import { format } from "date-fns"
import _, { Dictionary, groupBy } from "lodash"
import moment from "moment"
import { useEffect, useState } from "react"
import * as React from "react"
import { Row } from "react-bootstrap"
import { useParams } from "react-router"
import { syncFavorites } from "../../../backendServices/FavoritesServices"
import { Category, Event, EventDate, Person, ShareTargetType } from "../../../backendServices/Types"
import branding from "../../../branding/branding"
import { saveEvent, saveEvents } from "../../../communicationArea/ICal"
import { useFavoriteState } from "../../../globalStates/Favorites"
import { useLanguageState } from "../../../globalStates/LanguageState"
import { useLoggedInState } from "../../../globalStates/LoggedInUser"
import Breadcrumb, { BreadcrumbItem } from "../../../navigationArea/Breadcrumb"
import { programPageRoute } from "../../../navigationArea/RoutePaths"
import TopBar from "../../../navigationArea/TopBar"
import { calcBreadcrumbLocations } from "../../../tracking/RouteTracker"
import CenteredLoader from "../../../ui/CenteredLoader"
import ContextMenu from "../../../ui/ContextMenu"
import {
    IconArrowDownLangSwitcher,
    IconBookmarkFilled,
    IconBookmarkToggle,
    IconDownload,
    IconLiveStream,
    IconShare,
    IconThreeDotsMenu
} from "../../../ui/Icons"
import TopBannerSharedState from "../../advertisingBanner/TopBannerSharedState"
import GuestUserBanner from "../../guestUserBanner/GuestUserBanner"
import GuestUserBannerSharedState from "../../guestUserBanner/GuestUserBannerSharedState"
import DetailPageSections, { ModalRoot } from "../components/DetailPageSections"
import { buildDetailLink } from "../DetailNavLink"
import de from "date-fns/locale/de"
import en from "date-fns/locale/en-GB"
import { EventDetailsResponse, loadEventDetails } from "../../../backendServices/EventServices"
import {
    BasicInfoDescription,
    BasicInfoDescriptionText,
    BasicInfoDescriptionTitle,
    EventFormattedDate,
    EventLocation,
    EventLocationContainer,
    EventLocationRoot,
    EventLogo,
    EventLogoDiv,
    LocationCol,
    SectionRoot,
    SectionTopButton,
    ShowMoreButton,
    SpacingCol
} from "./EventDetailPageContent.styled"
import { capitalizeFirstLetter } from "../../../utils/StringUtils"
import { trackTakeAction } from "../../../utils/GTMTracking"

function checkIfDescriptionExists(event: Event, lang: string) {
    if (lang === "de") {
        return event.descriptionLongDe && event.descriptionLongDe.length > 0
    } else {
        return event.descriptionLong && event.descriptionLong.length > 0
    }
}

export const getFormattedDate = (date: string, lang: string) => {
    return format(moment(date).toDate(), branding.eventTiming.eventDaysFormatPatternEvtDetailPage, {
        locale: lang === "de" ? de : en
    })
}

export const EventDetailInfo: React.FunctionComponent<{
    event: Event
    showRecommendModal: (shareType: ShareTargetType, entityId: string, entityName: string, link: string) => void
}> = (props) => {
    const lang = useLanguageState().getLanguage()

    const [showDescription, setShowDescription] = useState<boolean>(false)
    const [showEventMenuModal, setShowEventMenuModal] = useState<boolean>(false)

    //returns date-time in format: Wed., 12 Sept., 2020, 17:00 - 18:30

    return (
        <SectionRoot className="event">
            <div style={{ marginLeft: "12px", paddingTop: "10px" }}>
                <div style={{ color: branding.primaryColor, height: "20px", display: "inline-block" }}>
                    {IconLiveStream({ fill: branding.sideIconBar.sideIconColorDark })}
                </div>
                <EventLocationRoot style={{ display: "inline-block" }}>
                    <EventLocationContainer>
                        {lang === "de" ? props.event.locationDe : props.event.location}
                    </EventLocationContainer>
                </EventLocationRoot>
                <SectionTopButton onClick={() => setShowEventMenuModal(true)}>
                    {IconThreeDotsMenu({ fill: branding.sideIconBar.sideIconColorDark })}
                </SectionTopButton>
            </div>

            <Row className="m-0">
                <SpacingCol xs={2} />
                <EventLogoDiv>
                    <EventLogo src={props.event.logo || "/branding/rsz_defaultgrey.png"} alt="" />
                </EventLogoDiv>
                <LocationCol xs={8}>
                    <div style={{ display: "block", marginLeft: "15px", width: "100%" }}>
                        <div style={{ marginTop: "4px" }}>
                            {props.event.start && props.event.end && (
                                <EventFormattedDate>
                                    {" "}
                                    {getFormattedDate(props.event.start, lang) + " - " + getFormattedDate(props.event.end, lang)}
                                </EventFormattedDate>
                            )}
                            <EventLocation>{lang === "de" ? props.event.nameDe : props.event.name}</EventLocation>
                        </div>
                    </div>
                </LocationCol>
            </Row>

            {checkIfDescriptionExists(props.event, lang) && (
                <Row className="m-0">
                    {showDescription ? (
                        <BasicInfoDescription>
                            <BasicInfoDescriptionTitle>
                                {branding.eventDateDetailPageContent.descriptionTextForEventDate}
                            </BasicInfoDescriptionTitle>
                            <BasicInfoDescriptionText>
                                {(lang === "de" ? props.event.descriptionLongDe : props.event.descriptionLong)
                                    ?.split(/\n+/)
                                    .map((item, i) => (
                                        <p>{item}</p>
                                    ))}
                            </BasicInfoDescriptionText>
                        </BasicInfoDescription>
                    ) : (
                        <ShowMoreButton onClick={() => setShowDescription(true)}>
                            <p style={{ marginTop: "15px" }}>{branding.myProfilePageContent.showMoreButtonText}</p>
                            <div style={{ marginTop: "15px", marginLeft: "5px" }}>
                                {IconArrowDownLangSwitcher({
                                    fill: branding.sideIconBar.sideIconColorDark,
                                    width: "12",
                                    height: "12"
                                })}
                            </div>
                        </ShowMoreButton>
                    )}
                </Row>
            )}

            {showEventMenuModal && (
                <EventMenuModal
                    event={props.event}
                    handleClose={() => setShowEventMenuModal(false)}
                    showRecommendModal={props.showRecommendModal}
                    type={ShareTargetType.EVENT}
                />
            )}
        </SectionRoot>
    )
}

interface EventMenuModalProps {
    event?: Event | EventDate
    type: ShareTargetType
    handleClose: () => void
    showRecommendModal: (shareType: ShareTargetType, entityId: string, entityName: string, link: string) => void

    marginTop?: string
}

export const EventMenuModal: React.FunctionComponent<EventMenuModalProps> = (props: EventMenuModalProps) => {
    const favoriteState = useFavoriteState()

    const eventId = props.event?.id ?? ""
    const isBookmarked = favoriteState.is(props.type === ShareTargetType.EVENT ? "event" : "eventdate", eventId)
    const userState = useLoggedInState()
    const user = userState.user()

    const type = props.type === ShareTargetType.EVENT ? "event" : "eventdate"

    const onBookmark = () => {
        if (props.event && user) {
            let date = new Date()
            date.setMinutes(date.getMinutes() - 1)

            if (!favoriteState.is(type, eventId)) {
                trackTakeAction(
                    `Set Favorite ${capitalizeFirstLetter(type)} ${eventId}`,
                    "SetFavorite",
                    capitalizeFirstLetter(type)
                )
            }

            favoriteState.toggle(type, eventId)
            const isFavorite = favoriteState.is(type, eventId)
            syncFavorites({
                profileId: user.profileId,
                body: {
                    currentTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), //"2020-01-01 12:00:00",
                    lastSyncTime: favoriteState.getLastSyncTime(),
                    changedFavorites: [
                        {
                            id: eventId,
                            kind: type.toUpperCase(),
                            deleted: !isFavorite,
                            lastModified: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                        }
                    ]
                }
            }).then(() => {
                favoriteState.setLastSyncTime(new Date())
            })
        }
    }

    return (
        <>
            <ModalRoot show={true} animation={false} onHide={() => props.handleClose()} marginTop={props.marginTop ?? "110px"}>
                <ContextMenu
                    collapsed={false}
                    items={() => [
                        {
                            title: branding.organizationDetailPageContent.bookmark,
                            icon: isBookmarked
                                ? branding.defaultToggleIcon
                                    ? IconBookmarkFilled({ fill: branding.sideIconBar.sideIconColorDark })
                                    : IconBookmarkToggle({ fill: branding.sideIconBar.sideIconColorDark })
                                : IconBookmarkToggle({ fill: branding.sideIconBar.sideIconColorDark }),
                            onClick: () => onBookmark()
                        },
                        {
                            title: branding.organizationDetailPageContent.recommend,
                            icon: IconShare({ fill: branding.sideIconBar.sideIconColorDark }),
                            onClick: () =>
                                props.showRecommendModal(
                                    props.type,
                                    eventId,
                                    props.event?.name!,
                                    buildDetailLink(eventId, props.event?.name!, type)
                                )
                        },
                        {
                            title: branding.contactEntry.downloadVCardTitle,
                            icon: IconDownload({ fill: branding.sideIconBar.sideIconColorDark }),
                            onClick: () => {
                                if (props.type === ShareTargetType.EVENT) {
                                    saveEvents((props.event as Event)?.eventdates!)
                                } else if (props.type === ShareTargetType.EVENTDATE) {
                                    saveEvent(props.event! as EventDate)
                                }
                            }
                        }
                    ]}
                />
            </ModalRoot>
        </>
    )
}

export function getEventCategories(eventdates: EventDate[]): Category[] {
    let eventCategories: Category[] = []
    eventdates.forEach((element: EventDate) => {
        if (element.categories) {
            eventCategories = eventCategories.concat(element.categories)

            eventCategories = _.uniqBy(eventCategories, function (c) {
                return c.id
            })
        }
    })

    return eventCategories
}

export function getEventPersons(eventdates: EventDate[]): Person[] {
    let eventPersons: Person[] = []
    eventdates.forEach((element: EventDate) => {
        if (element.persons) {
            eventPersons = eventPersons.concat(element.persons)

            eventPersons = _.uniqBy(eventPersons, function (p) {
                return p.id
            })
        }
    })

    return eventPersons
}

export function getEventPersonsFunctionGroups(eventPersons: Person[]): Dictionary<Person[]> {
    var personsWithFunctionNameSorted = eventPersons.sort((a, b) => {
        if (a!.personFunctionTargetName === undefined && b!.personFunctionTargetName === undefined) {
            return 0
        } else if (a!.personFunctionTargetName === undefined) {
            return 1
        } else if (b!.personFunctionTargetName === undefined) {
            return -1
        } else {
            return a!.personFunctionTargetName > b!.personFunctionTargetName
                ? -1
                : a!.personFunctionTargetName < b!.personFunctionTargetName
                ? 1
                : 0
        }
    })

    return groupBy(personsWithFunctionNameSorted, "personFunctionTargetName")
}

/* #endregion */

const EventDetailPageContent: React.FunctionComponent = (props) => {
    let { eventId }: any = useParams()
    const [event, setEvent] = useState<Event>()
    const [error, setError] = useState("")
    const [isLoaded, setIsLoaded] = useState(false)
    const langState = useLanguageState()
    const lang = langState.getLanguage()
    const profileId = useLoggedInState().user()?.profileId

    const { guestUserBannerRef, setGuestUserBannerRef } = GuestUserBannerSharedState()
    const { topBarRef, setTopBarRef } = TopBannerSharedState()
    const adjustHeight =
        (guestUserBannerRef && guestUserBannerRef.current ? guestUserBannerRef.current.clientHeight : 0) +
        (topBarRef && topBarRef.current ? topBarRef.current.clientHeight : 0) +
        65

    useEffect(() => {
        loadEventDetails(eventId || "")
            .then((data) => {
                const resp = data as EventDetailsResponse
                setIsLoaded(true)
                setEvent(resp.content)
            })
            .catch((e: { message: React.SetStateAction<string> }) => {
                setIsLoaded(true)
                setError(e.message)
            })
        // eslint-disable-next-line
    }, [eventId, error, profileId, lang])

    const breadcrumb: BreadcrumbItem[] = []
    if (event) {
        const locations = calcBreadcrumbLocations()
        breadcrumb.push(
            { to: programPageRoute, name: branding.programPageContent.scheduleTabTitle },
            { to: locations[1], name: event.name }
        )
    }

    let content: JSX.Element | null = null

    if (isLoaded && event) {
        content = (
            <>
                <DetailPageSections type="event" entity={event} adjustHeightWith={adjustHeight} newDesign />
            </>
        )
    } else {
        content = <CenteredLoader />
    }

    return (
        <>
            <GuestUserBanner setRef={setGuestUserBannerRef} />
            <TopBar setRef={setTopBarRef} />
            <div style={{ borderBottom: branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9" }}>
                <Breadcrumb breadcrumb={breadcrumb} classification="Details" contentType="Event" />
            </div>
            {content}
        </>
    )
}

export default EventDetailPageContent
