import {
    Exhibitor,
    News,
    Trademark,
    Product,
    JobOffer,
    EntityOrganization,
    EntityTrademark,
    EntityProduct,
    EntityNews,
    EntityJobOffer,
    ShareTargetType
} from "../../../backendServices/Types"
import styled from "styled-components"
import branding from "../../../branding/branding"
import BookmarkWithToggle from "../../BookmarkWithToggle"
import { useNavigationSource } from "../../../tracking/NavigationSource"
import { buildDetailLink, DetailNavLink } from "../../../contentArea/detailPages/DetailNavLink"
import { IconLinkRightArrow, IconShare } from "../../Icons"
import { device } from "../../../utils/Device"
import { TileLogo } from "./TileLogo"
import useWindowDimensions from "../../WindowDimensionsHook"
import { useFavoriteState } from "../../../globalStates/Favorites"
import { Tooltip } from "react-bootstrap"
import { useRecommendModal } from "../../modals/RecommendModal"
import { CustomOverlayTrigger } from "../../CustomOverlayTrigger"
import { useState } from "react"
import { useLoggedInState } from "../../../globalStates/LoggedInUser"
import { trackNewsAction } from "../../../backendServices/TrackingServices"
import React from "react"
import { trackSelectContent } from "../../../utils/GTMTracking"
import { capitalizeFirstLetter } from "../../../utils/StringUtils"

type ComponentType = Exhibitor | Product | Trademark | News | JobOffer
type ComponentTypeString = EntityOrganization | EntityProduct | EntityTrademark | EntityNews | EntityJobOffer

const getVisitEntityLinkText = (type: ComponentTypeString) => {
    switch (type) {
        case "product":
            return branding.receptionPage.visitProductText
        case "trademark":
            return branding.receptionPage.visitTrademarkText
        case "news":
            return branding.receptionPage.visitNewsText
        case "joboffer":
            return branding.receptionPage.visitJobofferText
        default:
            break
    }
}

const EntityActionsWrapper = styled.div<{ type: ComponentTypeString }>`
    position: absolute;
    top: ${(props) => (props.type === "product" ? "35px" : "40px")};
    right: 35px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 4;
`

interface EntityActionsProps {
    type: ComponentTypeString
    entity: ComponentType
    isBookmarked: boolean
    shareTargetType?: ShareTargetType
    lobby?: boolean
    showRecommendModal: (shareType: ShareTargetType, entityId: string, entityName: string, link: string) => void
}
const EntityActions = (props: EntityActionsProps) => {
    const { isMobile } = useWindowDimensions()

    if (isMobile) return null

    return (
        <EntityActionsWrapper type={props.type}>
            {props.shareTargetType && (
                <CustomOverlayTrigger
                    trigger={["focus", "click", "hover"]}
                    placement="bottom"
                    delay={{ show: 250, hide: 250 }}
                    overlay={
                        <Tooltip id="tooltip" style={{ fontFamily: branding.font1, marginLeft: "-20px", marginTop: "22px" }}>
                            {branding.communicationArea.chatToolRecommend}
                        </Tooltip>
                    }
                >
                    <div
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            if (props.shareTargetType)
                                props.showRecommendModal(
                                    props.shareTargetType,
                                    props.entity.id,
                                    props.entity.name,
                                    buildDetailLink(props.entity.id, props.entity.name, props.type)
                                )
                        }}
                    >
                        <div style={{ position: "absolute", top: "1px", right: "30px" }}>
                            {IconShare({
                                fill: branding.receptionPage.myFairBookmarkTileIconColor,
                                width: "21px",
                                height: "21px"
                            })}
                        </div>
                    </div>
                </CustomOverlayTrigger>
            )}

            <CustomOverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip id="tooltip" style={{ fontFamily: branding.font1, marginLeft: "0px", marginTop: "10px" }}>
                        {props.isBookmarked
                            ? branding.organizationDetailPageContent.unBookmarkPerson
                            : branding.organizationDetailPageContent.bookmarkPerson}
                    </Tooltip>
                }
            >
                <div className="exhibitor-bookmark">
                    <BookmarkWithToggle
                        className={"show-on-hover"}
                        newBookmarkItem={true}
                        type={props.type}
                        id={props.entity.id}
                        name={props.entity.name}
                        color={branding.receptionPage.myFairBookmarkTileIconColor}
                        stroke={branding.receptionPage.myFairBookmarkTileIconColor}
                        customSize={"18px"}
                    />
                </div>
            </CustomOverlayTrigger>
        </EntityActionsWrapper>
    )
}

interface EntityComponentProps {
    type: ComponentTypeString
    entity: ComponentType
    shareTargetType?: ShareTargetType
    lobby?: boolean
    showContextMenuOnHover?: boolean
}
export const EntityComponent: React.FC<EntityComponentProps> = React.memo((props) => {
    const user = useLoggedInState().user()
    const nav = useNavigationSource()
    const favoriteState = useFavoriteState()
    const { RecommendModal, showRecommendModal } = useRecommendModal()
    const { isMobile } = useWindowDimensions()
    const { entity } = props
    const isBookmarked = favoriteState.is(props.type, entity.id || "")
    const visitEntityText = getVisitEntityLinkText(props.type)
    const [contextMenuOpened, setContextMenuOpened] = useState(false)

    const handleMouseEnter = () => {
        if (props.showContextMenuOnHover) {
            setContextMenuOpened(true)
        }
    }

    const handleMouseLeave = () => {
        setContextMenuOpened(false)
    }

    const content: JSX.Element = props.lobby ? (
        <EntityComponentRoot
            type={props.type}
            onClick={() => {
                nav.set("LOBBY")

                trackSelectContent(props.entity.name, "Details", capitalizeFirstLetter(props.type))

                if (props.type === "news") {
                    trackNewsAction(user?.profileId!, "news", entity.id)
                }
            }}
            className={isMobile ? "isMobile" : ""}
        >
            <EntityActions
                type={props.type}
                entity={props.entity}
                shareTargetType={props.shareTargetType}
                isBookmarked={isBookmarked}
                showRecommendModal={showRecommendModal}
            />

            <TileLogo
                url={entity.pictureURL ?? (entity as Product | Trademark | News).logoUrl}
                alt={isMobile ? entity.name.replace(/(.{25})..+/, "$1…") : entity.name.replace(/(.{80})..+/, "$1…")}
                entityType={props.type}
                lobby={true}
            />

            {!isMobile && (
                <VisitEntityLink className={"show-on-hover"}>
                    <div className="mr-3">{visitEntityText}</div>
                    <div>
                        {IconLinkRightArrow({
                            fill: branding.receptionPage.myFairBookmarkTileTextColor
                        })}
                    </div>
                </VisitEntityLink>
            )}

            <RecommendModal />
        </EntityComponentRoot>
    ) : (
        <EntityComponentRootMyFairPage
            type={props.type}
            onClick={() => {
                nav.set("LOBBY")

                if (props.type === "news") {
                    trackNewsAction(user?.profileId!, "news", entity.id)
                }
            }}
            className={isMobile ? "isMobile" : ""}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {!isMobile && contextMenuOpened && (
                <EntityActions
                    type={props.type}
                    entity={props.entity}
                    shareTargetType={props.shareTargetType}
                    isBookmarked={isBookmarked}
                    showRecommendModal={showRecommendModal}
                />
            )}

            <TileLogo
                url={entity.pictureURL ?? (entity as Product | Trademark | News).logoUrl}
                alt={isMobile ? entity.name.replace(/(.{25})..+/, "$1…") : entity.name.replace(/(.{80})..+/, "$1…")}
                entityType={isMobile ? "mobile" : props.type}
                lobby={false}
                showContextMenuOnHover={contextMenuOpened}
                isMobile={isMobile}
            />

            <VisitEntityLinkMyFairPage entityType={props.type} className={isMobile ? "mobile" : ""}>
                {entity.name}
            </VisitEntityLinkMyFairPage>

            <RecommendModal />
        </EntityComponentRootMyFairPage>
    )

    return (
        <>
            {props.type === "news" && (entity as News).externalUrl ? (
                <a href={(entity as News).externalUrl} target="_blank" rel="noopener noreferrer">
                    {content}
                </a>
            ) : (
                <DetailNavLink
                    key={entity.id}
                    id={entity.id}
                    type={entity.entityType}
                    name={entity.name}
                    source="LOBBY"
                    onClick={() => {
                        trackSelectContent(entity.name, "Details", capitalizeFirstLetter(entity.entityType))
                    }}
                >
                    {content}
                </DetailNavLink>
            )}
        </>
    )
})

const VisitEntityLink = styled.span`
    font-family: ${branding.font1};
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    position: absolute;
    bottom: 10px;
    left: 50%;
    z-index: 1;
    width: 80%;
    transform: translateX(-50%);
    color: ${branding.receptionPage.myFairBookmarkTileTextColor};
    font-size: 16px;
`

const VisitEntityLinkMyFairPage = styled.span<{ entityType?: string }>`
    font-family: ${branding.font1};
    display: inline-block;
    text-decoration: none;
    position: absolute;
    width: 80%;
    height: 60px;
    z-index: 1;
    bottom: 10px;
    left: ${(props) => (props.entityType === "product" ? "44%" : "48%")};
    transform: translateX(-50%);
    color: ${branding.receptionPage.myFairBookmarkTileTextColor};
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;

    &.mobile {
        width: 60%;
        height: 15px;
        left: 49%;
        font-size: 9px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-top: 3px;
    }
`

const EntityComponentRoot = styled.div<{ type: ComponentTypeString }>`
    position: relative;
    display: flex;
    flex-basis: 95px;
    justify-content: center;
    align-items: center;
    width: 95px;
    height: 95px;
    border-style: solid;
    border-width: 1px;
    border: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    padding: 5px;
    margin: 0 0 1.25rem 0;
    color: #000;
    margin-right: ${branding.receptionPage.myFairPageExhibitorTilesMargin ?? "10px"};
    border-radius: 5px;

    &::before {
        background-color: white;
    }

    &:hover .show-on-hover {
        display: none;

        @media ${device.tablet} {
            display: flex;

            &.isMobile {
                display: none;
            }
        }
    }

    & .show-on-hover {
        display: none;
        color: ${branding.crsTabs.defaultActionItemColor};
    }

    ${VisitEntityLink} + ${VisitEntityLink} {
        left: 80%;

        @media ${device.tablet} {
            ${(props) => (props.type === "product" ? "left: 63%;" : "")}
        }
    }

    @media ${device.tablet} {
        flex-basis: ${(props) => (props.type === "product" ? "500px" : "250px")};
        width: ${(props) => (props.type === "product" ? "500px" : "250px")};
        height: ${(props) => (props.type === "product" ? "280px" : "250px")};
        padding: 20px;

        &.isMobile {
            flex-basis: 95px;
            width: 95px;
            height: 95px;

            padding: 5px;
        }
    }

    p {
        font-family: ${branding.font1};

        &.isMobile {
            font-size: 12px;
        }

        @media ${device.mobile} {
            font-size: 12px;
        }
    }
`

const EntityComponentRootMyFairPage = styled.div<{ type: ComponentTypeString }>`
    position: relative;
    display: flex;
    flex-basis: 280px;
    justify-content: center;
    align-items: center;
    width: 280px;
    height: 375px;
    border-style: solid;
    border-width: 1px;
    border: ${branding.receptionPage.myFairPageExhibitorTilesBorder
        ? branding.receptionPage.myFairPageExhibitorTilesBorder
        : "1px solid #d9d9d9"};
    padding: 20px;
    margin: 0 0 1.25rem 0;
    color: ${branding.primaryColor};
    margin-right: ${branding.receptionPage.myFairPageExhibitorTilesMargin ?? "10px"};
    border-radius: 5px;
    background-color: ${branding.receptionPage.myFairPageExhibitorTilesBgColor};
    z-index: 0;
    box-shadow: 10px 10px 12px rgba(0, 0, 0, 0.2);

    @media ${device.tablet} {
        flex-basis: ${(props) => (props.type === "product" ? "500px" : "280px")};
        width: ${(props) => (props.type === "product" ? "500px" : "280px")};
        height: ${(props) => (props.type === "product" ? "357px" : "357px")};
        padding: 20px;

        &.isMobile {
            flex-basis: 95px;
            width: 95px;
            height: 95px;

            padding: 5px;
        }
    }

    p {
        font-family: ${branding.font1};
        font-size: 16px;
        margin: 0px !important;

        &.isMobile {
            font-size: 12px;
        }

        @media ${device.mobile} {
            font-size: 12px;
        }
    }
    @media ${device.mobile} {
        flex-basis: 95px;
        width: 95px;
        height: 95px;
        padding: 5px;
        border: 0px solid transparent !important;
        margin-top: 0px !important;
    }
`
