import { useEffect, useRef, useState } from "react"
import * as React from "react"
import { Col, Row, Tooltip } from "react-bootstrap"
import styled, { css } from "styled-components"
import { EntityType, Product, ShareTargetType } from "../../../backendServices/Types"
import branding from "../../../branding/branding"
import { BookmarkWithToggle } from "../../../ui/BookmarkWithToggle"
import ScrollBarComponent from "../../../ui/ScrollBar"
import { buildDetailLink, DetailNavLink } from "../DetailNavLink"
import { DetailSectionTitle, ScrollableDiv, ViewAllButton, ViewMoreButton } from "./DetailPageSections"
import { HeaderTitle } from "./StyledComponents"
import { trackNewsAction, UserOrganizationVisitSource } from "../../../backendServices/TrackingServices"
import { device, DesktopVersionContainer, MobileVersionContainer } from "../../../utils/Device"
import TileRow from "../../../ui/TileRow"
import { IconShare } from "../../../ui/Icons"
import { useFavoriteState } from "../../../globalStates/Favorites"
import { CustomOverlayTrigger } from "../../../ui/CustomOverlayTrigger"
import useWindowDimensions from "../../../ui/WindowDimensionsHook"
import { ProductType } from "./ProductDetailInfo"
import { getShareTargetType } from "../../entitiesActions/actionsUtils"
import { useSuggestContext } from "../../../navigationArea/useSuggestContext"
import { useDetailSectionModal } from "../../../ui/modals/DetailSectionModal"
import { useRecommendModal } from "../../../ui/modals/RecommendModal"
import { useLoggedInState } from "../../../globalStates/LoggedInUser"
import { trackSelectContent } from "../../../utils/GTMTracking"
import { capitalizeFirstLetter } from "../../../utils/StringUtils"

const ScrollerShadowTop = styled.div`
    position: absolute;
    width: 100%;
    height: 10%;
    background: transparent;
    background: ${branding.primaryScrollWhiteShadowTTB};
    left: 0;
    top: 0;
    z-index: 2;
`

const ScrollerShadowBottom = styled.div`
    position: absolute;
    width: 100%;
    height: 10%;
    background: transparent;
    background: ${branding.primaryScrollWhiteShadowTTB};
    left: 0;
    bottom: 0;
    z-index: 2;
    transform: rotate(180deg);
`

export interface EntityRowData {
    id: string
    name: string
    initials: string
    pictureURL: string
    description?: { teaser?: string }
}

const TemplateRow = styled(Row)<{ isMobile: boolean; orgaDetailPage?: boolean }>`
    margin-left: ${(props) => (!props.isMobile ? "-5px" : "0")};
    margin-right: -3px;

    @media ${device.tablet} {
        margin-left: -15px;
        margin-right: -15px;
        ${(props) => (props.orgaDetailPage ? "" : "margin-top: 3rem;")}
        ${(props) => (props.orgaDetailPage ? "" : "margin-bottom: 3rem;")}

        &.joboffer {
            ${(props) => (props.orgaDetailPage ? "" : "margin-top: 25px !important;")}
            margin-bottom: 0px !important;
        }
        &.product {
            ${(props) => (props.orgaDetailPage ? "" : "margin-top: 25px !important;")}
            margin-bottom: 0px !important;
        }
    }

    @media ${device.mobile} {
        &.product {
            ${(props) => (props.orgaDetailPage ? "" : "margin-top: 25px !important;")}
            margin-bottom: 0px !important;
        }
    }

    &.forceMobileDesign {
        margin-top: 0;
        margin-bottom: 15px;
        margin-left: ${(props) => (!props.isMobile ? "-5px" : "0")};
        margin-right: -3px;
    }
`

const TemplateCol = styled(Col)<{ marginBottom?: string }>`
    margin-bottom: 0px;

    @media ${device.tablet} {
        margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "1.5rem !important")};
    }
`

const ProductTrademarkTileMobile = styled(DetailNavLink)`
    position: relative;
    display: flex;
    flex-basis: 135px;
    justify-content: center;
    align-items: center;
    width: 135px !important;
    height: 95px;
    border-style: solid;
    border-width: 1px;
    border: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    padding: 10px;
    margin: 0px 0 1.25rem 0px;
    color: ${branding.primaryColor};
    margin-right: ${branding.receptionPage.myFairPageExhibitorTilesMargin ?? "10px"};
    border-radius: 5px;
    cursor: pointer;

    &::before {
        background-color: white;
    }

    &:hover .show-on-hover {
        display: inline-block;
    }

    & .show-on-hover {
        display: none;
        color: ${branding.crsTabs.defaultActionItemColor};
    }

    & .exhibitor-bookmark {
        position: absolute;
        top: 15px;
        right: 15px;
    }
`

const ProductTrademarkLogoMobile = styled.div`
    width: 70%;
    height: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${branding.primaryColor};

    & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`

const CustomDesktopVersionContainer = styled.div`
    display: none;
    width: 100%;

    @media ${device.tablet} {
        display: block;
    }
`

interface EntityRowsProps {
    type: EntityType
    pageType: EntityType
    headerTitle: string
    rowsData: EntityRowData[]
    hideShadows?: boolean

    customMobileWidth?: string
    showRecommendModal?: (shareType: ShareTargetType, entityId: string, entityName: string, link: string) => void
    forceMobileDesign?: boolean

    customStyle?: any
}

export const DetailPageEntityRows: React.FunctionComponent<EntityRowsProps> = (props) => {
    const [position, setPosition] = useState(0)
    const [value, setValue] = useState(0)
    const scrollRef = useRef<HTMLDivElement>(null)
    const maxScrollRange = scrollRef.current?.scrollHeight! - scrollRef.current?.clientHeight!
    const width = maxScrollRange !== 0 ? "95%" : "100%"
    // Are shadows active
    const [isShadowTop, setIsShadowTop] = useState<boolean>(false)
    const [isShadowBottom, setIsShadowBottom] = useState<boolean>(false)
    const { isMobile } = useWindowDimensions()

    const [viewAll, setViewAll] = useState<boolean>(!(props.type === "product" || props.type === "trademark") ?? false)
    const headerString = `${props.headerTitle} ${
        props.rowsData && props.rowsData?.length > 1 ? "(" + props.rowsData.length + ")" : ""
    }`

    // Show-More-Pop-Up
    const { DetailSectionModal, showDetailSectionModal, hideDetailSectionModal } = useDetailSectionModal()
    const { RecommendModal, showRecommendModal } = useRecommendModal(
        () => showDetailSectionModal(modalContent, headerString),
        hideDetailSectionModal
    )

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current?.scrollTo(0, position)
        }
    }, [position])

    // DETERMING IF THE SHADOWS SHOULD BE VISIBLE
    useEffect(() => {
        if (scrollRef.current && (scrollRef.current!.scrollWidth === scrollRef.current!.clientHeight || props.hideShadows)) return
        value === 0 ? setIsShadowTop(false) : setIsShadowTop(true)
        maxScrollRange - value < 40 ? setIsShadowBottom(false) : setIsShadowBottom(true)
        // eslint-disable-next-line
    }, [value, maxScrollRange])

    if (props.rowsData.every((element) => element === null)) {
        return <div />
    }

    const modalContent = (
        <>
            {props.rowsData.map((rowData, index) => {
                return (
                    <React.Fragment key={rowData.id}>
                        {rowData && rowData !== null && (
                            <DetailPageEntityRow
                                type={props.type}
                                pageType={props.pageType}
                                rowData={rowData}
                                showRecommendModal={props.showRecommendModal}
                                forceMobileDesign={props.forceMobileDesign}
                                lastEntry={index === props.rowsData.length - 1}
                                openRecommendModalInParent={showRecommendModal}
                            />
                        )}
                    </React.Fragment>
                )
            })}
        </>
    )

    const maxNumEntries = props.pageType === "organization" && props.rowsData.length >= 3 ? 3 : props.rowsData.length

    const shortenedDesktopList = props.rowsData.slice(0, maxNumEntries)

    const desktopContent = shortenedDesktopList.map((rowData, index) => {
        return (
            <React.Fragment key={rowData.id}>
                {rowData && rowData !== null && (
                    <DetailPageEntityRow
                        type={props.type}
                        pageType={props.pageType}
                        rowData={rowData}
                        showRecommendModal={props.showRecommendModal}
                        forceMobileDesign={props.forceMobileDesign}
                        lastEntry={index === maxNumEntries - 1}
                    />
                )}
            </React.Fragment>
        )
    })

    return (
        <div
            style={
                props.customStyle ?? props.pageType === "organization"
                    ? { padding: "0px 12px 0px 0px" }
                    : { padding: "12px", paddingTop: "0px" }
            }
        >
            {/** temporary fix */}
            <Col
                xs={12}
                className={!isMobile && (!props.forceMobileDesign || props.forceMobileDesign === undefined) ? "" : "p-0"}
            >
                <TemplateRow
                    isMobile={isMobile || props.forceMobileDesign}
                    orgaDetailPage={props.pageType === "organization"}
                    className={
                        props.type === "product"
                            ? "product"
                            : props.type.toString() +
                              " " +
                              (!isMobile && (!props.forceMobileDesign || props.forceMobileDesign === undefined)
                                  ? props.pageType === "organization"
                                      ? ""
                                      : "mt-5"
                                  : props.forceMobileDesign
                                  ? "forceMobileDesign p-0"
                                  : "p-0")
                    }
                >
                    <TemplateCol
                        marginBottom={props.pageType === "organization" || viewAll ? "0" : ""}
                        xs={12}
                        className={props.pageType === "organization" || viewAll ? "p-0" : "p-0 mb-4"}
                        style={{
                            display: "flex",
                            justifyContent: "space-between"
                        }}
                    >
                        <div style={{ display: "inline-block" }}>
                            {props.pageType === "organization" && !isMobile ? (
                                <DetailSectionTitle marginTop="40px">
                                    {`${props.headerTitle} ${
                                        props.rowsData && props.rowsData?.length > 1 ? "(" + props.rowsData.length + ")" : ""
                                    }`}
                                </DetailSectionTitle>
                            ) : (
                                <HeaderTitle
                                    className={
                                        props.type === "product" ? "product" : props.forceMobileDesign ? "forceMobileDesign" : ""
                                    }
                                >{`${props.headerTitle} ${
                                    props.rowsData && props.rowsData?.length > 1 ? "(" + props.rowsData.length + ")" : ""
                                }`}</HeaderTitle>
                            )}
                        </div>
                        {(props.type === "product" || props.type === "trademark") && (
                            <ViewAllButton viewAll={viewAll} setViewAll={setViewAll} />
                        )}
                    </TemplateCol>

                    <MobileVersionContainer>
                        {viewAll ? (
                            <div style={{ marginBottom: "25px", width: "calc(100vw - 70px)" }}>
                                {props.rowsData.map((rowData) => (
                                    <React.Fragment key={rowData.id}>
                                        {rowData && rowData !== null && (
                                            <DetailPageEntityRow
                                                type={props.type}
                                                pageType={props.pageType}
                                                rowData={rowData}
                                                customMobileWidth={props.customMobileWidth}
                                                showRecommendModal={props.showRecommendModal}
                                                forceMobileDesign={props.forceMobileDesign}
                                            />
                                        )}
                                    </React.Fragment>
                                ))}
                            </div>
                        ) : (
                            <div style={{ width: "97%", overflowX: "hidden", paddingTop: "15px" }}>
                                {/** temporaray fix */}
                                <TileRow
                                    key={props.type}
                                    iconVisible={true}
                                    title={""}
                                    titleVisible={false}
                                    navLinkTextVisible={true}
                                    hideShadowsAndScrollButtons={true}
                                    navLinkText={""}
                                    scrollComponent={props.rowsData && props.rowsData?.length > 0}
                                    childWidth={135 + 9}
                                    childCount={props.rowsData?.length ?? 0}
                                    customMargin="-45px 0px 0 0px"
                                    customWidth="calc(100vw - 35px)"
                                >
                                    {props.rowsData.map((rowData) => {
                                        return (
                                            <ProductTrademarkTileMobile
                                                key={rowData.id}
                                                id={rowData.id}
                                                type={props.type}
                                                name={rowData.name ?? ""}
                                                onClick={() => {
                                                    trackSelectContent(
                                                        rowData.name ?? "",
                                                        "Details",
                                                        capitalizeFirstLetter(props.type)
                                                    )
                                                }}
                                            >
                                                <ProductTrademarkLogoMobile>
                                                    {rowData.pictureURL ? (
                                                        <img src={rowData.pictureURL} alt={rowData.name + " logo"} />
                                                    ) : (
                                                        <p>{rowData.name.replace(/(.{80})..+/, "$1…")}</p>
                                                    )}
                                                </ProductTrademarkLogoMobile>
                                            </ProductTrademarkTileMobile>
                                        )
                                    })}
                                </TileRow>
                            </div>
                        )}
                    </MobileVersionContainer>

                    <CustomDesktopVersionContainer>
                        <div style={{ width: "100%", position: "relative" }}>
                            {props.pageType === "organization" ? (
                                <>
                                    {desktopContent}
                                    <ViewMoreButton
                                        showDetailSectionModal={() => {
                                            showDetailSectionModal(modalContent, headerString)
                                        }}
                                        entries={props.rowsData}
                                        maxNumEntries={maxNumEntries}
                                    />
                                    <DetailSectionModal />
                                    <RecommendModal />
                                </>
                            ) : (
                                <ScrollableDiv
                                    ref={scrollRef}
                                    onScroll={() => setValue(scrollRef.current?.scrollTop!)}
                                    width={width}
                                >
                                    {desktopContent}
                                </ScrollableDiv>
                            )}
                            {props.pageType !== "organization" && isShadowTop && !props.hideShadows && <ScrollerShadowTop />}
                            {props.pageType !== "organization" && isShadowBottom && !props.hideShadows && (
                                <ScrollerShadowBottom />
                            )}
                        </div>

                        {props.pageType !== "organization" && (
                            <ScrollBarComponent
                                value={value}
                                maxScrollRange={maxScrollRange ? maxScrollRange : 0}
                                onScroll={(val: number) => {
                                    setPosition(val)
                                    setValue(val)
                                }}
                                isVertical={true}
                            />
                        )}
                    </CustomDesktopVersionContainer>
                </TemplateRow>
            </Col>
        </div>
    )
}

const DetailRowEntryRoot = styled.div<{ customMobileWidth?: string; lastEntry?: boolean }>`
    display: flex;
    border-bottom: ${(props) => (props.lastEntry ? "none" : branding.mainBorder ?? "1px solid #d9d9d9")};
    padding: 25px 0;

    @media ${device.mobile} {
        width: ${(props) => props.customMobileWidth ?? "calc(100% + 25px)"};
    }

    &.forceMobileDesign {
        width: ${(props) => props.customMobileWidth ?? "calc(100% + 25px)"};

        @media (min-width: 768px) {
            padding-right: 50px !important;
        }
    }
`

const DetailRowContent = styled.div``

const RowEntryNameLabel = styled.div<{ entityType?: string }>`
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
    color: ${branding.organizationDetailPageContent.listViewEntitiesTitleColor};
    font-family: ${branding.organizationDetailPageContent.listViewEntitiesTitleFont} !important;

    ${(props) =>
        props.entityType === "organization" &&
        css`
            color: ${branding.exhibitorsPageContent.companiesListLayoutTitleColor};
            font-family: ${branding.exhibitorsPageContent.companiesListLayoutTitleFont};
        `}

    ${(props) =>
        props.entityType === "product" &&
        css`
            color: ${branding.productsPageContent.productsListLayoutTitleColor};
            font-family: ${branding.productsPageContent.productsListLayoutTitleFont};
        `}

    ${(props) =>
        props.entityType === "trademark" &&
        css`
            color: ${branding.trademarksPageContent.trademarksListLayoutTitleColor};
            font-family: ${branding.trademarksPageContent.trademarksListLayoutTitleFont};
        `}

    ${(props) =>
        props.entityType === "news" &&
        css`
            color: ${branding.newsPageContent.newsListLayoutTitleColor};
            font-family: ${branding.newsPageContent.newsListLayoutTitleFont};
        `}

    ${(props) =>
        props.entityType === "joboffer" &&
        css`
            color: ${branding.newsPageContent.jobOfferListLayoutTitleColor};
            font-family: ${branding.newsPageContent.jobOfferListLayoutTitleFont};
        `}

    @media ${device.tablet} {
        font-size: 16px;
        line-height: 1rem;
        margin-top: 1px;
    }

    &.forceMobileDesign {
        font-size: 14px;
        line-height: 20px;
    }
`

const RowEntryTeaserLabel = styled.div`
    font-size: 14px;
    font-weight: 400;
    margin-top: 3px;
`

const MenuPanel = styled.div`
    display: flex;
    margin-left: auto;
    color: ${branding.primaryColor};
`

const MenuButton = styled.div`
    cursor: pointer;
    margin-left: 20px;
    height: 25px;
    width: 25px;

    &.disabled {
        opacity: 0.6;
        cursor: not-allowed;
        pointer-events: none;
    }
`

const LogoRoot = styled.div<{ exhibitor: boolean; isRectangle: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    border-radius: 5px;
    width: 60px;
    height: 60px;
    background: #fff;
    margin-right: 25px;
    padding: 1px;

    @media ${device.tablet} {
        width: ${(props) => (props.isRectangle ? "151px" : "95px")};
        height: 95px;
        padding: ${(props) => (props.exhibitor ? "12px" : "2px")};
    }

    &.forceMobileDesign {
        width: 60px;
        height: 60px;
        padding: 1px;
    }
`
const LogoDiv = styled.img`
    object-fit: contain;
    width: 100%;
    height: 100%;
`

const getSource = (pageType: EntityType): UserOrganizationVisitSource => {
    switch (pageType) {
        case "product":
            return "PRODUCT"
        case "trademark":
            return "TRADEMARK"
        case "news":
            return "NEWS"
        case "joboffer":
            return "JOBOFFER"
        default:
            return "UNKNOWN"
    }
}

interface EntityRowProps {
    type: EntityType
    pageType: EntityType
    rowData: EntityRowData

    customMobileWidth?: string
    showRecommendModal?: (shareType: ShareTargetType, entityId: string, entityName: string, link: string) => void
    forceMobileDesign?: boolean
    lastEntry?: boolean
    openRecommendModalInParent?: (
        shareType: ShareTargetType,
        entityId: string,
        entityName: string,
        link: string,
        onOpenCallback?: () => void
    ) => void
}

export const DetailPageEntityRow: React.FunctionComponent<EntityRowProps> = (props) => {
    const user = useLoggedInState().user()
    const src = props.type === "organization" ? getSource(props.pageType) : undefined
    const favorites = useFavoriteState()
    const isBookmarked = favorites.is(props.type, props.rowData.id)
    const suggestContext = useSuggestContext()

    return (
        <>
            <DetailRowEntryRoot
                customMobileWidth={props.customMobileWidth}
                className={props.forceMobileDesign ? "forceMobileDesign" : ""}
                lastEntry={props.lastEntry}
                onClick={() => {
                    if (props.type === "news") {
                        trackNewsAction(user?.profileId!, "news", props.rowData.id)
                    }
                }}
            >
                <DetailNavLink
                    id={props.rowData.id}
                    type={props.type}
                    name={props.rowData.name}
                    source={src}
                    onClick={() => {
                        trackSelectContent(props.rowData.name ?? "", "Details", capitalizeFirstLetter(props.type))
                    }}
                >
                    <LogoRoot
                        exhibitor={props.type === "organization"}
                        isRectangle={props.type === "product"}
                        className={props.forceMobileDesign ? "forceMobileDesign" : ""}
                    >
                        {props.rowData.pictureURL ? (
                            <LogoDiv src={props.rowData.pictureURL} alt="" />
                        ) : props.type === "organization" && branding.exhibitorsPageContent.defaultLogoVisibleOnCompanyTiles ? (
                            <LogoDiv src={"/branding/rsz_defaultgrey.png"} alt="" />
                        ) : (
                            <h2 style={{ fontFamily: branding.font1, marginBottom: "0px" }}>{props.rowData.initials}</h2>
                        )}
                    </LogoRoot>
                </DetailNavLink>
                <DetailRowContent>
                    <DetailNavLink
                        id={props.rowData.id}
                        type={props.type}
                        name={props.rowData.name}
                        source={src}
                        onClick={() => {
                            trackSelectContent(props.rowData.name ?? "", "Details", capitalizeFirstLetter(props.type))
                        }}
                    >
                        {props.type === "product" && branding.exhibitorsPageContent.showProductTypeInfo && (
                            <ProductType
                                product={props.rowData as Product}
                                view="companyDetail"
                                suggestContext={suggestContext}
                            />
                        )}

                        <RowEntryNameLabel entityType={props.type} className={props.forceMobileDesign ? "forceMobileDesign" : ""}>
                            {props.rowData.name}
                        </RowEntryNameLabel>

                        <DesktopVersionContainer>
                            <RowEntryTeaserLabel>
                                {props.rowData?.description?.teaser &&
                                    props.rowData?.description?.teaser
                                        .split("\\n")
                                        .map((item, i) => <span key={i}>{item} </span>)}
                            </RowEntryTeaserLabel>
                        </DesktopVersionContainer>
                    </DetailNavLink>
                </DetailRowContent>
                {
                    <MenuPanel>
                        {getShareTargetType(props.type) && (props.showRecommendModal || props.openRecommendModalInParent) && (
                            <CustomOverlayTrigger
                                trigger={["focus", "click", "hover"]}
                                placement="bottom"
                                delay={{ show: 250, hide: 250 }}
                                overlay={
                                    <Tooltip id="tooltip" style={{ fontFamily: branding.font1, zIndex: 1100 }}>
                                        {branding.communicationArea.chatToolRecommend}
                                    </Tooltip>
                                }
                            >
                                <MenuButton
                                    onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()

                                        if (props.openRecommendModalInParent)
                                            props.openRecommendModalInParent(
                                                getShareTargetType(props.type) || ShareTargetType.ORGANIZATION,
                                                props.rowData.id,
                                                props.rowData.name,
                                                buildDetailLink(props.rowData.id, props.rowData.name, props.type)
                                            )
                                        else if (props.showRecommendModal)
                                            props.showRecommendModal(
                                                getShareTargetType(props.type) || ShareTargetType.ORGANIZATION,
                                                props.rowData.id,
                                                props.rowData.name,
                                                buildDetailLink(props.rowData.id, props.rowData.name, props.type)
                                            )
                                    }}
                                >
                                    {IconShare({ fill: branding.sideIconBar.sideIconColorDark })}
                                </MenuButton>
                            </CustomOverlayTrigger>
                        )}

                        <CustomOverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id="button-tooltip-share" style={{ fontFamily: branding.font1, zIndex: 1100 }}>
                                    {isBookmarked ? branding.backofficeStaff.removeBookmark : branding.backofficeStaff.bookmark}
                                </Tooltip>
                            }
                        >
                            <MenuButton>
                                <BookmarkWithToggle
                                    newBookmarkItem={true}
                                    fontSize={"25px"}
                                    color={branding.sideIconBar.sideIconColorDark}
                                    stroke={branding.sideIconBar.sideIconColorDark}
                                    type={props.type}
                                    id={props.rowData.id}
                                    name={props.rowData.name}
                                />
                            </MenuButton>
                        </CustomOverlayTrigger>
                    </MenuPanel>
                }
            </DetailRowEntryRoot>
        </>
    )
}
