import { useEffect, useRef, useState } from "react"
import * as React from "react"
import Select, { ActionMeta, OptionTypeBase, ValueType } from "react-select"
import styled from "styled-components"
import { loadSuggestions } from "../backendServices/SuggestionServices"
import { EntityType } from "../backendServices/Types"
import branding, { LocalizedString } from "../branding/branding"
import TopBannerSharedState from "../contentArea/advertisingBanner/TopBannerSharedState"
import { useAppState } from "../globalStates/AppState"
import { getPrefix, useFavoriteState } from "../globalStates/Favorites"
import { useLanguageState } from "../globalStates/LanguageState"
import Breadcrumb, { BreadcrumbItem } from "../navigationArea/Breadcrumb"
import {
    bookmarkedExhibitorsPageRoute,
    bookmarkedJoboffersPageRoute,
    bookmarkedNewsPageRoute,
    bookmarkedProductsPageRoute,
    bookmarkedTrademarksPageRoute,
    categoriesPageRoute,
    customCategoriesPageRoute,
    exhibitorsPageRoute,
    exhibitorStartupPageRoute,
    joboffersPageRoute,
    mediaPartnerPageRoute,
    exhibitorNewsPageRoute,
    productsPageRoute,
    showfloorPageRoute,
    sponsorsPageRoute,
    trademarksPageRoute
} from "../navigationArea/RoutePaths"
import TopBar from "../navigationArea/TopBar"
import { calcBreadcrumbLocations } from "../tracking/RouteTracker"
import BackendError from "../ui/BackendError"
import CenteredLoader from "../ui/CenteredLoader"
import { ResultListLayout } from "../ui/ResultListLayout"
import { CompaniesTilesLayout } from "../ui/CompaniesTilesLayout"
import CrsTabs, { ActionTypes, getUserDefaultViewMode, TabItemType, ToggleFiltersButton, ViewMode } from "../ui/CrsTabs"
import FilterBar from "../ui/FilterBar"
import ScrollContainer from "../ui/ScrollContainer"
import useWindowDimensions from "../ui/WindowDimensionsHook"
import { device, HideOnMobileAndIpadContainer, MobileVersionContainer, DesktopVersionContainer } from "../utils/Device"
import { trackPageView, trackSearch, trackSelectContent } from "../utils/GTMTracking"
import { fetchDataHelper, SearchParameters, sectionOrder, Sections } from "../utils/searchUtils"
import TopBanner from "./advertisingBanner/TopBanner"
import { OnlyBookmarksButton } from "./BusinessAreaPageContent"
import { CategoryConfigType, CategoryTab, ProductTypeTab } from "./ContentAreaPageBranding"
import GuestUserBanner from "./guestUserBanner/GuestUserBanner"
import GuestUserBannerSharedState from "./guestUserBanner/GuestUserBannerSharedState"
import MainCategoriesPageContent from "./MainCategoriesPageContent"
import EmptyTile from "./reception/EmptyTile"
import CategoriesSlider from "../ui/slider/CategoriesSlider"
import { Row } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import { getTileViewMinReqWidth, PagesUsingTileView } from "../globalStates/TileViewConfig"
import InView from "react-intersection-observer"
import { SelectThemeCustom } from "../conference/components/settings/Settings"
import SearchBar from "../ui/SearchBar"
import { ResetFilterButton } from "./detailPages/components/ResetFilterButtonComponent"
import { capitalizeFirstLetter } from "../utils/StringUtils"

/*********************************************************************************************
 * variable definitions
 **********************************************************************************************/
export enum SortBy {
    DESC,
    ASC
}

/* #region  Header */
export const HeaderContainer = styled.div`
    display: flex;
    width: 100%;

    .dropdown-toggle::after {
        display: none;
    }

    .headerRoot {
        width: 100%;

        @media (max-width: 1800px) {
            width: 100%; //58%
        }

        @media (max-width: 1600px) {
            width: 100%; //54%
        }

        @media (max-width: 1400px) {
            width: 100%; //50%
        }
    }
`

export const InViewParent = styled.div`
    width: 100%;
    bottom: 30px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
`

/* #region  Dropdown section */
export const StyledSelect = styled(Select)`
    flex: 0 45%;
    font-size: 12px;
    font-family: ${branding.font1};
    color: ${branding.mainInfoColor};

    @media ${device.tablet} {
        flex: 0 200px;
    }

    @media ${device.laptop} {
        flex: 0 250px;
    }

    @media ${device.mobile} {
        .css-jeid61-option {
            background-color: #fff;
        }
    }
`
interface DropdownComponentProps {
    categories: any[]
    defaultTitle: string
    searchParamFilterValue: string | null | undefined
    onClick(value: string | null): void
}

export const DropdownComponent: React.FC<DropdownComponentProps> = (props) => {
    let options: any = []
    props.categories.length > 0
        ? (options =
              props.categories &&
              props.categories.map((cat) => {
                  return { label: cat.name, value: cat.alias }
              }))
        : (options = [])

    const categoryLabel = props.categories.find((x) => x.alias === props.searchParamFilterValue)?.name ?? props.defaultTitle
    const value = props.searchParamFilterValue ? { label: categoryLabel, value: props.searchParamFilterValue } : null

    return (
        <StyledSelect
            placeholder={props.defaultTitle}
            isMulti={false}
            isSearchable={true}
            isClearable={true}
            options={options}
            value={value}
            noOptionsMessage={() => branding.exhibitorsPageContent.filterNoOptionsMessage}
            onChange={(value: ValueType<OptionTypeBase, boolean>, action: ActionMeta<OptionTypeBase>) => {
                if (value !== null && action.action === "select-option") {
                    const option = value as OptionTypeBase
                    if (props.searchParamFilterValue !== option.value) props.onClick(option.value)
                } else props.onClick(null)
            }}
            theme={SelectThemeCustom}
            // style={SelectThemeCustom}
        />
    )
}
/* #endregion */
const ContentRoot = styled.div<{
    topBannerHeight: number
    guestBannerHeight: number
    additionalHeight: number
    filtersVisible: boolean
    isMobile: boolean
    isStartupPage?: boolean
    isSponsorsPage?: boolean
    height: string
}>`
    display: flex;
    flex-direction: column;
    height: ${(props) => props.height};
    overflow-x: hidden;
    background-color: ${branding.contentBgColorForEachPage ?? "#fff"};

    .ScrollbarsCustom-Scroller {
        overflow-x: hidden !important;
        padding-bottom: 20px;
    }

    .ScrollbarsCustom-Content {
        overflow: hidden;
        position: relative;
    }

    @media ${device.mobile} {
        height: ${(props) =>
            `calc(100vh - ${props.topBannerHeight}px - ${props.guestBannerHeight}px - ${
                props.filtersVisible ? "230" : "100"
            }px)`};

        &.joboffer  {
            height: ${(props) =>
                `calc(100vh - ${props.topBannerHeight}px - ${props.guestBannerHeight}px - ${
                    props.filtersVisible ? "230" : "100"
                }px)`};
        }
    }

    &.organization {
        @media screen and(min-width: 1024px) {
            height: ${(props) =>
                `calc(100vh - ${props.topBannerHeight}px - ${props.guestBannerHeight}px - ${
                    props.filtersVisible && !props.isStartupPage && !props.isSponsorsPage ? "340" : "250"
                }px)`};
        }
        //The next two media queries could possibly be merged as they have the same calculation?
        @media screen and (min-device-width: 768px) and (max-device-width: 1023px) {
            height: ${(props) =>
                `calc(100vh - ${props.topBannerHeight}px - ${props.guestBannerHeight}px - ${
                    props.filtersVisible && !props.isStartupPage && !props.isSponsorsPage ? "340" : "100"
                }px)`};
        }
        @media screen and (max-device-width: 640px) {
            height: ${(props) =>
                `calc(100vh - ${props.topBannerHeight}px - ${props.guestBannerHeight}px - ${
                    props.filtersVisible && !props.isStartupPage && !props.isSponsorsPage ? "340" : "100"
                }px)`};
        }
    }

    &.onlyBookmarksPage {
        height: calc(100vh - 160px);
    }
`

const ContainerRoot = styled.div``

export const FilterBarContainer = styled.div<{ showShadow?: boolean; visible: boolean }>`
    position: relative;
    z-index: 10;
    padding: 25px 25px;
    background-color: ${branding.contentBgColorForEachPage ?? "#fff"};

    box-shadow: ${(props) => (props.showShadow ? branding.primaryScrollDarkShadowTTB : "initial")};

    display: ${(props) => (props.visible ? "block" : "none !important")};

    @media ${device.mobile} {
        display: inline-flex;
        padding: 15px 0px 0px 10px;
        padding-left: 18px;
        width: inherit;
        // margin-right: -12px;

        &.categorysearch {
            padding: 15px 0px 10px 15px;
        }
    }
`

export const NextPageLoader = styled(CenteredLoader)`
    height: 120px;
`

export const DropdownsRoot = styled.div`
    width: 100%;
`

export const DropdownsContent = styled.div<{ isMobile?: boolean }>`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 10px;
    row-gap: 10px;
    max-width: 92%;
    padding-bottom: ${(props) => (props.isMobile ? "15px" : "0")};
`

export const DropdownsContentRoot = styled.div<{ isMobile?: boolean }>`
    display: ${(props) => (props.isMobile ? "inline-flex" : "block")};
    width: 100%;
`

export const ToggleFilterDiv = styled.div<{ filterVisible?: boolean }>`
    position: absolute;
    right: 0;
    margin-right: 12px;
    margin-top: ${(props) => (props.filterVisible ? "10px" : "0px")};
    z-index: 20;
`

/*********************************************************************************************
 * site assembly
 **********************************************************************************************/
const ExhibitorsContainer = styled.div<{ viewMode: ViewMode }>`
    height: 100%;
    overflow: hidden;

    .infinite-scroll-component {
        padding-bottom: ${(props) => (props.viewMode === ViewMode.TILES ? "250px" : "100px")};
    }
`
export interface SimpleOrganization {
    id: string
    name: string
    consentTitle?: string
    consentText?: string
}

interface ExhibitorsPageProps {
    /**
     * @title isSponsorsPage option
     * @description Enable / disable isSponsorsPage option
     * @title_de isSponsorsPage option
     * @description_de Enable / disable isSponsorsPage option
     */
    isSponsorsPage?: boolean
    /**
     * @title isMediaPartnerPage option
     * @description Enable / disable isMediaPartnerPage option
     * @title_de isMediaPartnerPage option
     * @description_de Enable / disable isMediaPartnerPage option
     */
    isMediaPartnerPage?: boolean
    /**
     * @title showOnlyBookmarks option
     * @description Enable / disable showOnlyBookmarks option
     * @title_de showOnlyBookmarks option
     * @description_de Enable / disable showOnlyBookmarks option
     */
    showOnlyBookmarks?: boolean
    /**
     * @title showOnlyBookmarks entity type
     * @description Entity type for only bookmarks page ("organization", "product", "tradeamark", "news")
     * @title_de showOnlyBookmarks entity type
     * @description_de Entity type for only bookmarks page ("organization", "product", "tradeamark", "news")
     */
    onlyBookmarksType?: EntityType
    /**
     * @title isStartupPage option
     * @description Enable / disable isStartupPage option
     * @title_de isStartupPage option
     * @description_de Enable / disable isStartupPage option
     */
    isStartupPage?: boolean
}

const FilterBarRoot = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 10px;
    padding: 25px;
    flex-wrap: wrap;

    @media ${device.mobile} {
        padding-left: 20px;
        padding-right: 15px;
        gap: 17px;
    }
`

interface FilterButtonsComponentProps {
    onResetFilterButtonClick: () => void
    resetFilterVisible: boolean
}

export const FilterButtonsComponent: React.FunctionComponent<FilterButtonsComponentProps> = (
    props: FilterButtonsComponentProps
) => {
    const content: JSX.Element = (
        <>
            {props.resetFilterVisible && (
                <ResetFilterButton
                    visibility={props.resetFilterVisible}
                    onResetFilterButtonClick={() => props.onResetFilterButtonClick()}
                    text={branding.businessAreaPageContent.resetFilterButtonText}
                    buttonHeight={"38px"}
                />
            )}
        </>
    )
    return (
        <>
            <DesktopVersionContainer>
                <FilterBarRoot style={{ padding: 0 }}>{content}</FilterBarRoot>
            </DesktopVersionContainer>
            <MobileVersionContainer>
                <Row style={{ marginLeft: "0px", marginRight: "0px" }}>
                    <FilterBarRoot style={{ padding: 0 }}>{content}</FilterBarRoot>
                </Row>
            </MobileVersionContainer>
        </>
    )
}

export type DropdownConfig = {
    id: string
    type: string
    entityType: string
    visibleForEntities: string[]
    defaultTitle: LocalizedString
    catId: string | null
    catIds: string[] | null
    msc: number | null
    fec: boolean | null
    umlautSortType: string | null
}
/**
 *
 * @param dropdownConfig
 * @returns dropdownConfig
 *
 * This function cleans up the dropdown config, meaning that not used properties will be removed from the config, since
 * the branding validator does not allow optional properties, we have to clean up the properties which have no values, which is
 * necessary for getting the right data set from the backend.
 *
 */

export function dropdownConfigCleanup(dropdownConfig: DropdownConfig) {
    let dropdownConfigCopy: any = { ...dropdownConfig }
    // no cat ids defined, remove catIds from config
    if (dropdownConfigCopy.jsonData.catIds && !dropdownConfigCopy.jsonData.catIds.length) {
        delete dropdownConfigCopy.jsonData.catIds
    }
    // no cat id defined, remove catId from config
    if (!dropdownConfigCopy.jsonData.catId || dropdownConfigCopy.jsonData.catId === "") {
        delete dropdownConfigCopy.jsonData.catId
    }

    return dropdownConfigCopy
}

export interface ShowfloorHistoryState {
    viewMode?: number
    showOnlyBookmarks?: boolean
    dropdownFilterParams?: any[]
    alpha: string
    scrollPosition: number
    page: number
    results: Sections
    categorySearchParam: string
    activeSubCategory: any
    activeCustomSubCategory: any
    newsSortBy: SortBy
}

const scrollPositionKey: string = "scrollPositionShowfloor"

const ExhibitorsPageContent: React.FunctionComponent<ExhibitorsPageProps> = (props: ExhibitorsPageProps) => {
    // Global states
    const appState = useAppState()
    const history = useHistory()
    const languageState = useLanguageState()
    const lang = languageState.getLanguage()
    const favoriteState = useFavoriteState()
    const { isMobile, useMobileDesign, height: windowHeight } = useWindowDimensions()

    // Add banner state
    const { showBanner, setShowBanner, topBarRef, setTopBarRef, hideOnScroll, setHideOnScroll } = TopBannerSharedState()
    const { guestUserBannerRef, setGuestUserBannerRef } = GuestUserBannerSharedState()
    const [bannerHeight, setBannerHeight] = useState(0)

    //Scroll persistance
    const [scrollPosition, setScrollPosition] = useState<any>(parseInt(window.sessionStorage.getItem(scrollPositionKey) ?? "0"))

    // Request parameters and data
    const [dropdownFilterParams, setDropdownFilterParams] = useState<any[]>(
        (history.location.state as ShowfloorHistoryState)?.dropdownFilterParams ?? []
    )

    const [loaded, setLoaded] = useState<boolean>(false)
    const [isError, setIsError] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [page, setPage] = useState((history.location.state as ShowfloorHistoryState)?.page ?? 0)
    const [alpha, setAlpha] = useState<string | null>((history.location.state as ShowfloorHistoryState)?.alpha ?? null)
    const [showOnlyBookmarks, setShowOnlyBookmarks] = useState<boolean>(
        (history.location.state as ShowfloorHistoryState)?.showOnlyBookmarks ?? props.showOnlyBookmarks ?? false
    )
    const [sections, setSections] = useState<Sections>((history.location.state as ShowfloorHistoryState)?.results ?? {})
    const [entityType, setEntityType] = useState<EntityType | string>(fetchEntityType())

    const [newsSortBy, setNewsSortBy] = useState<SortBy>(
        (history.location.state as ShowfloorHistoryState)?.newsSortBy ?? SortBy.DESC
    )
    const newsSortByOptions = [
        { label: branding.exhibitorsPageContent.newsSortByNewestToOldestTitle, value: SortBy.DESC },
        { label: branding.exhibitorsPageContent.newsSortByOldestToNewestTitle, value: SortBy.ASC }
    ]

    const categoryFilter = props.isSponsorsPage
        ? branding.sponsorsPageContent.partnersSponsorsCategoryAliases.map((alias) => "cat_" + alias).join(", ")
        : props.isStartupPage
        ? branding.exhibitorsPageContent.startupCategories.map((alias) => "cat_" + alias).join(", ")
        : ""
    const productTypeTab = getProductTypeTab(entityType)
    const productTypeFilter = productTypeTab ? "prodtype_" + productTypeTab.productType : ""

    const searchParams: SearchParameters = {
        page: page,
        order: "lexic",
        alpha: alpha,
        showOnlyBookmarks: showOnlyBookmarks,
        entityTypes: [getEntityType(entityType)],
        searchEntities: [],
        categoryFilter: categoryFilter,
        productTypeFilter: productTypeFilter,
        favorites: favoriteState.get(getEntityType(entityType)),
        searchValue: "",
        dropdownFilterParams: dropdownFilterParams,
        basispremium: props.isStartupPage ? branding.exhibitorsPageContent.startupPackageValue : undefined,
        newsDesc: newsSortBy === SortBy.DESC
    }

    const [categorySearchParam, setCategorySearchParam] = useState<string>(
        (history.location.state as ShowfloorHistoryState)?.categorySearchParam ?? ""
    )

    // Categories Slider Active Category
    const [activeSubCategory, setActiveSubCategory] = useState<any>(
        (history.location.state as ShowfloorHistoryState)?.activeSubCategory ??
            branding.categoriesPageContent.categoriesConfiguration[0]
    )

    // Custom Categories Slider Active Category
    const [activeCustomSubCategory, setActiveCustomSubCategory] = useState<any>(
        (history.location.state as ShowfloorHistoryState)?.activeCustomSubCategory ??
            branding.categoriesPageContent.customCategoriesConfiguration[0]
    )

    // View mode and Tabs Config
    const getCurrentEntityTitleAndViewMode = React.useCallback((): { title: string; viewMode: ViewMode } => {
        const userDefaultViewMode = getUserDefaultViewMode(appState.currentItem, entityType)
        const viewModeFromState = (history.location.state as ShowfloorHistoryState)?.viewMode
        let title = ""
        let viewMode = ViewMode.LIST

        switch (entityType) {
            case "product":
                title = branding.navigationArea.productsItemTitle
                break
            case "trademark":
                title = branding.navigationArea.trademarksItemTitle
                break
            case "category":
                const listViewAsDefault = activeSubCategory
                    ? activeSubCategory.listViewAsDefault
                    : branding.categoriesPageContent.listViewAsDefault
                title = branding.categoriesPageContent.categoriesPageTitle
                viewMode = listViewAsDefault ? ViewMode.LIST : ViewMode.TILES
                break
            case "customcategory":
                const listViewAsDefaultCustom = activeCustomSubCategory
                    ? activeCustomSubCategory.listViewAsDefault
                    : branding.categoriesPageContent.customCategoriesConfiguration.length > 0
                    ? branding.categoriesPageContent.customCategoriesConfiguration[0]?.listViewAsDefault
                    : branding.categoriesPageContent.listViewAsDefault
                title = branding.exhibitorsPageContent.customCategoriesTitle
                viewMode = listViewAsDefaultCustom ? ViewMode.LIST : ViewMode.TILES
                break
            case "organization":
                title = branding.navigationArea.companiesItemTitle
                viewMode =
                    (branding.sponsorsPageContent.listViewAsDefault && isSponsor) ||
                    (branding.exhibitorsPageContent.listViewAsDefaultStartup && isStartup) ||
                    branding.exhibitorsPageContent.listViewAsDefaultOrganization
                        ? ViewMode.LIST
                        : ViewMode.TILES
                break
            case "news":
                title = branding.navigationArea.newsItemTitle
                break
            case "joboffer":
                title = branding.exhibitorsPageContent.jobofferTabTitle
                break
            default:
                const productTypeTab = getProductTypeTab(entityType)
                if (productTypeTab) {
                    title = productTypeTab.label
                    viewMode = ViewMode.LIST
                } else {
                    title = branding.navigationArea.companiesItemTitle
                    viewMode =
                        branding.sponsorsPageContent.listViewAsDefault && isSponsor
                            ? ViewMode.LIST
                            : branding.exhibitorsPageContent.listViewAsDefaultStartup && isStartup
                            ? ViewMode.LIST
                            : ViewMode.TILES
                }
                break
        }

        return {
            title: title,
            viewMode: viewModeFromState ?? userDefaultViewMode ?? viewMode
        }
    }, [entityType, appState.currentItem, history.location.state]) // eslint-disable-line

    const [viewMode, setViewMode] = useState<any>(
        props.showOnlyBookmarks ? ViewMode.LIST : getCurrentEntityTitleAndViewMode().viewMode
    )

    const scrollContainerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (isShowfloor) {
            trackPageView(branding.sideIconBar.showfloorMenuText, "Overallview", "Show Floor_Explore")
        } else {
        }

        appState.setIsOnExhibitorsPageContentOrGlobalSearchResultPageContent(true)

        return function cleanUp() {
            appState.setIsOnExhibitorsPageContentOrGlobalSearchResultPageContent(false)
        }
        // eslint-disable-next-line
    }, [])

    const [filtersVisible, setFiltersVisible] = useState<boolean>(true)
    let entityFilterItems: any[] = []

    const [resetScroll, setResetScroll] = useState<boolean>(false)

    // Branding
    const includeFilterCategories = branding.exhibitorsPageContent.dropdownFilterConfig.length > 0

    const [searchKrit, setSearchKrit] = useState<string>("")
    const [dropdownData, setDropdownData] = useState<any>()
    const [dropdownDataLoaded, setDropdownDataLoaded] = useState<boolean>(true)

    useEffect(() => {
        //first we check if the results are already loaded, and only then we reset the page and scroll position values
        //this way we want to prevent the reset on the first render, so we can preserve the persistence

        //sections.all is checked because all entity lists have that section, while only the exhibitors list has the top section
        //if sections.all is present, and the value of "loaded" is true, that means that results are already loaded
        //if sections.all is not present at all, that means there are no results at all, and there is no point in triggering another backend request

        if (sections.all && loaded) {
            //reset the page and scroll position
            //we set both to 0, because if we kept the scroll position, a few more pages of results would be requested if the position value is really high, which would result in too many backend requests
            setPage(0)
            setScrollPosition(0)

            //adjust the showfloor history state to prevent returning to the old values when reloading the page, or visiting it when clicking back button from another page
            history.replace(getCurrentPath(entityType), {
                ...(history.location.state as ShowfloorHistoryState),
                page: 0,
                scrollPosition: 0
            })
        }

        let tempDropdownData: any = {}
        let dropdownLoadedCountTemp = 0

        dropdowns.forEach((dropdown) => {
            let dropdownConfigClean = dropdownConfigCleanup(dropdown as DropdownConfig)

            setDropdownDataLoaded(false)
            loadSuggestions("", [dropdownConfigClean])
                .then((res) => {
                    // creating a new object with the key of the dropdown id
                    if (!tempDropdownData[`${dropdown.id}`]) {
                        tempDropdownData[`${dropdown.id}`] = {}
                        tempDropdownData[`${dropdown.id}`].data = []

                        res.suggestGroups[0].suggestions.forEach((suggestion) => {
                            tempDropdownData[`${res.suggestGroups[0].id}`].data.push({
                                alias: suggestion.alias,
                                name: suggestion.title
                            })
                        })
                    }
                    setDropdownData(tempDropdownData)
                })
                .catch((err) => {
                    console.log("Error loading data for dropdown " + dropdown.id)
                })
                .finally(() => {
                    ++dropdownLoadedCountTemp
                    if (dropdownLoadedCountTemp === dropdowns.length) setDropdownDataLoaded(true)
                })
        })
        // eslint-disable-next-line
    }, [lang])

    const [showResetFilterButton, setShowResetFilterButton] = useState<boolean>(false)
    useEffect(() => {
        if (dropdownFilterParams.length && dropdownFilterParams.filter(({ alias }) => alias !== null).length > 0) {
            setShowResetFilterButton(true)
        } else {
            setShowResetFilterButton(false)
        }
    }, [dropdownFilterParams])

    // Breadcrumb
    const locations = calcBreadcrumbLocations()

    function getBookmarkedPageBreadcrumb(): BreadcrumbItem[] {
        const breadcrumb: BreadcrumbItem[] = [
            { to: locations[0], name: branding.sideIconBar.lobbyMenuText },
            { to: "/", name: branding.receptionPage.receptionPageMyHeaderTitle }
        ]

        if (props.showOnlyBookmarks && props.onlyBookmarksType) {
            if (props.onlyBookmarksType === "organization") {
                breadcrumb.push({ to: "/", name: branding.exhibitorsPageContent.bookmarkedExhibitorsTitle })
            } else if (props.onlyBookmarksType === "product") {
                breadcrumb.push({ to: "/", name: branding.exhibitorsPageContent.bookmarkedProductsTitle })
            } else if (props.onlyBookmarksType === "trademark") {
                breadcrumb.push({ to: "/", name: branding.exhibitorsPageContent.bookmarkedTrademarksTitle })
            } else if (props.onlyBookmarksType === "news") {
                breadcrumb.push({ to: "/", name: branding.exhibitorsPageContent.bookmarkedNewsTitle })
            } else if (props.onlyBookmarksType === "joboffer") {
                breadcrumb.push({ to: "/", name: branding.exhibitorsPageContent.bookmarkedJoboffersTitle })
            }
        }

        return breadcrumb
    }

    function getBookmarkedPageNoEntitiesMessage(): string {
        if (props.showOnlyBookmarks && props.onlyBookmarksType) {
            if (props.onlyBookmarksType === "organization") {
                return branding.receptionPage.noBookmarkedExhibitors
            } else if (props.onlyBookmarksType === "product") {
                return branding.receptionPage.noBookmarkedProducts
            } else if (props.onlyBookmarksType === "trademark") {
                return branding.receptionPage.noBookmarkedTrademarks
            } else if (props.onlyBookmarksType === "news") {
                return branding.receptionPage.noBookmarkedNews
            } else if (props.onlyBookmarksType === "joboffer") {
                return branding.receptionPage.noBookmarkedJoboffers
            }
        }

        return branding.receptionPage.noBookmarkedExhibitors
    }

    function getBookmarkedPageNoEntitiesRoute(): string {
        if (props.showOnlyBookmarks && props.onlyBookmarksType) {
            if (props.onlyBookmarksType === "organization") {
                return showfloorPageRoute
            } else if (props.onlyBookmarksType === "product") {
                return productsPageRoute
            } else if (props.onlyBookmarksType === "trademark") {
                return trademarksPageRoute
            } else if (props.onlyBookmarksType === "news") {
                return exhibitorNewsPageRoute
            } else if (props.onlyBookmarksType === "joboffer") {
                return joboffersPageRoute
            }
        }

        return branding.receptionPage.noBookmarkedExhibitors
    }

    // Additional params
    let isSponsor: boolean = props.isSponsorsPage ?? false
    let isMediaPartner: boolean = props.isMediaPartnerPage ?? false
    let isStartup: boolean = props.isStartupPage ?? false
    let isShowfloor = !isSponsor && !props.isMediaPartnerPage && !props.showOnlyBookmarks

    /* #region  UseEffects section */
    useEffect(() => setResetScroll(true), [entityType, viewMode])
    useEffect(() => setResetScroll(false), [resetScroll])

    function cleanRemovedFavorites() {
        const entityFavoritesFromState: string = favoriteState.get(getEntityType(entityType))

        const entityFavorites: string[] = entityFavoritesFromState
            .split(",")
            .map((item: string) => item.replace(getPrefix(getEntityType(entityType)) as string, ""))

        const results: Sections = { ...sections }
        for (let sectionKey of sectionOrder) {
            const section = sections[sectionKey]

            if (section) {
                results[sectionKey] = {
                    type: section?.type,
                    count: section?.count - 1,
                    hasMoreData: section?.hasMoreData,
                    entities: section?.entities?.filter((entity) => entityFavorites.includes(entity.id))
                }
            }
        }

        setLoaded(true)
        setSections(results)

        history.replace(getCurrentPath(entityType), {
            ...(history.location.state as ShowfloorHistoryState),
            results: results
        })
    }

    useEffect(() => {
        if (showOnlyBookmarks) {
            cleanRemovedFavorites()
        }
        // eslint-disable-next-line
    }, [favoriteState.get(getEntityType(entityType))])

    useEffect(() => {
        setBannerHeight(topBarRef && topBarRef.current ? topBarRef?.current?.clientHeight : 0)
    }, [topBarRef?.current]) // eslint-disable-line

    function setMobileBreadcrumb() {
        const entityConfig = getCurrentEntityTitleAndViewMode()

        if (props.isSponsorsPage) {
            appState.setCurrentMobileBreadcrumb(branding.sideIconBar.mediapartnersMenuText)
        } else if (props.isMediaPartnerPage) {
            appState.setCurrentMobileBreadcrumb(branding.mediaPartnerPageContent.mediaPartnerName)
        } else if (props.isStartupPage) {
            appState.setCurrentMobileBreadcrumb(branding.sideIconBar.exhibitorStartupMenuText)
        } else if (props.showOnlyBookmarks && props.onlyBookmarksType) {
            if (props.onlyBookmarksType === "organization") {
                appState.setCurrentMobileBreadcrumb(branding.exhibitorsPageContent.bookmarkedExhibitorsTitle)
            } else if (props.onlyBookmarksType === "product") {
                appState.setCurrentMobileBreadcrumb(branding.exhibitorsPageContent.bookmarkedProductsTitle)
            } else if (props.onlyBookmarksType === "trademark") {
                appState.setCurrentMobileBreadcrumb(branding.exhibitorsPageContent.bookmarkedTrademarksTitle)
            } else if (props.onlyBookmarksType === "news") {
                appState.setCurrentMobileBreadcrumb(branding.exhibitorsPageContent.bookmarkedNewsTitle)
            } else if (props.onlyBookmarksType === "joboffer") {
                appState.setCurrentMobileBreadcrumb(branding.exhibitorsPageContent.bookmarkedJoboffersTitle)
            }
        } else {
            appState.setCurrentMobileBreadcrumb(
                entityType === "organization" ? branding.sideIconBar.showfloorMenuText : entityConfig.title
            )
        }
    }

    useEffect(() => {
        setMobileBreadcrumb()
        // eslint-disable-next-line
    }, [lang, entityType])

    useEffect(() => {
        const entityConfig = getCurrentEntityTitleAndViewMode()
        const ignorePersistence =
            props.isSponsorsPage || props.isStartupPage || props.isMediaPartnerPage || props.showOnlyBookmarks

        if (isShowfloor && loaded) {
            trackSelectContent(
                branding.sideIconBar.showfloorMenuText + " - " + entityConfig.title,
                "List",
                capitalizeFirstLetter(entityType)
            )
        }

        trackPageView(
            branding.sideIconBar.showfloorMenuText + " - " + entityConfig.title,
            props.showOnlyBookmarks ? "Favorite" : "List",
            capitalizeFirstLetter(entityType)
        )

        setViewMode(props.showOnlyBookmarks ? ViewMode.LIST : entityConfig.viewMode)

        if (!ignorePersistence) {
            history.replace(getCurrentPath(entityType), {
                ...(history.location.state as ShowfloorHistoryState),
                page: 0,
                results: {}
            })
        }
    }, [entityType, appState.currentItem]) // eslint-disable-line

    function fetchEntityType(currentEntityType?: EntityType | string): EntityType | string {
        let entityTypeTemp: EntityType | string = branding.exhibitorsPageContent.initialTab

        if (!props.isSponsorsPage && !props.isStartupPage && !props.isMediaPartnerPage) {
            if (window.location.hash.endsWith("organization") || appState.lastVisitedTab === "organization") {
                entityTypeTemp = "organization"
            } else if (window.location.hash.endsWith("product") || appState.lastVisitedTab === "product") {
                entityTypeTemp = "product"
            } else if (window.location.hash.endsWith("trademark") || appState.lastVisitedTab === "trademark") {
                entityTypeTemp = "trademark"
            } else if (window.location.hash.endsWith("news") || appState.lastVisitedTab === "news") {
                entityTypeTemp = "news"
            } else if (
                window.location.hash.endsWith(branding.exhibitorsPageContent.customCategoriesUrl) ||
                appState.lastVisitedTab === "customcategory"
            ) {
                entityTypeTemp = "customcategory"
            } else if (window.location.hash.endsWith("category") || appState.lastVisitedTab === "category") {
                entityTypeTemp = "category"
            } else if (window.location.hash.endsWith("joboffer") || appState.lastVisitedTab === "joboffer") {
                entityTypeTemp = "joboffer"
            } else {
                const urlParam = window.location.hash.slice(1)
                const productTypeTab = getProductTypeTab(urlParam)
                if (productTypeTab) entityTypeTemp = productTypeTab.value
                //currentEntityType parameter is nullable because it is not accessible on the first render
                //this way we are preventing Showfloor page being rerendered infinitely when clicking on the Showfloor sidebar item while being in this page in a tab other than the initial one
                //when a user clicks on the sidebar item, the sidebar item's route does not contain a hash, so we have no way to check the hash for the entity type, and we keep the one already set
                if (currentEntityType) {
                    entityTypeTemp = currentEntityType
                }
            }
        }

        if (props.showOnlyBookmarks && props.onlyBookmarksType) {
            if (props.onlyBookmarksType === "organization") {
                entityTypeTemp = "organization"
            } else if (props.onlyBookmarksType === "product") {
                entityTypeTemp = "product"
            } else if (props.onlyBookmarksType === "trademark") {
                entityTypeTemp = "trademark"
            } else if (props.onlyBookmarksType === "news") {
                entityTypeTemp = "news"
            } else if (props.onlyBookmarksType === "joboffer") {
                entityTypeTemp = "joboffer"
            }
        }
        return entityTypeTemp
    }

    const refreshKey = [page, showOnlyBookmarks, viewMode, entityType, alpha, lang, newsSortBy, window.location.href].join("_")

    function getEntityType(value: EntityType | string): EntityType {
        if (getProductTypeTab(value)) return "product" as EntityType
        else return value as EntityType
    }

    useEffect(() => {
        const entityConfig = getCurrentEntityTitleAndViewMode()

        const entityTypeShowfloor: EntityType | string =
            props.showOnlyBookmarks || window.location.href.includes("#")
                ? fetchEntityType(entityType)
                : (appState.lastVisitedTab as EntityType) ?? fetchEntityType(entityType)
        const entityTypeTemp: EntityType | string =
            props.isSponsorsPage || props.isMediaPartnerPage || props.isStartupPage ? "organization" : entityTypeShowfloor
        searchParams.entityTypes = [getEntityType(entityTypeTemp)]
        setIsLoading(true)

        if (isShowfloor && (searchParams.alpha || dropdownFilterParams.length > 0 || productTypeFilter || categoryFilter)) {
            trackSearch(
                [searchParams.alpha, JSON.stringify(dropdownFilterParams), productTypeFilter, categoryFilter]
                    .filter(Boolean)
                    .join(","),
                branding.sideIconBar.showfloorMenuText + " - " + entityConfig?.title!
            )
        }

        fetchDataHelper(searchParams, sections)
            .then((resp) => {
                setIsLoading(false)
                const results: Sections = { ...sections }
                for (let sectionKey of sectionOrder) {
                    const oldSection = sections[sectionKey]
                    const newSection = resp[sectionKey]

                    if (!newSection || newSection.count === 0) continue

                    results[sectionKey] = {
                        type: newSection.type,
                        count: newSection.count,
                        hasMoreData: newSection.hasMoreData,
                        entities:
                            oldSection?.entities && oldSection.entities.length > 0 && page !== 0
                                ? oldSection?.entities.concat(newSection.entities)
                                : newSection.entities
                    }
                }
                setLoaded(true)
                setEntityType(entityTypeTemp)
                setSections(results)

                history.replace(getCurrentPath(entityType), {
                    ...(history.location.state as ShowfloorHistoryState),
                    results: results
                })
            })
            .catch((err) => {
                setIsLoading(false)
                setIsError(err)
            })
    }, [refreshKey, dropdownFilterParams]) // eslint-disable-line

    /* #endregion */

    /* #region  Helper methods */
    const onScroll = (scrollValues: any) => {
        if (scrollValues.contentScrollHeight > scrollValues.clientHeight) {
            setHideOnScroll(scrollValues.scrollTop > 0)
        } else {
            setHideOnScroll(true)
        }
        setScrollPosition(scrollValues.scrollTop)

        window.sessionStorage.setItem(scrollPositionKey, scrollValues.scrollTop.toString())
    }

    const handleFilterChange = (alphaKey: string) => {
        if (alphaKey !== alpha) {
            setAlpha(alphaKey)
            history.replace(getCurrentPath(entityType), {
                ...(history.location.state as ShowfloorHistoryState),
                alpha: alphaKey
            })
            clearResults()
        }
    }

    const hasMoreDataHelper = () => {
        return Object.values(sections).filter((result) => result && result.hasMoreData).length > 0
    }

    const clearResults = () => {
        setSections({})
        setPage(0)
        setHideOnScroll(false)
        setScrollPosition(0)

        window.sessionStorage.setItem(scrollPositionKey, "0")

        history.replace(getCurrentPath(entityType), {
            ...(history.location.state as ShowfloorHistoryState),
            results: {},
            page: 0
        })
    }

    const clearDropdownParams = () => {
        setDropdownFilterParams([])

        history.replace(getCurrentPath(entityType), {
            ...(history.location.state as ShowfloorHistoryState),
            dropdownFilterParams: []
        })
    }

    const getCurrentPath = (entityType: EntityType | string): string => {
        if (props.isSponsorsPage) {
            return sponsorsPageRoute
        } else if (props.isStartupPage) {
            return exhibitorStartupPageRoute
        } else if (props.isMediaPartnerPage) {
            return mediaPartnerPageRoute
        } else if (props.showOnlyBookmarks) {
            switch (props.onlyBookmarksType) {
                case "product":
                    return bookmarkedProductsPageRoute
                case "trademark":
                    return bookmarkedTrademarksPageRoute
                case "organization":
                    return bookmarkedExhibitorsPageRoute
                case "news":
                    return bookmarkedNewsPageRoute
                case "joboffer":
                    return bookmarkedJoboffersPageRoute
                default:
                    return bookmarkedExhibitorsPageRoute
            }
        } else {
            switch (entityType) {
                case "product":
                    return productsPageRoute
                case "trademark":
                    return trademarksPageRoute
                case "category":
                    return categoriesPageRoute
                case "customcategory":
                    return customCategoriesPageRoute
                case "organization":
                    return exhibitorsPageRoute
                case "news":
                    return exhibitorNewsPageRoute
                case "joboffer":
                    return joboffersPageRoute
                default:
                    const productTypeTab = getProductTypeTab(entityType)
                    if (productTypeTab) return "/showfloor#" + productTypeTab.value
                    else return exhibitorsPageRoute
            }
        }
    }

    function getCurrentBreadcrumb(): any {
        return { to: getCurrentPath(entityType), name: getCurrentEntityTitleAndViewMode().title }
    }
    /* #endregion */

    function resetSearch() {
        clearDropdownParams()
        clearResults()
    }

    function getProductTypeTab(tabValue: string) {
        if (!tabValue) return undefined

        let newProductTypeTab: ProductTypeTab | undefined = branding.exhibitorsPageContent.productTypeTabs.find(
            (productTypeTab) => productTypeTab.value?.toLowerCase() === tabValue.toLowerCase()
        )
        if (newProductTypeTab)
            return {
                value: newProductTypeTab.value.toLowerCase(),
                label: newProductTypeTab.label,
                productType: newProductTypeTab.productType
            } as ProductTypeTab
        else return undefined
    }

    let content: JSX.Element
    let totalCount = 0
    Object.values(sections).forEach((result) => {
        if (result) totalCount += result.count
    })

    function calculateDynamicHeight(defaultPixelValue: number, minPixelValue: number): string {
        if (!scrollContainerRef.current) {
            return defaultPixelValue + "px"
        }
        const rect = scrollContainerRef.current.getBoundingClientRect()

        const newHeight = windowHeight - rect.top - 3

        if (newHeight < minPixelValue) {
            return minPixelValue + "px"
        }
        return newHeight + "px"
    }

    if (isError) {
        content = (
            <div style={{ marginTop: "15%" }}>
                <BackendError />
            </div>
        )
    } else if (page === 0 && isLoading) {
        content = (
            <div style={{ marginTop: "15%" }}>
                <CenteredLoader />
            </div>
        )
    } else {
        const hasMoreData = hasMoreDataHelper()

        content = (
            <ContentRoot
                topBannerHeight={showBanner && !hideOnScroll ? bannerHeight : 0}
                guestBannerHeight={guestUserBannerRef && guestUserBannerRef.current ? guestUserBannerRef.current.clientHeight : 0}
                className={(props.showOnlyBookmarks ? "onlyBookmarksPage " : "") + entityType}
                additionalHeight={
                    entityType === "category" || entityType === "customcategory"
                        ? branding.categoriesPageContent.categoriesConfiguration.length > 0
                            ? 230
                            : 170
                        : 300
                } // because we don't have 2 filter dropdowns for category type
                filtersVisible={entityType === "category" || entityType === "customcategory" ? false : filtersVisible}
                isMobile={isMobile}
                isStartupPage={props.isStartupPage}
                isSponsorsPage={props.isSponsorsPage}
                height={calculateDynamicHeight(1000, 500)}
                ref={scrollContainerRef}
            >
                <ScrollContainer scrollTop={scrollPosition} onScroll={onScroll}>
                    {entityType === "category" ? (
                        <MainCategoriesPageContent
                            viewType={viewMode}
                            selectedRootCategory={activeSubCategory?.jsonData.rootCategoryAlias}
                            currentPath={getCurrentPath(entityType)}
                            searchParam={categorySearchParam}
                            setSearchParam={setCategorySearchParam}
                        />
                    ) : entityType === "customcategory" ? (
                        <MainCategoriesPageContent
                            viewType={viewMode}
                            selectedRootCategory={activeCustomSubCategory?.jsonData.rootCategoryAlias}
                            currentPath={getCurrentPath(entityType)}
                            searchParam={categorySearchParam}
                            setSearchParam={setCategorySearchParam}
                            custom
                        />
                    ) : (
                        <>
                            <ContentElements
                                viewMode={entityType === "organization" ? viewMode : ViewMode.LIST}
                                sections={sections}
                                entityType={entityType}
                                searchKrit={searchKrit}
                                isSponsor={isSponsor}
                                isMediaPartner={isMediaPartner}
                                isStartup={isStartup}
                                onlyBookmarks={showOnlyBookmarks}
                                setOnlyBookmarks={setShowOnlyBookmarks}
                            />
                            {hasMoreData && (
                                <InViewParent>
                                    <InView
                                        threshold={0.1}
                                        onChange={(inView) => {
                                            if (inView) {
                                                setPage(page + 1) //triggers loading of a new page of results

                                                history.replace(getCurrentPath(entityType), {
                                                    ...(history.location.state as ShowfloorHistoryState),
                                                    page: page + 2
                                                    //stores the number of the page after the one that is being triggered,
                                                    //so that the next page is loaded when a user reloads page or navigates to it by using the Back button
                                                })
                                            }
                                        }}
                                    >
                                        <NextPageLoader />
                                    </InView>
                                </InViewParent>
                            )}
                        </>
                    )}
                </ScrollContainer>
            </ContentRoot>
        )
    }

    let emptyOnlyBookmarksPage = props.showOnlyBookmarks && totalCount === 0 && !isLoading

    const itemsOrder = branding.navigationArea.crsTabsItemOrder

    itemsOrder.forEach((item, index) => {
        let newItem: TabItemType = { label: "", value: "" }
        let pushItem = true

        switch (item) {
            case "ORGANIZATION":
                newItem = {
                    label: branding.navigationArea.companiesItemTitle,
                    value: "organization",
                    actions: [ActionTypes.TILELIST, ActionTypes.BOOKMARK, ActionTypes.TOGGLEFILTERS]
                }
                break
            case "PRODUCT":
                if (branding.navigationArea.showProduct)
                    newItem = {
                        label: branding.navigationArea.productsItemTitle,
                        value: "product",
                        actions: [ActionTypes.BOOKMARK, ActionTypes.TOGGLEFILTERS]
                    }
                break
            case "TRADEMARK":
                if (branding.navigationArea.showTrademark)
                    newItem = {
                        label: branding.navigationArea.trademarksItemTitle,
                        value: "trademark",
                        actions: [ActionTypes.BOOKMARK, ActionTypes.TOGGLEFILTERS]
                    }
                break
            case "CATEGORY":
                if (branding.navigationArea.showCategories)
                    newItem = {
                        label: branding.categoriesPageContent.categoriesPageTitle,
                        value: "category",
                        actions: [ActionTypes.TILELIST, ActionTypes.TOGGLEFILTERS]
                    }
                break
            case "CUSTOM_CATEGORY":
                if (branding.navigationArea.showCategories)
                    newItem = {
                        label: branding.exhibitorsPageContent.customCategoriesTitle,
                        value: "customcategory",
                        actions: [ActionTypes.TILELIST, ActionTypes.TOGGLEFILTERS]
                    }
                break
            case "NEWS":
                newItem = {
                    label: branding.navigationArea.newsItemTitle,
                    value: "news",
                    actions: [ActionTypes.BOOKMARK, ActionTypes.TOGGLEFILTERS]
                }
                break
            case "JOBOFFER":
                newItem = {
                    label: branding.exhibitorsPageContent.jobofferTabTitle,
                    value: "joboffer",
                    actions: [ActionTypes.BOOKMARK, ActionTypes.TOGGLEFILTERS]
                }
                break
            default:
                const productType = getProductTypeTab(item)
                if (productType) {
                    newItem = {
                        label: productType.label,
                        value: productType.value,
                        actions: [ActionTypes.BOOKMARK]
                    }
                } else pushItem = false
                break
        }

        if (pushItem) entityFilterItems.push(newItem)
    })

    const isNotSponsorMediaStartupBookmark =
        !props.isMediaPartnerPage && !props.isSponsorsPage && !props.isStartupPage && !props.showOnlyBookmarks

    const dropdowns = branding.exhibitorsPageContent.dropdownFilterConfig
    return (
        <>
            <div style={{ background: "#fff" }}>
                <DesktopVersionContainer>
                    <GuestUserBanner setRef={setGuestUserBannerRef} />
                    <TopBar setRef={setTopBarRef} />
                    {!props.showOnlyBookmarks && (
                        <TopBanner
                            type={
                                isSponsor
                                    ? "partners_sponsors"
                                    : isMediaPartner
                                    ? "mediapartner"
                                    : isStartup
                                    ? "startup"
                                    : "showfloor"
                            }
                            setRef={setTopBarRef}
                            hideOnScroll={hideOnScroll}
                            onHideBanner={() => {
                                setShowBanner(false)
                            }}
                        />
                    )}

                    <Breadcrumb
                        breadcrumb={
                            props.showOnlyBookmarks
                                ? getBookmarkedPageBreadcrumb()
                                : props.isMediaPartnerPage
                                ? [{ to: mediaPartnerPageRoute, name: branding.mediaPartnerPageContent.mediaPartnerName }]
                                : props.isStartupPage
                                ? [
                                      {
                                          to: exhibitorStartupPageRoute,
                                          name: branding.exhibitorsPageContent.exhibitorsStartupPageName
                                      }
                                  ]
                                : props.isSponsorsPage
                                ? [
                                      {
                                          to: locations[0],
                                          name: branding.sideIconBar.mediapartnersMenuText
                                      }
                                  ]
                                : [
                                      {
                                          to: locations[0],
                                          name: branding.sideIconBar.showfloorMenuText
                                      },
                                      getCurrentBreadcrumb()
                                  ]
                        }
                        disableGTMTracking={isShowfloor}
                        classification="List"
                        contentType={capitalizeFirstLetter(entityType)}
                    />
                </DesktopVersionContainer>

                {!props.showOnlyBookmarks && isNotSponsorMediaStartupBookmark ? (
                    <CrsTabs
                        hideBorderBottom={history.location.pathname === "/startup" || history.location.pathname === "/sponsor"}
                        tabItems={entityFilterItems}
                        activeItem={entityType}
                        onTabItemClicked={(value: string) => {
                            if (value !== entityType) {
                                // clearing category filters when tab is switched
                                setDropdownFilterParams([])
                                setEntityType(value)
                                appState.setLastVisitedTab(value)

                                history.replace(getCurrentPath(value), {
                                    ...(history.location.state as ShowfloorHistoryState),
                                    viewMode: null,
                                    showOnlyBookmarks: showOnlyBookmarks,
                                    dropdownFilterParams: [],
                                    scrollPosition: scrollPosition
                                })

                                clearResults()
                            }
                        }}
                        viewMode={
                            entityType === "organization" || entityType === "category" || entityType === "customcategory"
                                ? viewMode
                                : undefined
                        }
                        onViewModeChange={(viewMode) => {
                            setViewMode(viewMode)
                            history.replace(getCurrentPath(entityType), {
                                ...(history.location.state as ShowfloorHistoryState),
                                viewMode: viewMode
                            })
                        }}
                        bookmarkFilter={showOnlyBookmarks}
                        setBookmarkFilter={(value: boolean) => {
                            setShowOnlyBookmarks(value)
                            history.replace(getCurrentPath(entityType), {
                                ...(history.location.state as ShowfloorHistoryState),
                                showOnlyBookmarks: value
                            })

                            if (value) {
                                switch (entityType) {
                                    case "organization": {
                                        trackSelectContent(
                                            branding.exhibitorsPageContent.bookmarkedExhibitorsTitle,
                                            "Favorite",
                                            "Organization"
                                        )
                                        break
                                    }

                                    case "product": {
                                        trackSelectContent(
                                            branding.exhibitorsPageContent.bookmarkedProductsTitle,
                                            "Favorite",
                                            "Product"
                                        )
                                        break
                                    }

                                    case "trademark": {
                                        trackSelectContent(
                                            branding.exhibitorsPageContent.bookmarkedTrademarksTitle,
                                            "Favorite",
                                            "Trademark"
                                        )
                                        break
                                    }

                                    case "joboffer": {
                                        trackSelectContent(
                                            branding.exhibitorsPageContent.bookmarkedJoboffersTitle,
                                            "Favorite",
                                            "Joboffer"
                                        )
                                        break
                                    }

                                    case "news": {
                                        trackSelectContent(branding.exhibitorsPageContent.bookmarkedNewsTitle, "Favorite", "News")
                                        break
                                    }
                                }
                            }

                            clearResults()
                        }}
                        filtersVisible={filtersVisible}
                        setFiltersVisible={setFiltersVisible}
                        showOnlyActionButtons={isStartup || isSponsor || props.showOnlyBookmarks}
                        pageUsingTileView={PagesUsingTileView.SHOWFLOOR}
                    />
                ) : (
                    <>
                        {!props.showOnlyBookmarks ? (
                            <HideOnMobileAndIpadContainer>
                                <CrsTabs
                                    hideBorderBottom={
                                        history.location.pathname === "/startup" || history.location.pathname === "/sponsor"
                                    }
                                    tabItems={entityFilterItems}
                                    activeItem={entityType}
                                    onTabItemClicked={(value: string) => {
                                        if (value !== entityType) {
                                            setEntityType(value)
                                            appState.setLastVisitedTab(value)

                                            history.replace(getCurrentPath(value), {
                                                ...(history.location.state as ShowfloorHistoryState),
                                                viewMode: null,
                                                showOnlyBookmarks: showOnlyBookmarks,
                                                dropdownFilterParams: [],
                                                scrollPosition: scrollPosition
                                            })

                                            clearResults()
                                        }
                                    }}
                                    viewMode={entityType === "organization" || entityType === "category" ? viewMode : undefined}
                                    onViewModeChange={(viewMode) => {
                                        setViewMode(viewMode)
                                        history.replace(getCurrentPath(entityType), {
                                            ...(history.location.state as ShowfloorHistoryState),
                                            viewMode: viewMode
                                        })
                                    }}
                                    bookmarkFilter={showOnlyBookmarks}
                                    setBookmarkFilter={(value: boolean) => {
                                        setShowOnlyBookmarks(value)
                                        history.replace(getCurrentPath(entityType), {
                                            ...(history.location.state as ShowfloorHistoryState),
                                            showOnlyBookmarks: value
                                        })
                                        clearResults()
                                    }}
                                    showOnlyActionButtons={isStartup || isSponsor || props.showOnlyBookmarks}
                                    pageUsingTileView={PagesUsingTileView.SHOWFLOOR}
                                />
                            </HideOnMobileAndIpadContainer>
                        ) : null}
                    </>
                )}

                {entityType === "customcategory" &&
                branding.categoriesPageContent.customCategoriesConfiguration.length <= 1 ? null : (entityType === "category" &&
                      branding.categoriesPageContent.categoriesConfiguration.length > 0) ||
                  (entityType === "customcategory" && branding.categoriesPageContent.customCategoriesConfiguration.length > 1) ? (
                    <CategoriesSlider
                        hideOnScroll
                        filtersVisible
                        categoriesConfiguration={
                            entityType === "category"
                                ? branding.categoriesPageContent.categoriesConfiguration
                                : branding.categoriesPageContent.customCategoriesConfiguration
                        }
                        activeSubCategory={entityType === "category" ? activeSubCategory : activeCustomSubCategory}
                        onSliderItemClick={(category: CategoryConfigType, viewMode: ViewMode) => {
                            entityType === "category" && setActiveSubCategory(category)
                            entityType === "customcategory" && setActiveCustomSubCategory(category)

                            if (entityType === "category") {
                                history.replace(getCurrentPath(entityType), {
                                    ...(history.location.state as ShowfloorHistoryState),
                                    activeSubCategory: category
                                })
                            } else if (entityType === "customcategory") {
                                history.replace(getCurrentPath(entityType), {
                                    ...(history.location.state as ShowfloorHistoryState),
                                    activeCustomSubCategory: category
                                })
                            }
                            setViewMode(viewMode)
                            history.replace(getCurrentPath(entityType), {
                                ...(history.location.state as ShowfloorHistoryState),
                                viewMode: viewMode
                            })
                        }}
                    />
                ) : (
                    <DesktopVersionContainer>
                        <FilterBar
                            showTopBorder={isStartup || isSponsor || props.showOnlyBookmarks}
                            activeFilter={alpha}
                            onFilterChanged={(alphaKey: string) => handleFilterChange(alphaKey)}
                        />
                    </DesktopVersionContainer>
                )}

                <ExhibitorsContainer viewMode={viewMode}>
                    {emptyOnlyBookmarksPage && (
                        <div style={{ marginTop: "15%" }}>
                            <EmptyTile
                                header={getBookmarkedPageNoEntitiesMessage()}
                                buttonNavLink={getBookmarkedPageNoEntitiesRoute()}
                                buttonMessage={branding.receptionPage.showFloorNavLinkText}
                                hideButton={true}
                                bgColor="transparent"
                            />
                        </div>
                    )}
                    {!emptyOnlyBookmarksPage && (
                        <ContainerRoot>
                            {entityType !== "category" && entityType !== "customcategory" && !props.showOnlyBookmarks && (
                                <DropdownsContentRoot isMobile={isMobile}>
                                    <FilterBarContainer
                                        className={
                                            !isSponsor && !isMediaPartner && !isStartup && includeFilterCategories
                                                ? "d-flex justify-content-between align-items-end"
                                                : ""
                                        }
                                        visible={isMobile ? filtersVisible : true}
                                        showShadow={hideOnScroll}
                                    >
                                        <HeaderContainer>
                                            {dropdownDataLoaded ? (
                                                <DropdownsRoot>
                                                    {!isSponsor &&
                                                        !isMediaPartner &&
                                                        !isStartup &&
                                                        includeFilterCategories &&
                                                        dropdownDataLoaded && (
                                                            <DropdownsContent isMobile={isMobile}>
                                                                {dropdowns.length &&
                                                                    dropdownData &&
                                                                    dropdownDataLoaded &&
                                                                    dropdowns.map((dropdown: any) => {
                                                                        return (
                                                                            dropdownData[`${dropdown.id}`] &&
                                                                            dropdown.visibleForEntities.includes(entityType) &&
                                                                            dropdownData[`${dropdown.id}`].data && (
                                                                                <DropdownComponent
                                                                                    categories={
                                                                                        dropdownData[`${dropdown.id}`].data
                                                                                    }
                                                                                    defaultTitle={dropdown.defaultTitle}
                                                                                    searchParamFilterValue={
                                                                                        dropdownFilterParams.find(
                                                                                            ({ dropdownId }) =>
                                                                                                dropdownId === dropdown.id
                                                                                        )?.alias
                                                                                    }
                                                                                    onClick={(alias: any) => {
                                                                                        if (
                                                                                            !dropdownFilterParams.find(
                                                                                                ({ dropdownId }) =>
                                                                                                    dropdownId === dropdown.id
                                                                                            )
                                                                                        ) {
                                                                                            // if empty or there is no data from this dropdown push to the array
                                                                                            const dropdownFilterParamsNew = [
                                                                                                ...dropdownFilterParams,
                                                                                                {
                                                                                                    dropdownId: dropdown.id,
                                                                                                    alias: alias,
                                                                                                    type: dropdown.type
                                                                                                }
                                                                                            ]

                                                                                            setDropdownFilterParams(
                                                                                                dropdownFilterParamsNew
                                                                                            )
                                                                                            history.replace(
                                                                                                getCurrentPath(entityType),
                                                                                                {
                                                                                                    ...(history.location
                                                                                                        .state as ShowfloorHistoryState),
                                                                                                    dropdownFilterParams:
                                                                                                        dropdownFilterParamsNew
                                                                                                }
                                                                                            )
                                                                                            clearResults()
                                                                                        } else {
                                                                                            // update the array with the current dropdown data
                                                                                            let dropdownFilterParamsCopy = [
                                                                                                ...dropdownFilterParams
                                                                                            ]

                                                                                            dropdownFilterParamsCopy.forEach(
                                                                                                (el) => {
                                                                                                    if (
                                                                                                        el.dropdownId ===
                                                                                                        dropdown.id
                                                                                                    ) {
                                                                                                        el.alias = alias
                                                                                                    }
                                                                                                }
                                                                                            )

                                                                                            setDropdownFilterParams(
                                                                                                dropdownFilterParamsCopy
                                                                                            )
                                                                                            history.replace(
                                                                                                getCurrentPath(entityType),
                                                                                                {
                                                                                                    ...(history.location
                                                                                                        .state as ShowfloorHistoryState),
                                                                                                    dropdownFilterParams:
                                                                                                        dropdownFilterParamsCopy
                                                                                                }
                                                                                            )
                                                                                            clearResults()
                                                                                        }

                                                                                        let newSearchKrit = ""

                                                                                        dropdownFilterParams.forEach((el) => {
                                                                                            if (el.alias) {
                                                                                                newSearchKrit +=
                                                                                                    (el.alias || "_") + "##"
                                                                                            }
                                                                                        })
                                                                                        setSearchKrit(newSearchKrit)
                                                                                    }}
                                                                                />
                                                                            )
                                                                        )
                                                                    })}

                                                                {entityType === "news" &&
                                                                    branding.exhibitorsPageContent.newsSortByFilterVisible && (
                                                                        <StyledSelect
                                                                            isMulti={false}
                                                                            isSearchable={false}
                                                                            isClearable={false}
                                                                            options={newsSortByOptions}
                                                                            value={newsSortByOptions[newsSortBy]}
                                                                            noOptionsMessage={() =>
                                                                                branding.exhibitorsPageContent
                                                                                    .filterNoOptionsMessage
                                                                            }
                                                                            onChange={(
                                                                                value: ValueType<OptionTypeBase, boolean>,
                                                                                action: ActionMeta<OptionTypeBase>
                                                                            ) => {
                                                                                if (
                                                                                    value !== null &&
                                                                                    action.action === "select-option"
                                                                                ) {
                                                                                    const option = value as OptionTypeBase
                                                                                    setNewsSortBy(option.value)

                                                                                    history.replace(getCurrentPath(entityType), {
                                                                                        ...(history.location
                                                                                            .state as ShowfloorHistoryState),
                                                                                        newsSortBy: option.value
                                                                                    })
                                                                                }
                                                                            }}
                                                                            theme={SelectThemeCustom}
                                                                        />
                                                                    )}

                                                                <MobileVersionContainer>
                                                                    <OnlyBookmarksButton
                                                                        bookmarkFilter={showOnlyBookmarks}
                                                                        updateBookmarkFilter={() => {
                                                                            setShowOnlyBookmarks(!showOnlyBookmarks)
                                                                            clearResults()
                                                                        }}
                                                                    />
                                                                </MobileVersionContainer>
                                                                <FilterButtonsComponent
                                                                    onResetFilterButtonClick={() => resetSearch()}
                                                                    resetFilterVisible={showResetFilterButton}
                                                                />
                                                            </DropdownsContent>
                                                        )}
                                                </DropdownsRoot>
                                            ) : (
                                                <CenteredLoader loaderWrapperStyle={{ marginTop: "20px" }} size="md" />
                                            )}
                                        </HeaderContainer>
                                    </FilterBarContainer>
                                    <MobileVersionContainer>
                                        {ActionTypes.TOGGLEFILTERS && !props.isStartupPage && !props.isSponsorsPage && (
                                            <ToggleFilterDiv filterVisible={filtersVisible}>
                                                <ToggleFiltersButton
                                                    isMobile={isMobile}
                                                    setFiltersVisible={setFiltersVisible}
                                                    filtersVisible={filtersVisible}
                                                />
                                            </ToggleFilterDiv>
                                        )}
                                    </MobileVersionContainer>
                                </DropdownsContentRoot>
                            )}
                            {branding.categoriesPageContent.tabsUsingSearch.includes(entityType as CategoryTab) && (
                                <DropdownsContentRoot>
                                    <FilterBarContainer visible showShadow={hideOnScroll} className="categorysearch">
                                        <SearchBar
                                            setSearchParam={(value: string) => {
                                                setCategorySearchParam(value)

                                                history.replace(getCurrentPath(entityType), {
                                                    ...(history.location.state as ShowfloorHistoryState),
                                                    categorySearchParam: value
                                                })
                                            }}
                                            searchValue={categorySearchParam}
                                            placeholder={branding.categoriesPageContent.searchBarPlaceholder}
                                            width={useMobileDesign ? "100%" : "40%"}
                                            height={"38px"}
                                            bottom={"10px"}
                                            bottomEnd={"8px"}
                                            isSearchBar
                                        />
                                    </FilterBarContainer>
                                </DropdownsContentRoot>
                            )}
                            {content}
                        </ContainerRoot>
                    )}
                </ExhibitorsContainer>
            </div>
        </>
    )
}

interface ContentElementsProps {
    onlyBookmarks: boolean
    viewMode: ViewMode
    sections: Sections
    isSponsor: boolean
    isMediaPartner: boolean
    isStartup: boolean
    entityType: EntityType | string
    searchKrit?: string
    setOnlyBookmarks: (value: boolean) => void
}

export const NoResultsContent = styled.div`
    font-family: ${branding.font1};
    color: ${branding.exhibitorsPageContent.noSearchResultsTextColor};
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    margin-top: 15%;
    text-align: center;
`

const ContentElements: React.FC<ContentElementsProps> = (props) => {
    const windowSize = useWindowDimensions()
    const tileViewMinReqWidth = getTileViewMinReqWidth(PagesUsingTileView.SHOWFLOOR)

    if (props.viewMode === ViewMode.LIST || windowSize.width < tileViewMinReqWidth) {
        return !props.sections.all ? (
            <NoResultsContent>{branding.exhibitorsPageContent.noSearchResultsText}</NoResultsContent>
        ) : (
            <ResultListLayout
                sections={props.sections}
                entityType={props.entityType}
                isSponsor={props.isSponsor}
                isMediaPartner={props.isMediaPartner}
                isStartup={props.isStartup}
                searchKrit={props.searchKrit}
            />
        )
    } else {
        return !props.sections.all ? (
            <NoResultsContent>{branding.exhibitorsPageContent.noSearchResultsText}</NoResultsContent>
        ) : (
            <CompaniesTilesLayout
                sections={props.sections}
                type={"organization"}
                isSponsor={props.isSponsor}
                isMediaPartner={props.isMediaPartner}
                isStartup={props.isStartup}
                searchKrit={props.searchKrit}
            />
        )
    }
}

export default ExhibitorsPageContent
