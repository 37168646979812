import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { CalendarEntryParticipationStatus } from "../../../API"
import {
    CalendarEntry,
    CalendarEntryParticipation,
    updateCalendarEntryParticipationStatus,
    UserBusyTime
} from "../../../backendServices/GraphQLServices"
import { InvitePerson } from "../../../backendServices/Types"
import branding from "../../../branding/branding"
import { useAppState } from "../../../globalStates/AppState"
import { useLoggedInState } from "../../../globalStates/LoggedInUser"
import DataUpdateAlert from "../../DataUpdateAlert"
import { IconClose, IconEdit, IconIndicatorErrorBlack } from "../../Icons"
import { MeetingSlotsDay } from "../../MeetingSlots"
import useWindowDimensions from "../../WindowDimensionsHook"
import { Obligations } from "../CalendarEntryModal"
import DeleteCalendarEntryModal from "../DeleteCalendarEntryModal"
import AvailabilityCalendar from "./AvailabilityCalendar"
import CalendarEntryForm from "./CalendarEntryForm"
import {
    CloseButton,
    Content,
    ContentHeader,
    ContentTitle,
    IconEditBackground,
    Layout,
    MainContent
} from "./CalendarEntryModal.styled"
import { data } from "./CalendarEntryModalData2"
import {
    calculateStartEndDate,
    CalendarEntryModalViewMode,
    deleteCalendarEntry,
    Location,
    resetTimes,
    showModalMeetingTitle
} from "./ModalFunctions"
import moment from "moment"
import ReactDOM from "react-dom"
import { PageViewEventClass, trackPageView } from "../../../utils/GTMTracking"

export interface CalendarEntryModal2Props {
    sotUser?: InvitePerson[]
    close?: () => void
    predefinedStartTime?: Date
    predefinedTitle?: string
    calendarEntry?: CalendarEntry
    viewMode?: CalendarEntryModalViewMode
    meetingSlotsTable?: MeetingSlotsDay[]
    timeSlotInterval?: number
    onRemovedFromList?: () => void
    onUpdated?: (calendarEntry: CalendarEntry) => void
    organizationId?: string
    organizationName?: string
    setReloadScheduleList?: Dispatch<SetStateAction<boolean>>
    isPast?: boolean
    setViewMode?: Dispatch<SetStateAction<CalendarEntryModalViewMode | undefined>>
}

const CalendarEntryModal2 = (props: CalendarEntryModal2Props) => {
    const [meetings, setMeetings] = useState<Obligations[]>([])
    const [openAvailabilityCalendar, setOpenAvailabilityCalendar] = useState<boolean>(true)
    const [showCancelModal, setShowCancelModal] = useState<boolean>(false)
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
    const [errorModalMessage, setErrorModalMessage] = useState<string>(branding.calendarEntryModalPageContent.fieldError)
    const [showDeclineModal, setShowDeclineModal] = useState<boolean>(false)
    const [title, setTitle] = useState(props.calendarEntry ? props.calendarEntry.title : "")
    const TIME_INTERVAL = props.timeSlotInterval ?? 15
    const [startTime, setStartTime] = useState<Date | null>(
        props.calendarEntry
            ? new Date(props.calendarEntry.start)
            : props.predefinedStartTime
            ? moment(props.predefinedStartTime).toDate()
            : null
    )
    const [endTime, setEndTime] = useState<Date | null>(
        props.calendarEntry
            ? new Date(props.calendarEntry.end)
            : props.predefinedStartTime
            ? moment(props.predefinedStartTime).add(TIME_INTERVAL, "m").toDate()
            : null
    )
    const [note, setNote] = useState<string | undefined>(props.calendarEntry ? props.calendarEntry.description : "")
    const [date, setDate] = useState<Date | null>(
        props.predefinedStartTime
            ? moment(props.predefinedStartTime).toDate()
            : props.calendarEntry
            ? moment(props.calendarEntry.start).toDate()
            : new Date()
    )
    const [location, setLocation] = useState<Location>()
    // eslint-disable-next-line
    const participantLimit = branding.configuration.calendarEntryParticipantLimit
    const appState = useAppState()
    const [selectedTimezone, setSelectedTimezone] = useState(appState.timezone)
    const [isVirtualMeeting, setIsVirtualMeeting] = useState<boolean>(
        props.calendarEntry?.isVirtual === undefined ? false : props.calendarEntry.isVirtual
    )
    const [canEditMeeting, setCanEditMeeting] = useState<boolean>(false)
    const userlink = useLoggedInState()
    const user = userlink.user()
    const profileId = user?.profileId
    const organizer: InvitePerson = {
        id: profileId ?? "",
        firstName: user?.firstName ?? "",
        lastName: user?.lastName,
        logoUrl: user?.logoUrl
    }

    const invited = props.sotUser || []
    if (!invited.find((item) => item.id === organizer.id)) {
        invited.unshift(organizer)
    }
    const [contactsInvited, setContactsInvited] = useState<InvitePerson[]>(invited)
    const [filteredBusyUsers, setFilteredBusyUsers] = useState<UserBusyTime[]>()
    const [times, setTimes] = useState<UserBusyTime[]>([])
    const windowSize = useWindowDimensions()
    let positionLeft = 0
    let timer = calculateStartEndDate(startTime!, endTime!)

    function getModalType(): PageViewEventClass {
        if (props.organizationId) {
            return "RequestMeeting"
        }

        switch (props.viewMode) {
            case CalendarEntryModalViewMode.CREATE:
                return "AddAppointment"
            case CalendarEntryModalViewMode.EDIT:
                return "EditAppointment"
            case CalendarEntryModalViewMode.VIEW:
                return "ViewAppointment"
            default:
                return "AddAppointment"
        }
    }

    useEffect(() => {
        if (props.calendarEntry) {
            const participations = props.calendarEntry.participants.items
            // Shift organizer participation to first index
            participations.unshift(
                participations.splice(
                    participations.findIndex((participation) => participation.userId === props.calendarEntry?.userId),
                    1
                )[0]
            )

            setContactsInvited(
                participations.map((participation) => {
                    return {
                        logoUrl: participation.user.pictureUrl,
                        id: participation.user.id,
                        firstName: participation.user.name,
                        status: participation.status
                    }
                })
            )
        }

        trackPageView(
            showModalMeetingTitle(props.viewMode, props.predefinedTitle, canEditMeeting)!,
            getModalType(),
            props.organizationId ? "Organization" : "Schedule"
        )
        // eslint-disable-next-line
    }, [props.calendarEntry, props.viewMode])

    //move to ModalFunctions.ts
    function onParticipantInvited(item: any) {
        if (contactsInvited.length >= participantLimit) {
            return
        }
        setContactsInvited(contactsInvited.concat(item))
    }

    function onCloseModalView() {
        if (profileId === undefined) {
            return
        }
        if (props.viewMode === CalendarEntryModalViewMode.CREATE) {
            setContactsInvited(props.sotUser ? [organizer].concat(props.sotUser) : [organizer])
            setNote("")
            setTitle("")
            if (props.calendarEntry) {
                setStartTime(moment(props.calendarEntry.start).toDate())
                setEndTime(moment(props.calendarEntry.end).toDate())
                setDate(moment(props.calendarEntry.start).toDate())
            } else {
                resetTimes(setStartTime, setEndTime)
                setDate(new Date())
            }
        }
        props.close!()
    }

    const myParticipation = props.calendarEntry?.participants.items.find((participation) => profileId === participation.userId)

    async function declineMeeting() {
        setShowDeclineModal(false)
        if (profileId != null && props.calendarEntry?.id != null) {
            if (myParticipation) {
                const result = await updateCalendarEntryParticipationStatus(
                    myParticipation.id,
                    profileId,
                    undefined,
                    CalendarEntryParticipationStatus.DECLINED
                )

                if (result != null) {
                    onCloseModalView()
                    if (props.onRemovedFromList) {
                        props.onRemovedFromList()
                    }
                } else {
                    // TODO ERROR WHAT TO SHOW HERE?
                }
            }
        }

        props.close!()
    }

    async function onAcceptClicked(participation: CalendarEntryParticipation | undefined) {
        const result = await updateCalendarEntryParticipationStatus(
            participation!.id,
            participation!.userId,
            undefined,
            CalendarEntryParticipationStatus.ACCEPTED
        )

        if (result != null) {
            props.onRemovedFromList!()
            props.setReloadScheduleList!(false)
            props.setReloadScheduleList!(true)
        } else {
            // TODO ERROR
        }
    }

    useEffect(() => {
        // Set end time to null to show placeholder
        if (startTime === null) {
            setEndTime(null)
        }
    }, [date, startTime])

    return (
        <Layout
            openLayout={
                openAvailabilityCalendar &&
                (canEditMeeting ? CalendarEntryModalViewMode.EDIT : props.viewMode) !== CalendarEntryModalViewMode.VIEW
            }
            show
            animation={false}
        >
            <Content
                openLayout={
                    windowSize.isMobile ||
                    (canEditMeeting ? CalendarEntryModalViewMode.EDIT : props.viewMode) === CalendarEntryModalViewMode.VIEW
                }
            >
                <ContentHeader>
                    <ContentTitle>
                        {showModalMeetingTitle(props.viewMode, props.predefinedTitle, canEditMeeting)}
                        {props.viewMode === CalendarEntryModalViewMode.VIEW &&
                            contactsInvited[0].id === organizer.id &&
                            !props.isPast && (
                                <IconEditBackground
                                    canEditMeeting={canEditMeeting}
                                    onClick={() => {
                                        setCanEditMeeting(true)
                                        props.setViewMode?.(CalendarEntryModalViewMode.EDIT)
                                    }}
                                >
                                    <IconEdit width="14px" height="14px" fill={branding.sideIconBar.sideIconColorDark} />
                                </IconEditBackground>
                            )}
                    </ContentTitle>
                    {windowSize.isMobile ||
                        ((canEditMeeting ? CalendarEntryModalViewMode.EDIT : props.viewMode) ===
                            CalendarEntryModalViewMode.VIEW && (
                            <CloseButton
                                onClick={() => {
                                    props.close?.()
                                }}
                            >
                                <IconClose fill={branding.recommendModal.closeIconColor} width="15px" height="15px" />
                            </CloseButton>
                        ))}
                </ContentHeader>
                <MainContent>
                    <CalendarEntryForm
                        data={data(branding)}
                        setOpenAvailabilityCalendar={setOpenAvailabilityCalendar}
                        personClicked={onParticipantInvited}
                        contactsInvited={contactsInvited}
                        setContactsInvited={setContactsInvited}
                        meetingOrganizer={organizer}
                        user={user}
                        predefinedStartTime={props.predefinedStartTime}
                        meetingSlotsTable={props.meetingSlotsTable}
                        viewMode={props.viewMode}
                        predefinedTitle={props.predefinedTitle}
                        setShowErrorModal={setShowErrorModal}
                        closeModal={() => props.close!()}
                        selectedTimezone={selectedTimezone}
                        setSelectedTimezone={setSelectedTimezone}
                        startTime={startTime}
                        setStartTime={setStartTime}
                        endTime={endTime}
                        setEndTime={setEndTime}
                        date={date}
                        setDate={setDate}
                        calendarEntry={props.calendarEntry}
                        setShowCancelModal={setShowCancelModal}
                        note={note}
                        setNote={setNote}
                        title={title}
                        setTitle={setTitle}
                        setShowDeclineModal={setShowDeclineModal}
                        setIsVirtualMeeting={setIsVirtualMeeting}
                        isVirtualMeeting={isVirtualMeeting}
                        location={location}
                        setLocation={setLocation}
                        organizationId={props.organizationId}
                        onUpdated={props.onUpdated}
                        sotUser={props.sotUser}
                        setErrorModalMessage={setErrorModalMessage}
                        meetings={meetings}
                        setMeetings={setMeetings}
                        times={times}
                        setTimes={setTimes}
                        positionLeft={positionLeft}
                        timer={timer}
                        canEditMeeting={canEditMeeting}
                        setCanEditMeeting={setCanEditMeeting}
                        acceptMeeting={onAcceptClicked}
                    />
                </MainContent>
            </Content>
            {openAvailabilityCalendar &&
                !windowSize.isMobile &&
                (canEditMeeting ? CalendarEntryModalViewMode.EDIT : props.viewMode) !== CalendarEntryModalViewMode.VIEW && (
                    <AvailabilityCalendar
                        setOpenAvailabilityCalendar={setOpenAvailabilityCalendar}
                        openAvailabilityCalendar={openAvailabilityCalendar}
                        closeModal={props.close!}
                        selectedTimezone={selectedTimezone}
                        contactsInvited={contactsInvited}
                        startTime={startTime}
                        setStartTime={setStartTime}
                        endTime={endTime}
                        setEndTime={setEndTime}
                        date={date}
                        setDate={setDate}
                        timezone={selectedTimezone}
                        setFilteredBusyUsers={setFilteredBusyUsers}
                        filteredBusyUsers={filteredBusyUsers}
                        meetings={meetings}
                        setMeetings={setMeetings}
                        times={times}
                        setTimes={setTimes}
                        positionLeft={positionLeft}
                        timer={timer}
                        viewMode={canEditMeeting ? CalendarEntryModalViewMode.EDIT : props.viewMode}
                    />
                )}
            {showErrorModal && (
                <DataUpdateAlert
                    message={errorModalMessage}
                    close={() => setShowErrorModal(false)}
                    show={showErrorModal}
                    type="calendar-entry-modal"
                    timeoutSeconds={3}
                    titleImage={IconIndicatorErrorBlack({ fill: branding.sideIconBar.sideIconColorDark })}
                    position="bottom"
                />
            )}

            {showCancelModal && (
                <DeleteCalendarEntryModal
                    backdrop="static"
                    type="cancel"
                    animation={false}
                    show={showCancelModal}
                    deleteCalendarEntry={() =>
                        deleteCalendarEntry(
                            setShowCancelModal,
                            profileId,
                            props.calendarEntry,
                            onCloseModalView,
                            props.onRemovedFromList
                        )
                    }
                    setShowConfirmationDialog={() => setShowCancelModal(false)}
                />
            )}

            {showDeclineModal && (
                <DeleteCalendarEntryModal
                    backdrop="static"
                    type="decline"
                    animation={false}
                    show={showDeclineModal}
                    deleteCalendarEntry={() => declineMeeting()}
                    setShowConfirmationDialog={() => setShowDeclineModal(false)}
                />
            )}
        </Layout>
    )
}

export default CalendarEntryModal2

export const useCalendarEntryModal2 = (closeCallback?: () => void, openCallback?: () => void) => {
    const [isVisible, setIsVisible] = useState(false)
    const [data, setData] = useState<CalendarEntryModal2Props>({} as any)

    const showCalendarEntryModal = (props: CalendarEntryModal2Props) => {
        setData(props)
        if (openCallback) openCallback()
        setIsVisible(true)
    }

    const CalendarModal = () =>
        ReactDOM.createPortal(
            <React.Fragment>
                {isVisible && (
                    <CalendarEntryModal2
                        {...data}
                        close={() => {
                            setIsVisible(false)

                            if (closeCallback) {
                                closeCallback()
                            }
                        }}
                    />
                )}
            </React.Fragment>,
            document.body
        )

    return {
        showCalendarEntryModal,
        CalendarModal
    }
}
