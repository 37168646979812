import { format } from "date-fns"
import de from "date-fns/locale/de"
import en from "date-fns/locale/en-GB"
import moment from "moment"
import queryString from "query-string"
import * as React from "react"
import { useEffect, useRef, useState } from "react"
import { Col, Container, Modal, Row, Tooltip } from "react-bootstrap"
import ReactDOM from "react-dom"
import Linkify from "react-linkify"
import { useHistory, useLocation } from "react-router-dom"
import Slider from "react-slick"
import styled from "styled-components"
import { isUserEdited } from "../../../App"
import { syncFavorites } from "../../../backendServices/FavoritesServices"
import {
    AccessProvider,
    Attachment,
    Category,
    Channel,
    Company,
    Description,
    EntityType,
    Event,
    EventDate,
    Exhibitor,
    JobOffer,
    Link,
    News,
    Person,
    Product,
    ShareTargetType,
    SocialMedia,
    Stand,
    Trademark
} from "../../../backendServices/Types"
import { BasisPremiumType, getBasisPremiumConfig } from "../../../branding/BasisPremiumBranding"
import branding from "../../../branding/branding"
import { useChimeContext } from "../../../conference/context/ChimeContext"
import { useMeetingContext } from "../../../conference/context/MeetingContext"
import { useAppState } from "../../../globalStates/AppState"
import { useCategoriesState } from "../../../globalStates/CategoriesState"
import { useFavoriteState } from "../../../globalStates/Favorites"
import { getIamPartOf } from "../../../globalStates/IAmPartOf"
import { useLanguageState } from "../../../globalStates/LanguageState"
import { useLoggedInState } from "../../../globalStates/LoggedInUser"
import { useUserRestrictedAreaAccess } from "../../../globalStates/UserRestrictedAreaAccess"
import { onAppDownloadClick } from "../../../MobileWebsiteCheck"
import BadgeArea from "../../../ui/BadgeArea"
import BookmarkWithToggle from "../../../ui/BookmarkWithToggle"
import { CalendarEntryModalViewMode } from "../../../ui/modals/calendarEntry/ModalFunctions"
import { useCalendarEntryModal2 } from "../../../ui/modals/calendarEntry/CalendarEntryModal2"
import PersonComponent from "../../../ui/carousels/components/PersonComponent"
import { ConditionalWrapper } from "../../../ui/ConditionalWrapper"
import ContextMenu, { MenuItemProps } from "../../../ui/ContextMenu"
import {
    IconShowMore,
    IconShowLess,
    IconArrowDownLangSwitcher,
    IconArrowLeftCarousel,
    IconArrowRightCarousel,
    IconArrowUpLangSwitcher,
    IconBookmarkFilled,
    IconBookmarkToggle,
    IconCameraJoin,
    IconCoupon,
    IconEmail,
    IconFacebook,
    IconFacebookOriginal,
    IconHallplan,
    IconInstagram,
    IconInstagramOriginal,
    IconLink,
    IconLinkedIn,
    IconLinkedInOriginal,
    IconLiveStream,
    IconLocation,
    IconLock,
    IconMeetingSchedule,
    IconMobile,
    IconPhone,
    IconPinterest,
    IconPinterestOrginal,
    IconShare,
    IconThreeDotsMenu,
    IconTicket,
    IconTwitter,
    IconVideo,
    IconXing,
    IconXingOriginal,
    IconYouTube,
    IconYouTubeOriginal,
    IconBusinessAreaTemplate,
    IconCalendar,
    IconPersonBehindPerson,
    IconFacebook3,
    IconTwitter3,
    IconLinkedIn3,
    IconXing3,
    IconInstagram3,
    IconYouTube3,
    IconPinterest3,
    IconContact,
    IconInfo,
    IconCopyLight,
    IconDownload,
    IconTikTokOriginal,
    IconTikTok
} from "../../../ui/Icons"
import LiveEvent from "../../../ui/LiveEvent"
import { usePrivacyPolicyModal } from "../../../ui/modals/PrivacyPolicyModal"
import { useRecommendModal } from "../../../ui/modals/RecommendModal"
import ScrollBarComponent from "../../../ui/ScrollBar"
import { ContentScrollContainer } from "../../../ui/ScrollContainer"
import { Staff, StaffEntry, StaffType } from "../../../ui/StaffEntry"
import TileRow from "../../../ui/TileRow"
import useWindowDimensions from "../../../ui/WindowDimensionsHook"
import { compareWhichIsEarlier, isEventDateLive } from "../../../utils/DateUtils"
import { DesktopVersionContainer, device, MobileVersionContainer } from "../../../utils/Device"
import {
    isExplorationOrPostEventPhase,
    isExplorationPhase,
    isLivePhase,
    isPostEventPhase
} from "../../../utils/EventPhaseChecker"
import { buildHallplanLink } from "../../hallplan/HallplanNavLink"
import MeetingRoomGroup from "../../lounges/MeetingRoomGroup"
import { SocialMediaType } from "../../myprofile/MyProfilePageContentBranding"
import { ListEventDateEntryColumn } from "../../program/eventDateEntry/EventDateEntry"
import { MobileTileWrapperSpeakers } from "../../reception/ReceptionPageContent"
import VideoPlayerReal from "../../videoPlayer/VideoPlayerReal"
import { buildDetailLink, DetailNavLink } from "../DetailNavLink"
import { DetailSection, InfoContentOrderType } from "../DetailPageBranding"
import { JobOfferDetails } from "../JobOfferDetailPageContent"
import { hasAvailableStaff, joinShowroom, joinShowRoom2 } from "../OrganizationDetailPageContent"
import Categories from "./Categories"
import { DetailPageEntityRow, DetailPageEntityRows, EntityRowData } from "./DetailPageEntityRows"
import { filterMedia, Media, MediaSlider } from "./Media"
import ProductDetailInfo from "./ProductDetailInfo"
import { FormattedDateExtensionDiv, FormattedDatePar, HeaderTitle } from "./StyledComponents"
import CompanyDetails from "../../../ui/CompanyDetails"
import { CustomOverlayTrigger } from "../../../ui/CustomOverlayTrigger"
import { PersonsLegacy } from "../eventDateDetail/EventDateDetailPageContent"
import { trackVisit, UserOrganizationVisitSource } from "../../../backendServices/TrackingServices"
import { getProperUrl } from "../personDetail/PersonDetailPageContent"
import { hallplanOverviewRoute } from "../../../navigationArea/RoutePaths"
import InView from "react-intersection-observer"
import { detect } from "detect-browser"
import { getShareTargetType } from "../../entitiesActions/actionsUtils"
import { formatNumberStringGroupsOfThree } from "../../../utils/StringUtils"
import { ButtonColored } from "../../../ui/Buttons"
import { useDetailSectionModal } from "../../../ui/modals/DetailSectionModal"
import { useMeetingInvitation } from "../../../conferenceV2/context/MeetingInvitation"
import { usePresenceState } from "../../../ui/PresenceIndicator"
import { useStaffMembersModal } from "../../../ui/modals/NoStaffMembersModal"
import {
    EventDetailInfo,
    getEventCategories,
    getEventPersons,
    getEventPersonsFunctionGroups,
    getFormattedDate
} from "../eventDetail/EventDetailPageContent"
import { saveEvents } from "../../../communicationArea/ICal"
import CalendarEntryModal2 from "../../../ui/modals/calendarEntry/CalendarEntryModal2"
import { Dictionary } from "lodash"
import { PointsBadge } from "../../program/eventDateEntry/EventDateEntry.styled"
import { trackSelectContent, trackTakeAction } from "../../../utils/GTMTracking"

// export const getNewsDateFormatted = (date: string, lang: string) => {
//     if (lang === "de") return moment(date).format("DD.MM.YYYY")
//     return moment(date).format("DD MMM yyyy")
// }

export const getNewsDateFormatted = (date: string, lang: string, dateFormat?: string) => {
    if (date)
        return format(moment(date).toDate(), dateFormat ?? branding.eventTiming.eventDaysFormatPatternNewsPage, {
            locale: lang === "de" ? de : en
        })
}

const allowedNumberOfItemsMap = {
    [BasisPremiumType.NONE]: {
        products: branding.organizationDetailPageContent.numberOfProductsNone,
        trademarks: branding.organizationDetailPageContent.numberOfTrademarksNone,
        news: branding.organizationDetailPageContent.numberOfNewsNone
    },
    [BasisPremiumType.BASIC]: {
        products: branding.organizationDetailPageContent.numberOfProductsBasic,
        trademarks: branding.organizationDetailPageContent.numberOfTrademarksBasic,
        news: branding.organizationDetailPageContent.numberOfNewsBasic
    },
    [BasisPremiumType.STANDARD]: {
        products: branding.organizationDetailPageContent.numberOfProductsStandard,
        trademarks: branding.organizationDetailPageContent.numberOfTrademarksStandard,
        news: branding.organizationDetailPageContent.numberOfNewsStandard
    },
    [BasisPremiumType.PREMIUM]: {
        products: branding.organizationDetailPageContent.numberOfProductsPremium,
        trademarks: branding.organizationDetailPageContent.numberOfTrademarksPremium,
        news: branding.organizationDetailPageContent.numberOfNewsPremium
    },
    [BasisPremiumType.ULTIMATE]: {
        products: branding.organizationDetailPageContent.numberOfProductsUltimate,
        trademarks: branding.organizationDetailPageContent.numberOfTrademarksUltimate,
        news: branding.organizationDetailPageContent.numberOfNewsUltimate
    },
    [BasisPremiumType.SPONSOR]: {
        products: -1,
        trademarks: -1,
        news: -1
    }
}

interface OrgaEntities {
    products: Product[]
    trademarks: Trademark[]
    news: News[]
}

const filterEntities = (organization: Company): OrgaEntities => {
    const allowedNumberOfItemsMapObject = allowedNumberOfItemsMap[organization.basisPremium as BasisPremiumType]
    const allowedNumProducts = allowedNumberOfItemsMapObject?.products
    const allowedNumTrademarks = allowedNumberOfItemsMapObject?.trademarks
    const allowedNumNews = allowedNumberOfItemsMapObject?.news
    let productsFiltered = organization.products
    let trademarksFiltered = organization.trademarks
    let newsFiltered = organization.news

    if (allowedNumProducts && allowedNumProducts > -1) productsFiltered = productsFiltered?.slice(0, allowedNumProducts)
    if (allowedNumTrademarks && allowedNumTrademarks > -1) trademarksFiltered = trademarksFiltered?.slice(0, allowedNumTrademarks)
    if (allowedNumNews && allowedNumNews > -1) newsFiltered = newsFiltered?.slice(0, allowedNumNews)

    return {
        products: productsFiltered,
        trademarks: trademarksFiltered,
        news: newsFiltered
    }
}
interface OrgaTransformedEntities {
    products: EntityRowData[]
    trademarks: EntityRowData[]
    news: EntityRowData[]
}

const mapEntities = (entities: OrgaEntities): OrgaTransformedEntities => {
    const products = entities.products?.map((product) => product as EntityRowData)
    const trademarks = entities.trademarks?.map((trademark) => trademark as EntityRowData)
    const news = entities.news?.map((news) => {
        const entityRow = news as EntityRowData
        entityRow.name = news.title
        entityRow.description = { teaser: news.teaser }
        return entityRow
    })

    return {
        products: products,
        trademarks: trademarks,
        news: news
    }
}

const transformOrgaSubEntities = (organization: Company): OrgaTransformedEntities => {
    const filteredEntites = filterEntities(organization)
    return mapEntities(filteredEntites)
}

const ContentWrapper = styled.div<{ isScrollable: boolean }>`
    background-color: ${branding.contentBgColorForEachPage ?? "#fff"} !important;
    & .ScrollbarsCustom-Scroller {
        margin-right: ${(props) => (props.isScrollable ? "-21px !important" : "unset !important")};
        padding-right: ${(props) => (props.isScrollable ? "20px" : "unset")};
    }

    & .ScrollbarsCustom-Content {
        background: ${branding.myProfilePageContent.mobilePageBackgroundColor};
        padding-bottom: 100px;
    }

    @media ${device.tablet} {
        & .ScrollbarsCustom-Content {
            background-color: ${branding.contentBgColorForEachPage ?? "#fff"};
            padding-bottom: 100px;
        }
    }

    @media ${device.laptop} {
        & .ScrollbarsCustom-Scroller {
            margin-right: -30px !important;
            padding-bottom: 100px;
        }
    }
`

const OrganizationDetailPageContentRoot = styled.div`
    padding: 0px;
    font-family: ${branding.font1};

    @media (max-width: 1400px) {
        padding: 0px 100px;
    }

    @media ${device.mobile} {
        padding: 10px;
        padding-bottom: 60px;
        background: ${branding.myProfilePageContent.mobilePageBackgroundColor};

        @supports (-webkit-touch-callout: none) {
            padding-bottom: 250px;
        }
    }

    @media ${device.tablet} {
        background-color: ${branding.contentBgColorForEachPage ?? "#fff"} !important;
    }
`

const MobileSectionWrapper = styled.div<{ newDesign?: boolean }>`
    width: 100%;
    background-color: ${branding.contentBgColorForEachPage ?? "#fff"} !important;
    margin-bottom: ${(props) => (props.newDesign ? "" : "10px")};
    border-radius: 5px;
`

const LogoDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    width: 150px;
    height: 150px;
    padding: 20px;
    background: #fff;

    @media (max-width: 1600px) {
        width: 120px;
        height: 120px;
        padding: 25px;
    }

    @media (max-width: 1400px) {
        width: 100px;
        height: 100px;
        padding: 15px;
    }

    @media (max-width: 1200px) {
        width: 80px;
        height: 80px;
        padding: 10px;
    }
`

const LogoDivCompany = styled(LogoDiv)`
    margin-bottom: 20px;
`

const CenterContentContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const HeaderTitleDetailInfoOrga = styled(HeaderTitle)`
    @media (max-width: 1600px) {
        font-size: 18px;
        padding: 5px 0px;
    }
`

const CompanyLogo = styled.img`
    object-fit: contain;
    width: 100%;
    height: 100%;
`

const RowTileParent = styled.div`
    & .title-no-link {
        font-size: 18px;
    }
`

const RemoteLivestreamTile = styled.div<{ customDimensions?: { width: string; height: string } }>`
    position: relative;
    width: ${(props) => (props.customDimensions ? props.customDimensions.width : "30vw")}; // width * 0.5625 - aspect ratio 16:9
    height: ${(props) => (props.customDimensions ? props.customDimensions.height : "16.875vw")};
    color: white !important;
    margin: 0px 10px 0px 0px;
    border: 1px solid #000;
    border-radius: 5px;
`

export const MediaRow = styled(Row)<{ newDesign?: boolean }>`
    ${(props) => (props.newDesign ? "" : "padding-bottom: 3rem !important;")}
    ${(props) => (props.newDesign ? "margin-left: 0 !important;" : "")}

    @media ${device.tablet} {
        ${(props) => (props.newDesign ? "" : "margin-top: 3rem !important;")}
        ${(props) => (props.newDesign ? "" : "padding-bottom: 3rem !important;")}
        &.product {
            margin-top: 50px !important;
        }
    }
`

interface DetailPageSectionsProps {
    type: EntityType
    guestUserBannerRef?: React.RefObject<HTMLDivElement>
    handleScroll?(value: number): void
    onAccessDenied?: () => void

    entity: Company | Product | Trademark | News | JobOffer | Event
    adjustHeightWith?: number
    initialScrollTop?: any

    newDesign: boolean
}

export const TeamSessionTemplateRow = styled(Row)<{ newDesign?: boolean }>`
    @media ${device.tablet} {
        ${(props) => (props.newDesign ? "margin-top: 50px" : "margin-top: 3rem;")}
        ${(props) => (props.newDesign ? "" : "margin-bottom: 3rem;")}
    }
`

export const LinksRow = styled(Row)`
    margin-left: -15px;
    margin-right: -15px;

    @media ${device.mobile} {
        margin-left: 5px;
        margin-right: 5px;
    }

    @media ${device.tablet} {
        padding: 0px 12px 12px;
    }
`

const CompanyWrapper = styled.div`
    padding: 10px 0;
`

const DetailPageSections: React.FunctionComponent<DetailPageSectionsProps> = React.memo((props) => {
    const langState = useLanguageState()
    const lang = langState.getLanguage()
    const appState = useAppState()
    const history = useHistory()
    const { RecommendModal, showRecommendModal } = useRecommendModal()

    const [isContentScrollable, setIsContentScrollable] = useState(false)

    //Main content container reference
    const containerRef = useRef<HTMLDivElement>(null)
    const [containerOffsetLeft, setContainerOffsetLeft] = useState<number | undefined>()
    const isNetworkingOpen = appState.isNetworkingOpen()

    useEffect(() => {
        setContainerOffsetLeft(containerRef.current?.offsetLeft)
    }, [window.innerWidth, isNetworkingOpen]) // eslint-disable-line

    const userAccessState = useUserRestrictedAreaAccess()

    let sectionOrder: DetailSection[] = []
    let infoContentOrder: InfoContentOrderType[] = []
    let organizationId: string | undefined
    let organization: Company | undefined
    let product: Product | undefined
    let trademark: Trademark | undefined
    let news: News | undefined
    let joboffer: JobOffer | undefined
    let event: Event | undefined

    let organizations: Company[] = []
    let joboffers: JobOffer[] = []
    let persons: Person[] = []
    let stands: Stand[] = []
    let products: EntityRowData[] = []
    let trademarks: EntityRowData[] = []
    let orgaNews: EntityRowData[] = []
    let liveChannels: Channel[] = []
    let liveSessions: EventDate[] = [] // for live eventdates without channel (e.g. roundtable)
    let links: Link[] = []
    let attachments: Attachment[] = []
    let previousSessions: EventDate[] = []
    let upcomingSessions: EventDate[] = []
    let iframe: string
    let config: any
    let categories: Category[] = []
    let showCategoryHierarchy: boolean = false
    let source: UserOrganizationVisitSource | undefined

    const { isMobile } = useWindowDimensions()

    const now = moment()

    useEffect(() => {
        userAccessState.fetchUserAccessForAllRestrictedAreaTypes()
        // eslint-disable-next-line
    }, [])

    switch (props.type) {
        case "organization":
            organization = props.entity as Company
            config = getBasisPremiumConfig(organization?.basisPremium! as BasisPremiumType)
            const orgaEntities = transformOrgaSubEntities(organization)
            products = orgaEntities.products
            trademarks = orgaEntities.trademarks
            orgaNews = orgaEntities.news
            iframe = lang === "en" ? organization.iframeUrl : organization.iframeUrlDe
            sectionOrder = branding.organizationDetailPageContent.orgaDetailPageMainContentOrder
            infoContentOrder = branding.organizationDetailPageContent.orgaDetailPageInfoSectionOrder
            organizationId = props.entity.id
            categories = organization.categories
            showCategoryHierarchy = branding.organizationDetailPageContent.showCategoryHierarchy
            joboffers = organization.joboffers
            stands = organization.stands
            links = organization.links
            attachments = organization.attachments
            if (organization.eventDates) {
                organization.eventDates.forEach((eventDate) => {
                    if (isEventDateLive(eventDate)) {
                        if (eventDate.channel) {
                            const channel: Channel = { ...eventDate.channel, currentEventDate: eventDate }
                            liveChannels.push(channel)
                        } else liveSessions.push(eventDate)
                    } else if (moment(eventDate.date + " " + eventDate.start).isAfter(now)) {
                        upcomingSessions.push(eventDate)
                    } else if (moment(eventDate.date + " " + eventDate.end).isBefore(now)) {
                        previousSessions.push(eventDate)
                    }
                })
            }
            previousSessions.sort((a: EventDate, b: EventDate) => {
                return compareWhichIsEarlier(a, b)
            })
            source = "ORGANIZATION"
            break
        case "product":
            product = props.entity as Product
            organization = product.organizations[0] || null
            organizations = product.organizations
            sectionOrder = branding.productDetailPageContent.sectionOrder
            infoContentOrder = branding.productDetailPageContent.infoContentOrder
            categories = product.categories
            stands = product.stands
            showCategoryHierarchy = branding.productDetailPageContent.showCategoryHierarchy
            links = product.links
            attachments = product.attachments
            if ((props.entity as Product).organizations?.length) {
                organization = (props.entity as Product).organizations[0]
                organizationId = (props.entity as Product).organizations[0].id
            }
            break
        case "trademark":
            trademark = props.entity as Trademark
            organizations = trademark.organizations
            sectionOrder = branding.trademarkDetailPageContent.sectionOrder
            infoContentOrder = branding.trademarkDetailPageContent.infoContentOrder
            categories = trademark.categories
            showCategoryHierarchy = branding.trademarkDetailPageContent.showCategoryHierarchy
            links = trademark.links
            attachments = trademark.attachments
            if ((props.entity as Trademark).organizations?.length) {
                organization = (props.entity as Trademark).organizations[0]
                organizationId = (props.entity as Trademark).organizations[0].id
            }
            break
        case "news":
            news = props.entity as News
            news.pictureURL = news.logoUrl
            organization = news.organization
            organizations = [news.organization]
            sectionOrder = branding.newsDetailPageContent.sections
            infoContentOrder = branding.newsDetailPageContent.infoContentOrder
            showCategoryHierarchy = branding.newsDetailPageContent.showCategoryHierarchy
            categories = news.categories
            persons = news.persons
            links = news.links
            attachments = news.attachments
            if (news.organization) organizationId = news.organization.id
            source = "NEWS"
            break
        case "joboffer":
            joboffer = props.entity as JobOffer
            persons = joboffer.persons
            organization = joboffer.organization
            sectionOrder = branding.jobofferDetailPageContent.sections
            infoContentOrder = branding.jobofferDetailPageContent.infoContentOrder
            organizationId = joboffer.organization.id
            categories = joboffer.categories
            links = joboffer.links
            attachments = joboffer.attachments
            showCategoryHierarchy = branding.jobofferDetailPageContent.showCategoryHierarchy
            source = "JOBOFFER"
            break
        case "event":
            event = props.entity as Event
            persons = getEventPersons(event.eventdates || [])
            sectionOrder = branding.eventDetailPageContent.sections
            infoContentOrder = branding.jobofferDetailPageContent.infoContentOrder
            categories = getEventCategories(event.eventdates || [])
            showCategoryHierarchy = branding.eventDetailPageContent.showCategoryHierarchy
            source = "EVENT"
            if (event.eventdates) {
                event.eventdates?.forEach((eventDate) => {
                    if (isEventDateLive(eventDate)) {
                        liveSessions.push(eventDate)
                    } else if (moment(eventDate.date + " " + eventDate.start).isAfter(now)) {
                        upcomingSessions.push(eventDate)
                    } else if (moment(eventDate.date + " " + eventDate.end).isBefore(now)) {
                        previousSessions.push(eventDate)
                    }
                })
            }
            previousSessions.sort((a: EventDate, b: EventDate) => {
                return compareWhichIsEarlier(a, b)
            })
            break
        default:
            return null
    }

    return (
        <ContentWrapper isScrollable={isContentScrollable}>
            <ContentScrollContainer
                setIsScrollable={setIsContentScrollable}
                handleScroll={props.handleScroll}
                initialScrollTop={props.initialScrollTop}
                adjustForHeaderWith={
                    isMobile
                        ? "0px"
                        : props.guestUserBannerRef && props.guestUserBannerRef.current
                        ? props.guestUserBannerRef.current.clientHeight + (props.adjustHeightWith ?? 0) + "px"
                        : (props.adjustHeightWith ?? 0) + "px"
                }
            >
                <OrganizationDetailPageContentRoot>
                    <MobileVersionContainer>
                        {props.type === "organization" && <MobileCompanyHeader company={organization} />}
                        {(props.type === "product" || props.type === "trademark" || props.type === "joboffer") && (
                            <MobileProductHeader entity={props.entity as Product | Trademark | JobOffer} type={props.type} />
                        )}
                    </MobileVersionContainer>

                    <ConditionalWrapper
                        wrapper={(children) =>
                            isMobile ? (
                                <div>{children}</div>
                            ) : (
                                <Container
                                    ref={containerRef}
                                    className="mt-5"
                                    style={{ color: branding.mainInfoColor, width: "100%" }}
                                >
                                    {children}
                                </Container>
                            )
                        }
                    >
                        {sectionOrder.map((item, index) => {
                            /* Logo / Picture and Detail Information */
                            if (item === DetailSection.DETAIL_INFO) {
                                return (
                                    <>
                                        {props.type === "product" ? (
                                            <DesktopVersionContainer>
                                                <ProductDetailInfo key={index} product={product} />
                                            </DesktopVersionContainer>
                                        ) : (
                                            <DetailInfo
                                                key={index}
                                                order={infoContentOrder}
                                                company={organization}
                                                joboffer={joboffer}
                                                event={event}
                                                containerOffsetLeft={containerOffsetLeft}
                                                newsDate={props.type === "news" ? news?.date : undefined}
                                                persons={persons}
                                                showCategoryHierarchy={showCategoryHierarchy}
                                                {...props.entity}
                                                type={props.type}
                                                showRecommendModal={showRecommendModal}
                                            />
                                        )}
                                    </>
                                )
                            }

                            /* Company team */
                            if (item === DetailSection.TEAM && organization?.persons?.length) {
                                return (
                                    config.teamMembersVisible && (
                                        <MobileSectionWrapper newDesign={props.newDesign && !isMobile}>
                                            <TeamSessionTemplateRow key={index} newDesign={props.newDesign && !isMobile}>
                                                <TeamSessionsTemplate
                                                    type="team"
                                                    team={organization.persons}
                                                    organizationId={organization.id}
                                                    organizationName={organization.name}
                                                    organization={organization}
                                                    onAccessDenied={props.onAccessDenied}
                                                    containerOffsetLeft={containerOffsetLeft}
                                                    newDesign={props.newDesign && !isMobile}
                                                />
                                            </TeamSessionTemplateRow>
                                        </MobileSectionWrapper>
                                    )
                                )
                            }

                            if (item === DetailSection.PERSONS && persons?.length && joboffer && props.type === "joboffer") {
                                return (
                                    <MobileSectionWrapper>
                                        <TeamSessionTemplateRow key={index}>
                                            <TeamSessionsTemplate
                                                type="team"
                                                team={persons}
                                                organizationId={joboffer.organization.id}
                                                organizationName={branding.jobofferDetailPageContent.personListTitle}
                                                organization={joboffer.organization}
                                                onAccessDenied={props.onAccessDenied}
                                                containerOffsetLeft={containerOffsetLeft}
                                            />
                                        </TeamSessionTemplateRow>
                                    </MobileSectionWrapper>
                                )
                            }

                            if (item === DetailSection.PERSONS && persons.length > 0 && event && props.type === "event") {
                                const groups: Dictionary<Person[]> = getEventPersonsFunctionGroups(persons)

                                return (
                                    <>
                                        {isMobile ? (
                                            <>
                                                {Object.getOwnPropertyNames(groups).map(function (key) {
                                                    const currentGroup: Person[] = groups[key]
                                                    return (
                                                        <MobileSectionWrapper>
                                                            <TeamSessionTemplateRow key={index}>
                                                                <TeamSessionsTemplate
                                                                    type="team"
                                                                    team={currentGroup}
                                                                    organizationName={key}
                                                                    onAccessDenied={props.onAccessDenied}
                                                                    containerOffsetLeft={containerOffsetLeft}
                                                                    newDesign={props.newDesign && !isMobile}
                                                                />
                                                            </TeamSessionTemplateRow>
                                                        </MobileSectionWrapper>
                                                    )
                                                })}
                                            </>
                                        ) : (
                                            <TeamSessionsTemplate
                                                type="team"
                                                team={persons}
                                                organizationName={branding.jobofferDetailPageContent.personListTitle}
                                                onAccessDenied={props.onAccessDenied}
                                                containerOffsetLeft={containerOffsetLeft}
                                                newDesign={props.newDesign && !isMobile}
                                                group
                                            />
                                        )}
                                    </>
                                )
                            }

                            if (item === DetailSection.HALLE_STAND_INFO && props.type === "product" && stands && stands.length) {
                                return (
                                    <DesktopVersionContainer key={index}>
                                        <HalleStandSection hasSocials={false} className="product">
                                            <HeaderTitle className="mt-0" style={{ marginBottom: "15px" }}>
                                                {branding.productDetailPageContent.halleStandSectionTitle}
                                            </HeaderTitle>
                                            {stands.map((stand, idx) => {
                                                return (
                                                    <HalleStandSingle
                                                        onClick={() => {
                                                            if (!branding.organizationDetailPageContent.halleStandLinkEnabled)
                                                                return

                                                            history.push(
                                                                hallplanOverviewRoute +
                                                                    "/" +
                                                                    stand.id.split("__")[0] +
                                                                    "--" +
                                                                    stand.id
                                                            )

                                                            trackSelectContent(
                                                                stand.standName || stand.displayName,
                                                                "Details",
                                                                "Stand"
                                                            )
                                                        }}
                                                        key={idx}
                                                    >
                                                        {stand.standName || stand.displayName}
                                                    </HalleStandSingle>
                                                )
                                            })}
                                        </HalleStandSection>
                                    </DesktopVersionContainer>
                                )
                            }

                            /* Now live section */
                            if (
                                item === DetailSection.LIVE_SESSIONS &&
                                (liveChannels.length || liveSessions.length) &&
                                organization
                            ) {
                                return (
                                    <NowLiveSection
                                        company={organization}
                                        index={index}
                                        containerOffsetLeft={containerOffsetLeft}
                                        liveChannels={liveChannels}
                                        liveSessions={liveSessions}
                                        source={source}
                                    ></NowLiveSection>
                                )
                            }

                            if (item === DetailSection.LIVE_SESSIONS && liveSessions.length && props.type === "event") {
                                return (
                                    <SessionsSection
                                        entries={liveSessions}
                                        sectionTitle={`${branding.organizationDetailPageContent.liveSessionsTitle} (${liveSessions.length})`}
                                    />
                                )
                            }

                            /* Upcoming sessions */
                            if (item === DetailSection.UPCOMING_SESSIONS && upcomingSessions?.length) {
                                if (props.type === "organization" || props.type === "event")
                                    return (
                                        <SessionsSection
                                            entries={upcomingSessions}
                                            sectionTitle={`${branding.organizationDetailPageContent.upcomingSessionsTitle} (${upcomingSessions.length})`}
                                        />
                                    )
                                else
                                    return (
                                        <DesktopVersionContainer key={index}>
                                            {liveChannels && liveChannels.length > 0 && (
                                                <RowTileParent>
                                                    <TileRow
                                                        title={branding.receptionPage.liveSessions}
                                                        titleNoLink={
                                                            branding.receptionPage.nowLive + " (" + liveChannels.length + ")"
                                                        }
                                                        tileRowMargin="0"
                                                        offsetLeft={containerOffsetLeft}
                                                        scrollComponent
                                                        childWidth={480}
                                                        childCount={liveChannels.length}
                                                        hideShadowsAndScrollButtons={true}
                                                        customWidth="auto"
                                                    >
                                                        {liveChannels.map((channel) => (
                                                            <InView key={channel.id} threshold={0.1} initialInView>
                                                                {({ inView }) =>
                                                                    inView ? (
                                                                        <LiveEvent
                                                                            id={index.toString()}
                                                                            title={channel.name}
                                                                            thumbnailUrl={channel.thumbnailUrl}
                                                                            channelId={channel.id}
                                                                            channelIcon={IconLiveStream({ fill: "#fff" })}
                                                                            eventDate={channel.currentEventDate}
                                                                            rerenderOnUnlockedChanged={userAccessState.isUnlocked(
                                                                                channel.currentEventDate
                                                                            )}
                                                                            src={source || "UNKNOWN"}
                                                                        />
                                                                    ) : (
                                                                        <div style={{ width: 480, height: 280 }}></div>
                                                                    )
                                                                }
                                                            </InView>
                                                        ))}
                                                    </TileRow>
                                                </RowTileParent>
                                            )}
                                            {liveSessions && liveSessions.length > 0 && (
                                                <Row key={index} className="mt-5 pb-5">
                                                    <TeamSessionsTemplate
                                                        type="session"
                                                        sessions={upcomingSessions}
                                                        organizationId={organizationId!}
                                                        organization={organization!}
                                                        past={false}
                                                        src={source}
                                                    />
                                                </Row>
                                            )}
                                        </DesktopVersionContainer>
                                    )
                            }

                            /* Previous sessions */
                            if (item === DetailSection.PREVIOUS_SESSIONS && previousSessions?.length) {
                                if (props.type === "organization" || props.type === "event")
                                    return (
                                        <SessionsSection
                                            entries={previousSessions}
                                            sectionTitle={`${branding.organizationDetailPageContent.pastSessionsTitle} (${previousSessions.length})`}
                                        />
                                    )
                                else
                                    return (
                                        <DesktopVersionContainer key={index}>
                                            <Row key={index} className="mt-5 pb-5">
                                                <TeamSessionsTemplate
                                                    type="session"
                                                    sessions={previousSessions}
                                                    organizationId={organizationId!}
                                                    organization={organization!}
                                                    past={true}
                                                    src={source}
                                                />
                                            </Row>
                                        </DesktopVersionContainer>
                                    )
                            }

                            /* Showroom */
                            if (
                                props.type === "organization" &&
                                item === DetailSection.CONFERENCE_ROOMS &&
                                organization?.conferenceRooms &&
                                organization?.conferenceRooms.length > 0
                            ) {
                                return (
                                    <ShowRoomSection
                                        company={organization}
                                        index={index}
                                        containerOffsetLeft={containerOffsetLeft}
                                    />
                                )
                            }

                            /* Categories */
                            if (
                                item === DetailSection.CATEGORIES &&
                                categories &&
                                categories.length &&
                                filterCategories(categories || []).length > 0
                            ) {
                                return (
                                    <MobileSectionWrapper newDesign={props.newDesign && !isMobile}>
                                        <Categories
                                            key={index}
                                            categories={filterCategories(categories)}
                                            categoryType={props.type}
                                            showLargeHeading={props.type === "organization"}
                                            showCategoryHierarchy={showCategoryHierarchy}
                                        />
                                    </MobileSectionWrapper>
                                )
                            }

                            /* Virtual Cafes */
                            if (item === DetailSection.VIRTUAL_CAFES && organization) {
                                return (
                                    <VirtualCafesSection
                                        company={organization}
                                        index={index}
                                        containerOffsetLeft={containerOffsetLeft}
                                    ></VirtualCafesSection>
                                )
                            }

                            /* Company Links */
                            if (item === DetailSection.LINKS && links.length > 0) {
                                return (
                                    <MobileSectionWrapper newDesign={props.newDesign && !isMobile}>
                                        <LinksRow key={index}>
                                            <Links
                                                organizationId={organizationId}
                                                organization={organization}
                                                links={links}
                                                tooltipMessage={branding.organizationDetailPageContent.copyToClipboardText}
                                                newDesign={props.newDesign && !isMobile}
                                            />
                                        </LinksRow>
                                    </MobileSectionWrapper>
                                )
                            }

                            /* Media */
                            if (item === DetailSection.MEDIA && attachments.length > 0) {
                                return (
                                    <MobileSectionWrapper newDesign={props.newDesign && !isMobile}>
                                        <MediaRow
                                            key={index}
                                            className={
                                                props.type === "product"
                                                    ? "product"
                                                    : props.type === "organization" && !isMobile
                                                    ? ""
                                                    : "pb-5"
                                            }
                                            newDesign={props.newDesign && !isMobile}
                                        >
                                            <Media
                                                media={attachments}
                                                organizationId={organizationId}
                                                organization={organization!}
                                                entityType={props.type}
                                                entityName={props.entity.name}
                                                newDesign={props.newDesign && !isMobile}
                                            />
                                        </MediaRow>
                                    </MobileSectionWrapper>
                                )
                            }

                            /* Contact information */
                            if (item === DetailSection.COMPANY_INFORMATION_TITLE) {
                                return (
                                    <MobileSectionWrapper newDesign={props.newDesign && !isMobile}>
                                        <Row key={index} className="mt-5 pb-1" style={{ marginLeft: "5px", marginRight: "5px" }}>
                                            <HeaderTitle>
                                                {branding.organizationDetailPageContent.companyInformationTitle}
                                            </HeaderTitle>
                                        </Row>
                                    </MobileSectionWrapper>
                                )
                            }

                            /* Address, Contact Info, Founding Year, Size, Industry */
                            if (item === DetailSection.COMPANY_DETAILS && organization) {
                                if (isMobile)
                                    return (
                                        <MobileSectionWrapper key={index} className="mt-3 pb-5">
                                            <CompanyDetailInfoMobile company={organization} />
                                        </MobileSectionWrapper>
                                    )
                                else
                                    return (
                                        <MobileSectionWrapper key={index} className="mt-3" newDesign={props.newDesign}>
                                            <CompanyDetailInfoDesktop company={organization} />
                                        </MobileSectionWrapper>
                                    )
                            }

                            /* Parent exhibitor */
                            if (item === DetailSection.PARENT && organization && organization.parent) {
                                const rowData: EntityRowData = organization.parent
                                return (
                                    <DesktopVersionContainer key={index}>
                                        <div className="mt-5 p-0 mb-4">
                                            <HeaderTitle>{branding.organizationDetailPageContent.parentSectionTitle}</HeaderTitle>
                                        </div>
                                        <DetailPageEntityRow
                                            type={"organization"}
                                            pageType={props.type}
                                            rowData={rowData}
                                            key={rowData.id}
                                        />
                                    </DesktopVersionContainer>
                                )
                            }

                            /* Co-Exhibitors */
                            if (
                                item === DetailSection.CO_EXHIBITORS &&
                                organization &&
                                organization.children &&
                                organization.children.length > 0
                            ) {
                                return (
                                    <DesktopVersionContainer key={index}>
                                        <DetailPageEntityRows
                                            key={index}
                                            type="organization"
                                            pageType={props.type}
                                            headerTitle={branding.organizationDetailPageContent.coExhibitorsSectionTitle}
                                            rowsData={organization.children}
                                        />
                                    </DesktopVersionContainer>
                                )
                            }

                            if (item === DetailSection.COUPONS && organization?.coupons?.length) {
                                return (
                                    <DesktopVersionContainer key={index}>
                                        {
                                            <Row key={index} className={props.type === "organization" ? "mt-5" : "mt-5 pb-5"}>
                                                <TeamSessionsTemplate
                                                    type="coupon"
                                                    coupons={organization.coupons}
                                                    organizationId={organization.id}
                                                    organization={organization}
                                                    newDesign={props.newDesign && !isMobile}
                                                />
                                            </Row>
                                        }
                                    </DesktopVersionContainer>
                                )
                            }

                            if (
                                item === DetailSection.PRODUCTS &&
                                products?.length &&
                                (branding.configuration.detailSectionToggle === false || organization?.showProducts)
                            ) {
                                return (
                                    <MobileSectionWrapper newDesign={props.newDesign && !isMobile}>
                                        <DetailPageEntityRows
                                            key={index}
                                            type="product"
                                            pageType={props.type}
                                            headerTitle={branding.sideIconBar.productsSearchDrawerText}
                                            rowsData={products}
                                            showRecommendModal={showRecommendModal}
                                        />
                                    </MobileSectionWrapper>
                                )
                            }

                            if (
                                item === DetailSection.TRADEMARKS &&
                                trademarks?.length &&
                                (branding.configuration.detailSectionToggle === false || organization?.showTrademarks)
                            ) {
                                return (
                                    <MobileSectionWrapper newDesign={props.newDesign && !isMobile}>
                                        <DetailPageEntityRows
                                            key={index}
                                            type="trademark"
                                            pageType={props.type}
                                            headerTitle={branding.sideIconBar.trademarksSearchDrawerText}
                                            rowsData={trademarks}
                                            showRecommendModal={showRecommendModal}
                                        />
                                    </MobileSectionWrapper>
                                )
                            }

                            if (item === DetailSection.NEWS && orgaNews && orgaNews.length > 0) {
                                return (
                                    <MobileSectionWrapper newDesign={props.newDesign && !isMobile}>
                                        <DetailPageEntityRows
                                            key={index}
                                            type="news"
                                            pageType={props.type}
                                            headerTitle={branding.sideIconBar.newsSearchDrawerText}
                                            rowsData={orgaNews}
                                            showRecommendModal={showRecommendModal}
                                        />
                                    </MobileSectionWrapper>
                                )
                            }

                            if (item === DetailSection.ORGANIZATIONS && organizations?.length) {
                                return (
                                    <MobileSectionWrapper newDesign={props.newDesign && !isMobile}>
                                        <DetailPageEntityRows
                                            key={index}
                                            type="organization"
                                            pageType={props.type}
                                            headerTitle={branding.sideIconBar.companiesSearchDrawerText}
                                            rowsData={organizations}
                                        />
                                    </MobileSectionWrapper>
                                )
                            }

                            if (item === DetailSection.SHOWCASE && iframe) {
                                return (
                                    <DesktopVersionContainer key={index}>
                                        <IframeSection src={iframe} title={organization?.id + "-iframe"} />
                                    </DesktopVersionContainer>
                                )
                            }

                            if (
                                item === DetailSection.REMOTE_LIVESTREAMS &&
                                organization &&
                                organization.remoteLivestreams &&
                                organization.remoteLivestreams.length > 0
                            ) {
                                return (
                                    <RemoteShowRoomSection
                                        company={organization}
                                        index={index}
                                        containerOffsetLeft={containerOffsetLeft}
                                    ></RemoteShowRoomSection>
                                )
                            }

                            if (item === DetailSection.JOB_OFFERS && joboffers?.length) {
                                return (
                                    <MobileSectionWrapper newDesign={props.newDesign && !isMobile}>
                                        <DetailPageEntityRows
                                            key={index}
                                            type="joboffer"
                                            pageType={props.type}
                                            headerTitle={branding.suggestBoxContent.joboffersGroupTitle}
                                            rowsData={joboffers}
                                            showRecommendModal={showRecommendModal}
                                        />
                                    </MobileSectionWrapper>
                                )
                            }

                            if (item === DetailSection.COMPANY && organization) {
                                return (
                                    <MobileSectionWrapper newDesign={props.newDesign && !isMobile}>
                                        <CompanyWrapper className="company-wrapper">
                                            <CompanyDetails
                                                organization={organization}
                                                source={
                                                    props.type === "joboffer"
                                                        ? "JOBOFFER"
                                                        : props.type === "news"
                                                        ? "NEWS"
                                                        : props.type === "product"
                                                        ? "PRODUCT"
                                                        : props.type === "trademark"
                                                        ? "TRADEMARK"
                                                        : props.type === "eventdate"
                                                        ? "EVENTDATE"
                                                        : "UNKNOWN"
                                                }
                                            />
                                        </CompanyWrapper>
                                    </MobileSectionWrapper>
                                )
                            }
                            return null
                        })}
                    </ConditionalWrapper>
                </OrganizationDetailPageContentRoot>
            </ContentScrollContainer>
            <RecommendModal />
        </ContentWrapper>
    )
})

export default DetailPageSections

const componentDecorator = (href: string, text: string, key: number) => (
    <a
        style={{ fontWeight: "bold", color: branding.mainInfoColor }}
        href={href}
        key={key}
        target="_blank"
        rel="noopener noreferrer"
    >
        {text}
    </a>
)

/* #region Company address, phone, email info */
interface CompanyInfoProps {
    company: Company
    showYearSizeIndustry?: boolean
}
const CompanyAdress: React.FunctionComponent<CompanyInfoProps> = (props) => {
    return (
        <div style={{ display: "flex", flex: 1 }}>
            <DetailsAdditionalInfoDivOrgaAddress showYearSizeIndustry={props.showYearSizeIndustry}>
                <div style={{ paddingRight: "10px" }}>
                    {(props.company.name || props.company.adress1 || props.company.postCode || props.company.country) &&
                        IconLocation({ fill: branding.sideIconBar.sideIconColorDark, width: "18", height: "18" })}
                </div>
                <div>
                    <p>{props.company.name}</p>
                    {props.company.adress1 && <p>{props.company.adress1}</p>}
                    {props.company.adress2 && <p>{props.company.adress2}</p>}
                    {props.company.adress3 && <p>{props.company.adress3}</p>}
                    <p>
                        {props.company.postCode} {props.company.city}
                    </p>
                    <p>{props.company.country}</p>
                </div>
            </DetailsAdditionalInfoDivOrgaAddress>
        </div>
    )
}

const StyledRow = styled(Row)<{ showYearSizeIndustry?: boolean }>`
    ${(props) => (props.showYearSizeIndustry ? "" : "display: flex; justify-content: center;")}
`

const CompanyPhoneEmailWebsite: React.FunctionComponent<CompanyInfoProps> = (props) => {
    return (
        <div style={{ display: "flex", flex: 1 }}>
            <DetailsAdditionalInfoDivOrga>
                <Container fluid>
                    {props.company.phone && !branding.organizationDetailPageContent.hideTelephone && (
                        <StyledRow showYearSizeIndustry={props.showYearSizeIndustry}>
                            <Col sm={1}>
                                {IconPhone({
                                    fill: branding.sideIconBar.sideIconColorDark,
                                    width: "14",
                                    height: "14"
                                })}
                            </Col>
                            <Col sm={7}>
                                <span>{props.company.phone}</span>
                            </Col>
                        </StyledRow>
                    )}
                    {props.company.email && !branding.organizationDetailPageContent.hideEmailAddress && (
                        <StyledRow showYearSizeIndustry={props.showYearSizeIndustry}>
                            <Col sm={1}>
                                {IconEmail({
                                    fill: branding.sideIconBar.sideIconColorDark,
                                    width: "16",
                                    height: "16"
                                })}
                            </Col>
                            <Col sm={7}>
                                <Linkify componentDecorator={componentDecorator}>
                                    <div onClick={() => trackSelectContent(props.company.email, "Details", "Link")}>
                                        {props.company.email}
                                    </div>
                                </Linkify>
                            </Col>
                        </StyledRow>
                    )}
                    {props.company.web && (
                        <StyledRow showYearSizeIndustry={props.showYearSizeIndustry}>
                            <Col sm={1}>
                                {IconLink({
                                    fill: branding.sideIconBar.sideIconColorDark,
                                    width: "18",
                                    height: "18"
                                })}
                            </Col>
                            <Col sm={7}>
                                <div onClick={() => trackSelectContent(props.company.web, "Details", "Link")}>
                                    <Linkify componentDecorator={componentDecorator}>{props.company.web}</Linkify>
                                </div>
                            </Col>
                        </StyledRow>
                    )}
                </Container>
            </DetailsAdditionalInfoDivOrga>
        </div>
    )
}

const CompanyYearSizeIndustry: React.FunctionComponent<CompanyInfoProps> = (props) => {
    const columnWidthIcon = 1
    const columnWidthText = 10
    return (
        <CompanyAdditionalInfoRootDesktop>
            <DetailsAdditionalInfoDivOrga>
                <Container fluid>
                    {props.company.foundingYear && (
                        <Row>
                            <Col sm={columnWidthIcon}>
                                {IconCalendar({
                                    fill: branding.sideIconBar.sideIconColorDark,
                                    width: "17",
                                    height: "17"
                                })}
                            </Col>
                            <Col sm={columnWidthText}>
                                {branding.organizationDetailPageContent.foundingYearTitle}: {props.company.foundingYear}
                            </Col>
                        </Row>
                    )}
                    {props.company.size && (
                        <Row>
                            <Col sm={columnWidthIcon}>
                                {IconPersonBehindPerson({
                                    fill: branding.sideIconBar.sideIconColorDark,
                                    width: "18",
                                    height: "18"
                                })}
                            </Col>
                            <Col sm={columnWidthText}>
                                {branding.organizationDetailPageContent.companySizeTitle +
                                    ": " +
                                    formatNumberStringGroupsOfThree(props.company.size) +
                                    " " +
                                    branding.organizationDetailPageContent.employeesText}
                            </Col>
                        </Row>
                    )}
                    {props.company.industry && (
                        <Row>
                            <Col sm={columnWidthIcon}>
                                {IconBusinessAreaTemplate({
                                    fill: branding.sideIconBar.sideIconColorDark,
                                    width: "16",
                                    height: "16"
                                })}
                            </Col>
                            <Col sm={columnWidthText}>
                                {branding.organizationDetailPageContent.industryTitle}: {props.company.industry}
                            </Col>
                        </Row>
                    )}
                </Container>
            </DetailsAdditionalInfoDivOrga>
        </CompanyAdditionalInfoRootDesktop>
    )
}

const CompanyAddressPhoneEmail: React.FunctionComponent<CompanyInfoProps> = (props) => {
    const componentDecorator = (href: string, text: string, key: number) => (
        <a
            style={{ fontWeight: "bold", color: branding.mainInfoColor }}
            href={href}
            key={key}
            target="_blank"
            rel="noopener noreferrer"
        >
            {text}
        </a>
    )

    return (
        <div style={{ display: "flex", flex: 2 }}>
            <DetailsAdditionalInfoDiv style={{ flex: 1, display: "flex", flexDirection: "row", marginTop: "10px" }}>
                <div style={{ paddingRight: "10px" }}>
                    {(props.company.name || props.company.adress1 || props.company.postCode || props.company.country) &&
                        IconLocation({ fill: branding.sideIconBar.sideIconColorDark, width: "18", height: "18" })}
                </div>
                <div>
                    <p>{props.company.name}</p>
                    {props.company.adress1 && <p>{props.company.adress1}</p>}
                    {props.company.adress2 && <p>{props.company.adress2}</p>}
                    {props.company.adress3 && <p>{props.company.adress3}</p>}
                    <p>
                        {props.company.postCode} {props.company.city}
                    </p>
                    <p>{props.company.country}</p>
                </div>
            </DetailsAdditionalInfoDiv>

            <DetailsAdditionalInfoDiv style={{ flex: 1, display: "flex", flexDirection: "row", marginTop: "10px" }}>
                <div style={{ paddingRight: "10px" }}>
                    {(props.company.phone || props.company.email || props.company.web) &&
                        IconContact({ fill: branding.sideIconBar.sideIconColorDark, width: "18", height: "18" })}
                </div>
                <div>
                    {props.company.phone && !branding.organizationDetailPageContent.hideTelephone && <p>{props.company.phone}</p>}
                    {props.company.email && !branding.organizationDetailPageContent.hideEmailAddress && (
                        <p>
                            <Linkify componentDecorator={componentDecorator}>{props.company.email}</Linkify>
                        </p>
                    )}
                    {props.company.web && (
                        <p>
                            <Linkify componentDecorator={componentDecorator}>{props.company.web}</Linkify>
                        </p>
                    )}
                </div>
            </DetailsAdditionalInfoDiv>
        </div>
    )
}
/* #endregion */

/* #region  Company Detail Info */
const CompanyTeaser = styled.p`
    font-size: ${branding.organizationDetailPageContent.companyTeaserFontSize};
    font-weight: 400;
    white-space: pre-line;

    &.news {
        @media ${device.tablet} {
            width: 90%;
        }

        @media ${device.mobile} {
            font-size: 14px;
            line-height: 18px;
            font-weight: 400;
        }
    }

    &.event {
        font-size: 14px;
    }
`

const EventFormattedDate = styled.div`
    font-family: ${branding.font1};
    color: ${branding.sideIconBar.sideIconColorDark};
    font-size: 14px;
    width: auto;
    margin-bottom: 30px;
    line-height: 30px;
`

const NewsSubheadline = styled.p`
    font-size: 18px;
    font-weight: 600;
    white-space: pre-line;

    @media ${device.mobile} {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        font-style: italic;
    }
`

const NewsDate = styled.p`
    font-size: 12px;
    margin-bottom: 10px;

    @media ${device.tablet} {
        margin-bottom: 20px;
    }

    @media ${device.laptopL} {
        font-size: 14px;
    }

    @media ${device.desktop} {
        font-size: 16px;
        margin-bottom: 25px;
    }
`

const TalkWithTeamDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-top: 50px;
    border: 1px solid ${branding.mainInfoColor};
    border-radius: 5px;
    height: 100px;
    font-size: 16px;
    position: relative;

    @media (max-width: 1600px) {
        font-size: 14px;
    }
`

const JoinButtonCol = styled(Col)`
    background: ${branding.mainInfoColor};
    font-family: ${branding.font1};
    border-radius: 5px;
    height: 65%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    ${(props) => (props.disabled ? "opacity: 0.5; pointer-events: none;" : "")}

    :hover {
        color: #fff;
        background-color: ${branding.meetingRoomGroupsRequestAccessButtonBgColorOnHover};
        border-color: ${branding.meetingRoomGroupsRequestAccessButtonBgColorOnHover};
    }
`
const ExplorationPhaseMessage = styled.div`
    position: absolute;
    bottom: 0;
    left: 110px;
    color: rgb(189, 189, 189);
    font-family: ${branding.font1};
    @media (max-width: 1600px) {
        left: 80px;
        font-size: 14px;
    }

    @media (max-width: 1400px) {
        font-size: 12px;
        left: 50px;
    }
`

const CompanyInfoParagraph = styled.p<{ lang?: string }>`
    width: auto;
    min-width: ${(props) => (props.lang === "de" ? "135px" : "105px")};
    margin-right: 15px;
    font-weight: bold;
`

const HalleStandSection = styled.div<{ hasSocials?: boolean }>`
    padding: 25px 0;
    border-top: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    border-bottom: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    margin-top: ${(props) => (props.hasSocials === true ? "40px" : "0px")};

    &.product {
        border: none;
        padding-top: 50px;
        padding-left: 10px;
    }
`
const HalleStandSectionMobileView = styled.div`
    width: 400px;

    &.product {
        margin-top: 15px;

        p {
            font-size: 14px !important;
        }
    }
`

const HalleStandSingle = styled.div`
    display: inline-block;
    padding: 10px 30px;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 5px;
    text-align: center;
    background-color: ${branding.organizationDetailPageContent.halleStandItemBgColor};
    color: ${branding.organizationDetailPageContent.halleStandItemColor};
    cursor: ${branding.organizationDetailPageContent.halleStandLinkEnabled ? "pointer" : "auto"};
    pointer-events: ${branding.organizationDetailPageContent.halleStandLinkEnabled ? "auto" : "none"};
`
const HalleStandSingleMobileView = styled.div`
    display: inline-block;
    padding: 10px 30px;
    margin-right: 10px;
    margin-top: 20px;
    border-radius: 5px;
    text-align: center;
    background-color: ${branding.organizationDetailPageContent.halleStandItemBgColor};
    color: ${branding.organizationDetailPageContent.halleStandItemColor};
    cursor: ${branding.organizationDetailPageContent.halleStandLinkEnabled ? "pointer" : "auto"};
    pointer-events: ${branding.organizationDetailPageContent.halleStandLinkEnabled ? "auto" : "none"};
    width: 250px;

    &.product {
        margin-top: 0px;
    }
`

export function flattenCategories(categories: Category[], showCategoryHierarchy: boolean): Category[] {
    if (!showCategoryHierarchy) {
        return categories
    }

    const simpleCategories: Category[] = []

    categories.forEach((category: Category) => {
        if (category.nodes && category.nodes?.length > 0) {
            let categoriesTemp = flattenCategories(category.nodes || [], showCategoryHierarchy)

            categoriesTemp.forEach((categoryTemp: Category) => {
                simpleCategories.push(categoryTemp)
            })
        } else {
            simpleCategories.push(category)
        }
    })

    return simpleCategories
}

function checkIfAllSyntheticNodes(nodes: Category[]): boolean {
    if (nodes.length === 0) {
        return false
    }

    let nodesTemp = nodes?.filter((node) => !node.isSynthetic)

    return nodesTemp.length === 0
}

export function filterCategories(categories: Category[]): Category[] {
    let categoriesToFilter: Category[] = []

    categories.forEach((category: Category) => {
        categoriesToFilter.push(Object.assign({}, category))
    })

    categoriesToFilter = categoriesToFilter?.filter(
        (category) => !category.isSynthetic && !checkIfAllSyntheticNodes(category.nodes || [])
    )

    categoriesToFilter.forEach((category: Category) => {
        if (category.nodes && category.nodes?.length > 0) {
            category.nodes = category.nodes?.filter((node) => !node.isSynthetic && !checkIfAllSyntheticNodes(node.nodes || []))

            category.nodes?.forEach((node: Category) => {
                if (node.nodes) {
                    node.nodes = filterCategories(node.nodes)
                }
            })
        }
    })

    return categoriesToFilter
}

const InfoRow = styled(Row)`
    margin-top: 30px;

    @media ${device.mobile} {
        padding: 10px 20px 10px 10px;
        margin-top: 0;
    }
`

const InfoRowOrga = styled(InfoRow)`
    justify-content: space-evenly;
`

interface DetailInfoProps {
    type: EntityType
    order: InfoContentOrderType[]
    company?: Company
    description?: Description
    joboffer?: JobOffer
    event?: Event
    pictureURL?: string
    name?: string
    initials?: string
    socialMedias?: SocialMedia[]
    containerOffsetLeft: number | undefined
    newsDate?: string
    categories?: Category[]
    persons?: Person[]
    showCategoryHierarchy: boolean
    id: string
    logo?: string
    start?: string
    end?: string
    descriptionLong?: string
    showRecommendModal?: (shareType: ShareTargetType, entityId: string, entityName: string, link: string) => void
}

const DetailInfo: React.FunctionComponent<DetailInfoProps> = (props) => {
    const chime = useChimeContext()
    const meeting = useMeetingContext()
    const languageState = useLanguageState()
    const lang = languageState.getLanguage()
    const loggedInUser = useLoggedInState().user()
    const { showPrivacyPolicyModal, PrivacyModal } = usePrivacyPolicyModal()
    const queryParams: any = queryString.parse(window.location.search)
    const { company, joboffer, event } = props
    let config = getBasisPremiumConfig(props.company?.basisPremium! as BasisPremiumType)
    const { useMobileDesign } = useWindowDimensions()

    // chime v2 hooks
    const meetingInvitation = useMeetingInvitation()
    const presenceContext = usePresenceState()
    const history = useHistory()
    const { showNoStaffMembersModal, NoStaffMembersModal } = useStaffMembersModal()

    function onNoStaffMembers() {
        showNoStaffMembersModal()
    }

    const DesktopInfo =
        props.type === "organization" || props.type === "event" ? (
            <MobileSectionWrapper>
                {props.type === "event" && event && <EventActions event={event} showRecommendModal={props.showRecommendModal!} />}
                <InfoRowOrga>
                    <Col xs={useMobileDesign ? 9 : 12}>
                        <CenterContentContainer>
                            {props.type === "event" ? (
                                <LogoDivCompany>
                                    {props.logo ? (
                                        <CompanyLogo src={props.logo} alt="" />
                                    ) : branding.exhibitorsPageContent.defaultLogoVisibleOnCompanyTiles ? (
                                        <CompanyLogo src={"/branding/rsz_defaultgrey.png"} />
                                    ) : (
                                        <h2 style={{ fontFamily: branding.font1 }}>{props.initials}</h2>
                                    )}
                                </LogoDivCompany>
                            ) : (
                                <LogoDivCompany>
                                    {props.pictureURL ? (
                                        <CompanyLogo src={props.pictureURL} alt="" />
                                    ) : branding.exhibitorsPageContent.defaultLogoVisibleOnCompanyTiles ? (
                                        <CompanyLogo src={"/branding/rsz_defaultgrey.png"} />
                                    ) : (
                                        <h2 style={{ fontFamily: branding.font1 }}>{props.initials}</h2>
                                    )}
                                </LogoDivCompany>
                            )}
                        </CenterContentContainer>
                        <CenterContentContainer>
                            <h3 style={{ fontSize: useMobileDesign ? "16px" : "22px", fontWeight: 800 }}>{props.name}</h3>
                        </CenterContentContainer>
                        <CenterContentContainer>
                            {props.socialMedias?.length && <CompanySocialMediaDesktop socialMedias={props.socialMedias} />}
                            {props.start && props.end && (
                                <EventFormattedDate>
                                    {getFormattedDate(props.start, lang) + " - " + getFormattedDate(props.end, lang)}
                                </EventFormattedDate>
                            )}
                        </CenterContentContainer>

                        {props.type === "event" && props.descriptionLong && (
                            <CompanyTeaser className="mt-3 event">
                                {props.descriptionLong?.split("\\n").map((item, i) => (
                                    <span key={i}>
                                        {item}
                                        <br />
                                    </span>
                                ))}
                            </CompanyTeaser>
                        )}

                        {props.order.map((item, index) => {
                            if (item === InfoContentOrderType.COMPANY_DETAILS && company) {
                                return <CompanyDetailInfoDesktop company={company} />
                            }
                            if (item === InfoContentOrderType.BADGES) {
                                return (
                                    <div style={{ marginBottom: "20px" }}>
                                        <BadgeArea
                                            categories={flattenCategories(props.categories || [], props.showCategoryHierarchy)}
                                            marginTop="30px"
                                        />
                                    </div>
                                )
                            }

                            if (item === InfoContentOrderType.DESCRIPTION && props.description?.text) {
                                return (
                                    <>
                                        <HeaderTitleDetailInfoOrga className="mb-3 mt-4">
                                            {branding.organizationDetailPageContent.descriptionLabel}
                                        </HeaderTitleDetailInfoOrga>
                                        <CompanyTeaser key={index} className="mt-3">
                                            {props.description?.text.split("\\n").map((item, i) => (
                                                <span key={i}>
                                                    {item}
                                                    <br />
                                                </span>
                                            ))}
                                        </CompanyTeaser>
                                    </>
                                )
                            }
                            if (
                                item === InfoContentOrderType.HALLE_STAND_INFO &&
                                props.company &&
                                props.company.stands &&
                                props.company.stands.length
                            ) {
                                return (
                                    <HalleStandSection key={index} hasSocials={false}>
                                        <HeaderTitleDetailInfoOrga className="mb-3 mt-0">
                                            {branding.organizationDetailPageContent.halleStandSectionTitle}
                                        </HeaderTitleDetailInfoOrga>
                                        {props.company.stands.map((stand, idx) => {
                                            return (
                                                <HalleStandSingle
                                                    onClick={() => {
                                                        if (!branding.organizationDetailPageContent.halleStandLinkEnabled) return

                                                        history.push(buildHallplanLink(stand.hallNr, stand.standNr))

                                                        trackSelectContent(
                                                            stand.standName || stand.displayName,
                                                            "Details",
                                                            "Stand"
                                                        )
                                                    }}
                                                    key={idx}
                                                >
                                                    {stand.standName}
                                                </HalleStandSingle>
                                            )
                                        })}
                                    </HalleStandSection>
                                )
                            }
                            if (
                                item === InfoContentOrderType.TALK_WITH_TEAM &&
                                (isExplorationOrPostEventPhase || isLivePhase) &&
                                !branding.organizationDetailPageContent.removeExpoShowroomDuringPostEvent &&
                                company &&
                                config.expoShowroomEnabled &&
                                hasAvailableStaff(company)
                            ) {
                                return (
                                    <DesktopVersionContainer key={index}>
                                        <TalkWithTeamDiv key={index}>
                                            <Col xs={1}>
                                                {IconCameraJoin({
                                                    width: "25",
                                                    height: "25",
                                                    fill: branding.sideIconBar.sideIconColorDark
                                                })}
                                            </Col>
                                            <Col
                                                style={{ fontSize: "16px", fontWeight: 500 }}
                                                xs={branding.organizationDetailPageContent.joinButton.length > 14 ? 8 : 9}
                                            >
                                                {branding.organizationDetailPageContent.talkWithTeamBefString} {company.name}{" "}
                                                {branding.organizationDetailPageContent.talkWithTeamAftString}
                                            </Col>
                                            <JoinButtonCol
                                                xs={branding.organizationDetailPageContent.joinButton.length > 14 ? 3 : 2}
                                                onClick={() =>
                                                    company &&
                                                    showPrivacyPolicyModal(company, () => {
                                                        if (branding.configuration.useConferenceRoomV2) {
                                                            joinShowRoom2(
                                                                company,
                                                                loggedInUser!,
                                                                meetingInvitation,
                                                                presenceContext,
                                                                history,
                                                                onNoStaffMembers
                                                            )
                                                        } else {
                                                            joinShowroom(company, loggedInUser!, chime, meeting)
                                                        }
                                                    })
                                                }
                                                disabled={
                                                    !hasAvailableStaff(company) ||
                                                    (isExplorationOrPostEventPhase &&
                                                        !(getIamPartOf(queryParams) === "onboarding"))
                                                }
                                            >
                                                <p
                                                    className="user-select-none"
                                                    style={{
                                                        fontSize:
                                                            branding.organizationDetailPageContent.joinButton.length > 14
                                                                ? "12px"
                                                                : "14px",
                                                        marginBottom: "0px"
                                                    }}
                                                >
                                                    {branding.organizationDetailPageContent.joinButton}
                                                </p>
                                            </JoinButtonCol>
                                            {isExplorationOrPostEventPhase && (
                                                <ExplorationPhaseMessage className="mt-2">
                                                    {isExplorationPhase
                                                        ? branding.organizationDetailPageContent.explorationPhasePlaceholder
                                                        : branding.organizationDetailPageContent.postPhasePlaceholder}
                                                </ExplorationPhaseMessage>
                                            )}
                                        </TalkWithTeamDiv>
                                    </DesktopVersionContainer>
                                )
                            }
                            return null
                        })}
                    </Col>
                    <PrivacyModal />
                </InfoRowOrga>
                <PrivacyModal />
            </MobileSectionWrapper>
        ) : (
            <MobileSectionWrapper>
                <InfoRow></InfoRow>
                <InfoRow>
                    <Col xs={useMobileDesign ? 3 : 2}>
                        <LogoDiv>
                            {props.pictureURL ? (
                                <CompanyLogo src={props.pictureURL} alt="" />
                            ) : branding.exhibitorsPageContent.defaultLogoVisibleOnCompanyTiles ? (
                                <CompanyLogo src={"/branding/rsz_defaultgrey.png"} />
                            ) : (
                                <h2 style={{ fontFamily: branding.font1 }}>{props.initials}</h2>
                            )}
                        </LogoDiv>
                    </Col>
                    <Col xs={useMobileDesign ? 9 : 10}>
                        {props.type === "news" && props.order.includes(InfoContentOrderType.NEWS_DATE) && props.newsDate && (
                            <NewsDate>{getNewsDateFormatted(props.newsDate, languageState.getLanguage())}</NewsDate>
                        )}
                        <Col>
                            <Row>
                                <Col className="p-0" xs={10}>
                                    <h3 style={{ fontSize: useMobileDesign ? "16px" : "22px", fontWeight: 800 }}>{props.name}</h3>
                                </Col>
                                <Col className="d-flex justify-content-end p-0 text-right" xs={2}>
                                    {getShareTargetType(props.type) && props.showRecommendModal && (
                                        <ShareIcon
                                            onClick={(e) => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                if (props.showRecommendModal)
                                                    props.showRecommendModal(
                                                        getShareTargetType(props.type) || ShareTargetType.ORGANIZATION,
                                                        props.id,
                                                        props.name!,
                                                        buildDetailLink(props.id, props.name!, props.type)
                                                    )
                                            }}
                                        >
                                            {IconShare({ fill: branding.sideIconBar.sideIconColorDark })}
                                        </ShareIcon>
                                    )}
                                    <BookmarkWithToggle
                                        newBookmarkItem={true}
                                        fontSize={"25px"}
                                        color={branding.sideIconBar.sideIconColorDark}
                                        favIconBasic={true}
                                        type={props.type}
                                        id={props.id}
                                        name={props.name as string}
                                    />
                                </Col>
                            </Row>
                        </Col>

                        {props.order.map((item, index) => {
                            if (item === InfoContentOrderType.JOBOFFER_DETAILS && joboffer) {
                                return <JobOfferDetails joboffer={joboffer} />
                            } else if (item === InfoContentOrderType.BADGES) {
                                return (
                                    <BadgeArea
                                        categories={flattenCategories(props.categories || [], props.showCategoryHierarchy)}
                                        marginTop="20px"
                                    />
                                )
                            } else if (item === InfoContentOrderType.SOCIAL_MEDIA && props.socialMedias?.length) {
                                return <CompanySocialMediaDesktop key={index} socialMedias={props.socialMedias} />
                            }

                            if (
                                props.type === "news" &&
                                item === InfoContentOrderType.NEWS_SUBHEADLINE &&
                                props.description?.teaser
                            ) {
                                return (
                                    <DesktopVersionContainer>
                                        <NewsSubheadline key={index} className="mt-3">
                                            {props.description?.teaser.split("\\n").map((item, i) => (
                                                <span key={i}>
                                                    {item}
                                                    <br />
                                                </span>
                                            ))}
                                        </NewsSubheadline>
                                    </DesktopVersionContainer>
                                )
                            } else if (
                                props.type !== "news" &&
                                props.type !== "joboffer" &&
                                item === InfoContentOrderType.DESCRIPTION &&
                                props.description?.text
                            ) {
                                return (
                                    <CompanyTeaser key={index} className="mt-3">
                                        {props.description?.text.split("\\n").map((item, i) => (
                                            <span key={i}>
                                                {item}
                                                <br />
                                            </span>
                                        ))}
                                    </CompanyTeaser>
                                )
                            } else if (
                                item === InfoContentOrderType.HALLE_STAND_INFO &&
                                props.company &&
                                props.company.stands &&
                                props.company.stands.length
                            ) {
                                return (
                                    <HalleStandSection
                                        key={index}
                                        hasSocials={
                                            props.company?.socialMedias! && props.company?.socialMedias!.length > 0 ? true : false
                                        }
                                    >
                                        <HeaderTitle className="mb-3 mt-0">
                                            {branding.organizationDetailPageContent.halleStandSectionTitle}
                                        </HeaderTitle>
                                        {props.company.stands.map((stand, idx) => {
                                            return (
                                                <HalleStandSingle
                                                    onClick={() => {
                                                        if (!branding.organizationDetailPageContent.halleStandLinkEnabled) return

                                                        history.push(buildHallplanLink(stand.hallNr, stand.standNr))

                                                        trackSelectContent(
                                                            stand.standName || stand.displayName,
                                                            "Details",
                                                            "Stand"
                                                        )
                                                    }}
                                                    key={idx}
                                                >
                                                    {stand.standName}
                                                </HalleStandSingle>
                                            )
                                        })}
                                    </HalleStandSection>
                                )
                            } else if (
                                // old logic of showing / hidding the button
                                !branding.configuration.useConferenceRoomV2
                                    ? item === InfoContentOrderType.TALK_WITH_TEAM &&
                                      (isExplorationOrPostEventPhase || isLivePhase) &&
                                      !branding.organizationDetailPageContent.removeExpoShowroomDuringPostEvent &&
                                      company &&
                                      config.expoShowroomEnabled &&
                                      hasAvailableStaff(company)
                                    : // new logic, the button will be visible if the user is a staff member
                                      item === InfoContentOrderType.TALK_WITH_TEAM &&
                                      (isExplorationOrPostEventPhase || isLivePhase) &&
                                      !branding.organizationDetailPageContent.removeExpoShowroomDuringPostEvent &&
                                      company &&
                                      config.expoShowroomEnabled &&
                                      (company?.allPersons
                                          .map((attendee: Person) => attendee.userId)
                                          .includes(loggedInUser?.profileId || "") ||
                                          hasAvailableStaff(company))
                            ) {
                                return (
                                    <DesktopVersionContainer key={index}>
                                        <TalkWithTeamDiv key={index}>
                                            <Col xs={1}>
                                                {IconCameraJoin({
                                                    width: "25",
                                                    height: "25",
                                                    fill: branding.sideIconBar.sideIconColorDark
                                                })}
                                            </Col>
                                            <Col
                                                style={{ fontSize: "16px", fontWeight: 500 }}
                                                xs={branding.organizationDetailPageContent.joinButton.length > 14 ? 8 : 9}
                                            >
                                                {branding.organizationDetailPageContent.talkWithTeamBefString}{" "}
                                                {company?.name ?? ""}{" "}
                                                {branding.organizationDetailPageContent.talkWithTeamAftString}
                                            </Col>
                                            <JoinButtonCol
                                                xs={branding.organizationDetailPageContent.joinButton.length > 14 ? 3 : 2}
                                                onClick={() =>
                                                    company &&
                                                    showPrivacyPolicyModal(company, () => {
                                                        if (branding.configuration.useConferenceRoomV2) {
                                                            joinShowRoom2(
                                                                company,
                                                                loggedInUser!,
                                                                meetingInvitation,
                                                                presenceContext,
                                                                history,
                                                                onNoStaffMembers
                                                            )
                                                        } else {
                                                            joinShowroom(company, loggedInUser!, chime, meeting)
                                                        }
                                                    })
                                                }
                                                disabled={
                                                    // old logic for disabling
                                                    !branding.configuration.useConferenceRoomV2
                                                        ? !hasAvailableStaff(company) ||
                                                          (isExplorationOrPostEventPhase &&
                                                              !(getIamPartOf(queryParams) === "onboarding"))
                                                        : // new logic for disabling
                                                          false
                                                }
                                            >
                                                <p
                                                    className="user-select-none"
                                                    style={{
                                                        fontSize:
                                                            branding.organizationDetailPageContent.joinButton.length > 14
                                                                ? "12px"
                                                                : "14px",
                                                        marginBottom: "0px"
                                                    }}
                                                >
                                                    {branding.organizationDetailPageContent.joinButton}
                                                </p>
                                            </JoinButtonCol>
                                            {isExplorationOrPostEventPhase && (
                                                <ExplorationPhaseMessage className="mt-2">
                                                    {isExplorationPhase
                                                        ? branding.organizationDetailPageContent.explorationPhasePlaceholder
                                                        : branding.organizationDetailPageContent.postPhasePlaceholder}
                                                </ExplorationPhaseMessage>
                                            )}
                                        </TalkWithTeamDiv>
                                    </DesktopVersionContainer>
                                )
                            } else return null
                        })}
                    </Col>
                    {props.type === "news" &&
                        props.order.includes(InfoContentOrderType.DESCRIPTION) &&
                        props.description?.text && (
                            <Col xs={12}>
                                <Linkify componentDecorator={componentDecorator}>
                                    {props.description?.teaser && (
                                        <MobileVersionContainer>
                                            <NewsSubheadline className="mt-3">
                                                {props.description?.teaser?.split("\\n").map((item, i) => (
                                                    <span key={i}>
                                                        {item}
                                                        <br />
                                                    </span>
                                                ))}
                                            </NewsSubheadline>
                                        </MobileVersionContainer>
                                    )}

                                    <MobileVersionContainer>
                                        <CompanyTeaser className="mt-3 news">
                                            {props.description?.text?.split("\\n").map((item, i) => (
                                                <span key={i}>
                                                    {item}
                                                    <br />
                                                </span>
                                            ))}
                                        </CompanyTeaser>
                                    </MobileVersionContainer>

                                    <DesktopVersionContainer>
                                        <Row className="m-0">
                                            <Col xs={props.persons && props.persons?.length! > 0 ? 8 : 11} className="p-0">
                                                <CompanyTeaser className="mt-3 news">
                                                    {props.description?.text?.split("\\n").map((item, i) => (
                                                        <span key={i}>
                                                            {item}
                                                            <br />
                                                        </span>
                                                    ))}
                                                </CompanyTeaser>
                                            </Col>

                                            <Col xs={4} className="p-0">
                                                {props.persons && props.persons?.length! > 0 && (
                                                    <PersonsLegacy
                                                        persons={props.persons || []}
                                                        descriptionExists={props.description?.text !== undefined}
                                                        newsPage
                                                    />
                                                )}
                                            </Col>
                                        </Row>
                                    </DesktopVersionContainer>
                                </Linkify>
                            </Col>
                        )}
                    <PrivacyModal />
                </InfoRow>
                <PrivacyModal />
                <NoStaffMembersModal />
            </MobileSectionWrapper>
        )

    return (
        <>
            <ConditionalWrapper
                wrapper={(children) => (props.type !== "news" ? <DesktopVersionContainer children={children} /> : null)}
            >
                {DesktopInfo}
            </ConditionalWrapper>

            {props.type === "news" && props.persons && props.persons?.length! > 0 && (
                <MobileVersionContainer>
                    <MobileSectionWrapper>
                        <Col xs={11} className="p-0">
                            <PersonsLegacy
                                persons={props.persons || []}
                                descriptionExists={props.description?.text !== undefined}
                                newsPage
                            />
                        </Col>
                    </MobileSectionWrapper>
                </MobileVersionContainer>
            )}

            {props.type !== "news" && props.type !== "joboffer" && company && (
                <MobileVersionContainer>
                    <MobileSectionWrapper>
                        <Row>
                            <MobileCompanyInfo company={company} socialMedias={props.socialMedias} />
                        </Row>
                    </MobileSectionWrapper>
                </MobileVersionContainer>
            )}

            {props.type === "event" && event && (
                <MobileVersionContainer>
                    <MobileSectionWrapper>
                        <Row>
                            <EventDetailInfo event={event} showRecommendModal={props.showRecommendModal!} />
                        </Row>
                    </MobileSectionWrapper>
                </MobileVersionContainer>
            )}
        </>
    )
}

interface TileRowSectionProps {
    company: Company
    index: number
    containerOffsetLeft: number | undefined
}

const ShowRoomSection: React.FunctionComponent<TileRowSectionProps> = (props) => {
    const userAccessState = useUserRestrictedAreaAccess()
    const title = branding.organizationDetailPageContent.showRoomTitle.replace(
        "$count",
        props.company.conferenceRooms.length.toString()
    )
    const tileWidth = 350
    return (
        <DesktopVersionContainer key={props.index}>
            <RowTileParent key={props.index}>
                <TileRow
                    type="orgaDetail"
                    title={title}
                    titleNoLink={title}
                    offsetLeft={props.containerOffsetLeft}
                    scrollComponent
                    hideAllScrollbars
                    childCount={props.company.conferenceRooms.length}
                    childWidth={tileWidth}
                    scrollDistance={tileWidth + 10}
                    tileRowMargin="33px 0 0 0"
                    bottomSize="0px"
                    customHeaderHeight="30px"
                >
                    {props.company.conferenceRooms.map((conferenceRoom) => {
                        return (
                            <div key={conferenceRoom.id}>
                                <MeetingRoomGroup
                                    key={
                                        conferenceRoom.id +
                                        userAccessState.isUnlocked(conferenceRoom) +
                                        userAccessState.isLoaded(conferenceRoom)
                                    }
                                    source={undefined}
                                    meetingRoomGroupConfig={conferenceRoom}
                                    roomType="conferenceroom"
                                    companyStaff={props.company.allPersons}
                                    margin="0 20px 0 0"
                                    customTileDimensions={{ width: tileWidth + "px", height: "233px" }}
                                    customHeaderFontsize={"14px"}
                                    limitHeaderToTwoLines
                                />
                            </div>
                        )
                    })}
                </TileRow>
            </RowTileParent>
        </DesktopVersionContainer>
    )
}

const RemoteShowRoomSection: React.FunctionComponent<TileRowSectionProps> = (props) => {
    const sectionTitle = branding.organizationDetailPageContent.remoteLivestreamsTitle.replace(
        "$count",
        props.company.remoteLivestreams.length.toString()
    )
    const tileWidth = 640
    return (
        <DesktopVersionContainer key={props.index}>
            <RowTileParent>
                <TileRow
                    type="orgaDetail"
                    title={sectionTitle}
                    titleNoLink={sectionTitle}
                    offsetLeft={props.containerOffsetLeft}
                    scrollComponent
                    hideAllScrollbars
                    childCount={props.company.remoteLivestreams.length}
                    childWidth={tileWidth}
                    scrollDistance={tileWidth + 10}
                    tileRowMargin="36px 0 0 0"
                    bottomSize="0px"
                    customHeaderHeight="30px"
                >
                    {props.company.remoteLivestreams.map((stream, idx) => (
                        <RemoteLivestreamTile
                            key={stream.id ?? idx}
                            customDimensions={{ width: tileWidth + "px", height: "360px" }} // 16:9 ratio!
                        >
                            <VideoPlayerReal
                                src={stream.url}
                                controls={true}
                                live={true}
                                remoteLivestream={stream}
                                remoteStreamLayout={true}
                                disablePlayerStateIsSavedAndRead={true}
                                sourceIsStatic={false}
                            />
                        </RemoteLivestreamTile>
                    ))}
                </TileRow>
            </RowTileParent>
        </DesktopVersionContainer>
    )
}

const VirtualCafesSection: React.FunctionComponent<TileRowSectionProps> = (props) => {
    const userAccessState = useUserRestrictedAreaAccess()
    const orgaMeetingRoomGroups = branding.meetingRoomGroups.filter(
        (x) => x.organizationId === props.company!.id || x.secondOrganizationId === props.company!.id
    )
    const sectionTitle = branding.organizationDetailPageContent.virtualCafesTitle + " (" + orgaMeetingRoomGroups.length + ")"
    if (props.company.basisPremium === BasisPremiumType.NONE) return null
    return (
        <DesktopVersionContainer key={props.index}>
            {orgaMeetingRoomGroups.length !== 0 && (
                <RowTileParent>
                    <TileRow
                        type="orgaDetail"
                        title={sectionTitle}
                        titleNoLink={sectionTitle}
                        offsetLeft={props.containerOffsetLeft}
                        scrollComponent
                        hideAllScrollbars
                        childCount={orgaMeetingRoomGroups.length}
                        childWidth={500}
                        scrollDistance={500 + 10}
                        tileRowMargin="36px 0 0 0"
                        bottomSize="0px"
                        customHeaderHeight="30px"
                    >
                        {orgaMeetingRoomGroups.map((meetingRoomGroup) => (
                            <div key={meetingRoomGroup.id}>
                                <MeetingRoomGroup
                                    key={
                                        "" +
                                        userAccessState.isUnlocked(meetingRoomGroup) +
                                        userAccessState.isLoaded(meetingRoomGroup)
                                    }
                                    source="ORG_DETAIL"
                                    meetingRoomGroupConfig={meetingRoomGroup}
                                    roomType="virtualCafe"
                                    limitHeaderToTwoLines
                                    margin="0 20px 0 0"
                                />
                            </div>
                        ))}
                    </TileRow>
                </RowTileParent>
            )}
        </DesktopVersionContainer>
    )
}

const ViewMoreButtonRoot = styled.div<{ isMobile?: boolean }>`
    display: flex;
    justify-content: center;
    ${(props) => (props.isMobile ? "padding-bottom: 21px;" : "")}
    width: 100%;
`

const ViewMoreButtonText = styled.div`
    font-weight: 400;
`

interface ViewMoreButtonProps {
    entries: EventDate[] | Staff[] | EntityRowData[] | Category[]
    showDetailSectionModal: (content: React.ReactNode) => void
    maxNumEntries: number
}

export const ViewMoreButton: React.FunctionComponent<ViewMoreButtonProps> = (props) => {
    const { isMobile } = useWindowDimensions()
    if (props.entries.length <= props.maxNumEntries) return null
    return (
        <ViewMoreButtonRoot isMobile={isMobile}>
            <ButtonColored
                color={branding.communicationArea.editProfileButtonColor}
                onClick={props.showDetailSectionModal}
                height="34px"
            >
                <ViewMoreButtonText>{branding.organizationDetailPageContent.buttonShowMore}</ViewMoreButtonText>
            </ButtonColored>
        </ViewMoreButtonRoot>
    )
}

interface NowLiveSectionProps extends TileRowSectionProps {
    liveChannels: Channel[]
    liveSessions?: EventDate[]
    source?: UserOrganizationVisitSource
}

const NowLiveSection: React.FunctionComponent<NowLiveSectionProps> = (props) => {
    const userAccessState = useUserRestrictedAreaAccess()
    const tileWidth = 640
    const tileHeight = (tileWidth * 9) / 16
    return (
        <DesktopVersionContainer key={props.index}>
            {props.liveChannels && props.liveChannels.length > 0 && (
                <RowTileParent>
                    <TileRow
                        type="orgaDetail"
                        title={branding.receptionPage.liveSessions}
                        titleNoLink={branding.receptionPage.nowLive + " (" + props.liveChannels.length + ")"}
                        offsetLeft={props.containerOffsetLeft}
                        scrollComponent
                        hideAllScrollbars
                        childCount={props.liveChannels.length}
                        childWidth={tileWidth}
                        scrollDistance={tileWidth + 10}
                        tileRowMargin="40px 0"
                        bottomSize="36px"
                        customHeaderHeight="30px"
                    >
                        {props.liveChannels.map((channel) => (
                            <InView key={channel.id} threshold={0.1} initialInView>
                                {({ inView }) =>
                                    inView ? (
                                        <LiveEvent
                                            id={props.index.toString()}
                                            title={channel.name}
                                            thumbnailUrl={channel.thumbnailUrl}
                                            channelId={channel.id}
                                            channelIcon={IconLiveStream({ fill: "#fff" })}
                                            eventDate={channel.currentEventDate}
                                            rerenderOnUnlockedChanged={userAccessState.isUnlocked(channel.currentEventDate)}
                                            src={props.source || "UNKNOWN"}
                                            customDimensions={{ width: tileWidth + "px", height: tileHeight + "px" }}
                                            orgaDetailPage
                                        />
                                    ) : (
                                        <div style={{ width: 480, height: 280 }}></div>
                                    )
                                }
                            </InView>
                        ))}
                    </TileRow>
                </RowTileParent>
            )}
            {props.liveSessions && props.liveSessions.length > 0 && (
                <SessionsSection
                    entries={props.liveSessions}
                    sectionTitle={
                        "" + branding.organizationDetailPageContent.liveSessionsTitle + " (" + props.liveSessions.length + ")"
                    }
                />
            )}
        </DesktopVersionContainer>
    )
}

export const DetailSectionTitle = styled.div<{ marginTop?: string; marginBottom?: string }>`
    font-weight: 700;
    font-size: 18px;
    margin-top: ${(props) => props.marginTop ?? "20px"};
    ${(props) => (props.marginBottom ? "margin-bottom: " + props.marginBottom + ";" : "")};
`

interface SessionSectionProps {
    entries: EventDate[]
    sectionTitle: string
}

const SessionsSection: React.FunctionComponent<SessionSectionProps> = (props) => {
    const { DetailSectionModal, showDetailSectionModal, hideDetailSectionModal } = useDetailSectionModal()
    const { RecommendModal, showRecommendModal } = useRecommendModal(
        () => showDetailSectionModal(modalContent, props.sectionTitle),
        hideDetailSectionModal
    )
    const maxNumEntries = 3
    const modalContent: JSX.Element = (
        <>
            <ListEventDateEntryColumn eventDates={props.entries} orgaDetailPage openRecommendModalInParent={showRecommendModal} />
        </>
    )

    return (
        <DesktopVersionContainer key={props.entries[0].id}>
            <DetailSectionModal />
            <RecommendModal />
            <DetailSectionTitle marginTop="50px">{props.sectionTitle}</DetailSectionTitle>
            <ListEventDateEntryColumn eventDates={props.entries.slice(0, maxNumEntries)} orgaDetailPage />
            <ViewMoreButton
                {...props}
                showDetailSectionModal={() => showDetailSectionModal(modalContent, props.sectionTitle)}
                maxNumEntries={maxNumEntries}
            />
        </DesktopVersionContainer>
    )
}

const CompanyInfoIconContainer = styled.div`
    padding-right: 15px;
`
const ShowMoreDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
    /* padding-right: 40px; */
`

const ShowMoreBtn = styled.div`
    cursor: pointer;
    font-family: ${branding.font1};
    font-size: 12px;
    line-height: 17px;
`

const MobileCompanyInfo: React.FunctionComponent<{ company?: Company; socialMedias?: SocialMedia[] }> = (props) => {
    const lang = useLanguageState().getLanguage()
    const [showMore, setShowMore] = useState(false)
    const [showMoreBtnClicked, setShowMoreBtnClicked] = useState(false)
    const history = useHistory()

    useEffect(() => {
        if (props.company && props.company?.stands!) props.company?.stands!.length > 3 ? setShowMore(true) : setShowMore(false)
        else setShowMore(false)
        // eslint-disable-next-line
    }, [props.company?.stands])

    useEffect(() => {
        setShowMoreBtnClicked(false)
    }, [lang])

    const componentDecorator = (href: string, text: string, key: number) => (
        <a
            style={{ fontWeight: "bold", color: branding.mainInfoColor }}
            href={href}
            key={key}
            target="_blank"
            rel="noopener noreferrer"
        >
            {text}
        </a>
    )

    return (
        <CompanyAdditionalInfoRootMobile>
            <DetailsAdditionalInfoDiv style={{ flex: 1, display: "flex", flexDirection: "row", marginRight: "50px" }}>
                <CompanyInfoIconContainer>
                    {(props.company?.name || props.company?.adress1 || props.company?.postCode || props.company?.country) &&
                        IconLocation({ fill: branding.sideIconBar.sideIconColorDark, width: "17", height: "17" })}
                </CompanyInfoIconContainer>
                <div>
                    <p>{props.company?.name}</p>
                    {props.company?.adress1 && <p>{props.company?.adress1}</p>}
                    {props.company?.adress2 && <p>{props.company?.adress2}</p>}
                    {props.company?.adress3 && <p>{props.company?.adress3}</p>}
                    <p>
                        {props.company?.postCode} {props.company?.city}
                    </p>
                    <p>{props.company?.country}</p>
                </div>
            </DetailsAdditionalInfoDiv>

            {props.company?.phone && !branding.organizationDetailPageContent.hideTelephone && (
                <DetailsAdditionalInfoDiv style={{ flex: 1, display: "flex", flexDirection: "row", marginRight: "50px" }}>
                    <CompanyInfoIconContainer>
                        {IconPhone({ fill: branding.sideIconBar.sideIconColorDark, width: "17", height: "17" })}
                    </CompanyInfoIconContainer>
                    <div>{<p>{props.company?.phone}</p>}</div>
                </DetailsAdditionalInfoDiv>
            )}

            {props.company?.email && !branding.organizationDetailPageContent.hideEmailAddress && (
                <DetailsAdditionalInfoDiv style={{ flex: 1, display: "flex", flexDirection: "row" }}>
                    <CompanyInfoIconContainer>
                        {IconEmail({ fill: branding.sideIconBar.sideIconColorDark, width: "17", height: "17" })}
                    </CompanyInfoIconContainer>
                    <p>
                        <Linkify componentDecorator={componentDecorator}>{props.company?.email}</Linkify>
                    </p>
                </DetailsAdditionalInfoDiv>
            )}

            {props.company?.web && (
                <DetailsAdditionalInfoDiv style={{ flex: 1, display: "flex", flexDirection: "row" }}>
                    <CompanyInfoIconContainer>
                        {IconLink({ fill: branding.sideIconBar.sideIconColorDark, width: "17", height: "17" })}
                    </CompanyInfoIconContainer>
                    <p>
                        <Linkify componentDecorator={componentDecorator}>{props.company?.web}</Linkify>
                    </p>
                </DetailsAdditionalInfoDiv>
            )}

            {props.company && props.company?.stands && props.company?.stands.length && (
                <div>
                    <DetailsAdditionalInfoDiv style={{ flex: 1, display: "flex", flexDirection: "row" }}>
                        <CompanyInfoIconContainer>
                            {IconHallplan({ fill: branding.sideIconBar.sideIconColorDark, width: "17", height: "17" })}
                        </CompanyInfoIconContainer>
                        <HalleStandSectionMobileView>
                            <p>{branding.organizationDetailPageContent.halleStandSectionTitle}</p>
                            {props.company?.stands
                                .slice(0, showMoreBtnClicked ? props.company?.stands.length : 3)
                                .map((stand, idx) => {
                                    return (
                                        <HalleStandSingleMobileView
                                            onClick={() => {
                                                if (!branding.organizationDetailPageContent.halleStandLinkEnabled) return

                                                history.push(buildHallplanLink(stand.hallNr, stand.standNr))

                                                trackSelectContent(stand.standName || stand.displayName, "Details", "Stand")
                                            }}
                                            key={idx}
                                        >
                                            {stand.standName}
                                        </HalleStandSingleMobileView>
                                    )
                                })}
                            {props.company?.stands.length > 3 && showMore && (
                                <ShowMoreDiv>
                                    <ShowMoreBtn onClick={() => setShowMoreBtnClicked(!showMoreBtnClicked)}>
                                        {showMoreBtnClicked
                                            ? branding.productDetailPageContent.showLessText
                                            : branding.productDetailPageContent.showMoreText}
                                    </ShowMoreBtn>
                                    <div style={{ marginLeft: "7px", marginBottom: "2px" }}>
                                        {showMoreBtnClicked
                                            ? IconShowLess({
                                                  fill: branding.sideIconBar.sideIconColorDark,
                                                  width: "12",
                                                  height: "12"
                                              })
                                            : IconShowMore({
                                                  fill: branding.sideIconBar.sideIconColorDark,
                                                  width: "12",
                                                  height: "12"
                                              })}
                                    </div>
                                </ShowMoreDiv>
                            )}
                        </HalleStandSectionMobileView>
                    </DetailsAdditionalInfoDiv>
                </div>
            )}

            {props.company?.description?.text && (
                <DetailsAdditionalInfoDiv style={{ marginTop: "20px", marginBottom: "20px" }}>
                    <CompanyInfoParagraph lang={lang} style={{ marginBottom: "5px" }}>
                        {branding.organizationDetailPageContent.descriptionLabel}
                    </CompanyInfoParagraph>
                    <p style={{ marginRight: "85px" }}>
                        {props.company?.description?.text.split("\\n").map((item, i) => (
                            <span key={i}>
                                {item}
                                <br />
                            </span>
                        ))}
                    </p>
                </DetailsAdditionalInfoDiv>
            )}

            {props.company?.foundingYear && (
                <DetailsAdditionalInfoDiv style={{ flex: 1, display: "flex", flexDirection: "row" }}>
                    <CompanyInfoParagraph lang={lang}>
                        {branding.organizationDetailPageContent.foundingYearTitle}:
                    </CompanyInfoParagraph>

                    <p>{props.company?.foundingYear}</p>
                </DetailsAdditionalInfoDiv>
            )}

            {props.company?.size && (
                <DetailsAdditionalInfoDiv style={{ flex: 1, display: "flex", flexDirection: "row" }}>
                    <CompanyInfoParagraph lang={lang}>
                        {branding.organizationDetailPageContent.companySizeTitle}:
                    </CompanyInfoParagraph>

                    <p>{props.company?.size}</p>
                </DetailsAdditionalInfoDiv>
            )}

            {props.company?.industry && (
                <DetailsAdditionalInfoDiv style={{ flex: 1, display: "flex", flexDirection: "row", marginRight: "30px" }}>
                    <CompanyInfoParagraph lang={lang}>
                        {branding.organizationDetailPageContent.industryTitle}:
                    </CompanyInfoParagraph>

                    <p>{props.company?.industry}</p>
                </DetailsAdditionalInfoDiv>
            )}
            {props.socialMedias && <CompanySocialMediaMobile socialMedias={props.socialMedias} />}
        </CompanyAdditionalInfoRootMobile>
    )
}
/* #endregion */

/* #region  Company social media */

const SocialMediaIconMobile = styled.div`
    cursor: pointer;

    a {
        color: unset;
        text-decoration: none;
    }
`

const SocialMediaIconDesktop = styled(SocialMediaIconMobile)`
    margin-left: 4px;
    margin-right: 4px;
`
const SocialMediaContainerDesktop = styled.div`
    display: flex;
    margin-top: 5px;
    margin-bottom: 0;

    @media ${device.tablet} {
        margin-bottom: 2rem !important;
    }
`

const SocialMediaContainerMobile = styled(SocialMediaContainerDesktop)`
    @media ${device.tablet} {
        margin-top: 2rem !important;
    }
`

interface CompanySocialMediaProps {
    socialMedias: SocialMedia[]
}

const CompanySocialMediaDesktop: React.FunctionComponent<CompanySocialMediaProps> = (props) => {
    const iconSize = "25"
    const facebook: SocialMedia | undefined = props.socialMedias.find(
        (socialMedia: SocialMedia) => socialMedia.type === SocialMediaType.FACEBOOK
    )
    const twitter: SocialMedia | undefined = props.socialMedias.find(
        (socialMedia: SocialMedia) => socialMedia.type === SocialMediaType.TWITTER
    )
    const instagram: SocialMedia | undefined = props.socialMedias.find(
        (socialMedia: SocialMedia) => socialMedia.type === SocialMediaType.INSTAGRAM
    )
    const youTube: SocialMedia | undefined = props.socialMedias.find(
        (socialMedia: SocialMedia) => socialMedia.type === SocialMediaType.YOUTUBE
    )
    const linkedIn: SocialMedia | undefined = props.socialMedias.find(
        (socialMedia: SocialMedia) => socialMedia.type === SocialMediaType.LINKEDIN
    )
    const xing: SocialMedia | undefined = props.socialMedias.find(
        (socialMedia: SocialMedia) => socialMedia.type === SocialMediaType.XING
    )
    const pinterest: SocialMedia | undefined = props.socialMedias.find(
        (socialMedia: SocialMedia) => socialMedia.type === SocialMediaType.PINTEREST
    )
    const tiktok: SocialMedia | undefined = props.socialMedias.find(
        (socialMedia: SocialMedia) => socialMedia.type === SocialMediaType.TIKTOK
    )

    function checkIfValid(socialMedia: SocialMedia | undefined) {
        return socialMedia && socialMedia !== null && socialMedia?.link?.length! > 0
    }

    function handleLinkClick(link: string, type: SocialMediaType, e: React.MouseEvent<HTMLAnchorElement>) {
        if (!link) e.currentTarget.removeAttribute("href")

        trackSelectContent(link, "Details", "Link")
    }

    if (
        !checkIfValid(facebook) &&
        !checkIfValid(twitter) &&
        !checkIfValid(instagram) &&
        !checkIfValid(youTube) &&
        !checkIfValid(linkedIn) &&
        !checkIfValid(xing) &&
        !checkIfValid(pinterest) &&
        !checkIfValid(tiktok)
    )
        return <></>

    return (
        <DetailsAdditionalInfoDiv>
            <SocialMediaContainerDesktop>
                {branding.organizationDetailPageContent.availableSocialMedias.map((item, index) => {
                    if (item === SocialMediaType.FACEBOOK) {
                        return (
                            <React.Fragment key={index}>
                                {facebook && facebook.link && facebook.link.length > 0 && (
                                    <SocialMediaIconDesktop>
                                        <a
                                            href={getProperUrl(facebook.link)}
                                            onClick={(e) => handleLinkClick(facebook.link, SocialMediaType.FACEBOOK, e)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {branding.socialMediaIcons.useOriginalSocialMediaIcons
                                                ? IconFacebookOriginal({
                                                      width: iconSize,
                                                      height: iconSize
                                                  })
                                                : IconFacebook3({
                                                      fill: branding.sideIconBar.sideIconColorDark,
                                                      width: iconSize,
                                                      height: iconSize
                                                  })}
                                        </a>
                                    </SocialMediaIconDesktop>
                                )}
                            </React.Fragment>
                        )
                    }

                    if (item === SocialMediaType.TWITTER) {
                        return (
                            <React.Fragment key={index}>
                                {twitter && twitter.link && twitter.link.length > 0 && (
                                    <SocialMediaIconDesktop>
                                        <a
                                            href={getProperUrl(twitter.link)}
                                            onClick={(e) => handleLinkClick(twitter.link, SocialMediaType.TWITTER, e)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {branding.socialMediaIcons.useOriginalSocialMediaIcons
                                                ? IconTwitter({ width: iconSize, height: iconSize })
                                                : IconTwitter3({
                                                      fill: branding.sideIconBar.sideIconColorDark,
                                                      width: iconSize,
                                                      height: iconSize
                                                  })}
                                        </a>
                                    </SocialMediaIconDesktop>
                                )}
                            </React.Fragment>
                        )
                    }

                    if (item === SocialMediaType.LINKEDIN) {
                        return (
                            <React.Fragment key={index}>
                                {linkedIn && linkedIn.link && linkedIn.link.length > 0 && (
                                    <SocialMediaIconDesktop>
                                        <a
                                            href={getProperUrl(linkedIn.link)}
                                            onClick={(e) => handleLinkClick(linkedIn.link, SocialMediaType.LINKEDIN, e)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {branding.socialMediaIcons.useOriginalSocialMediaIcons
                                                ? IconLinkedInOriginal({ width: iconSize, height: iconSize })
                                                : IconLinkedIn3({
                                                      fill: branding.sideIconBar.sideIconColorDark,
                                                      width: iconSize,
                                                      height: iconSize
                                                  })}
                                        </a>
                                    </SocialMediaIconDesktop>
                                )}
                            </React.Fragment>
                        )
                    }

                    if (item === SocialMediaType.XING) {
                        return (
                            <React.Fragment key={index}>
                                {xing && xing.link && xing.link.length > 0 && (
                                    <SocialMediaIconDesktop>
                                        <a
                                            href={getProperUrl(xing.link)}
                                            onClick={(e) => handleLinkClick(xing.link, SocialMediaType.XING, e)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {branding.socialMediaIcons.useOriginalSocialMediaIcons
                                                ? IconXingOriginal({ width: iconSize, height: iconSize })
                                                : IconXing3({
                                                      fill: branding.sideIconBar.sideIconColorDark,
                                                      width: iconSize,
                                                      height: iconSize
                                                  })}
                                        </a>
                                    </SocialMediaIconDesktop>
                                )}
                            </React.Fragment>
                        )
                    }

                    if (item === SocialMediaType.INSTAGRAM) {
                        return (
                            <React.Fragment key={index}>
                                {instagram && instagram.link && instagram.link.length > 0 && (
                                    <SocialMediaIconDesktop>
                                        <a
                                            href={getProperUrl(instagram.link)}
                                            onClick={(e) => handleLinkClick(instagram.link, SocialMediaType.INSTAGRAM, e)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {branding.socialMediaIcons.useOriginalSocialMediaIcons
                                                ? IconInstagramOriginal({ width: iconSize, height: iconSize })
                                                : IconInstagram3({
                                                      fill: branding.sideIconBar.sideIconColorDark,
                                                      width: iconSize,
                                                      height: iconSize
                                                  })}
                                        </a>
                                    </SocialMediaIconDesktop>
                                )}
                            </React.Fragment>
                        )
                    }

                    if (item === SocialMediaType.YOUTUBE) {
                        return (
                            <React.Fragment key={index}>
                                {youTube && youTube.link && youTube.link.length > 0 && (
                                    <SocialMediaIconDesktop>
                                        <a
                                            href={getProperUrl(youTube.link)}
                                            onClick={(e) => handleLinkClick(youTube.link, SocialMediaType.YOUTUBE, e)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {branding.socialMediaIcons.useOriginalSocialMediaIcons
                                                ? IconYouTubeOriginal({ width: iconSize, height: iconSize })
                                                : IconYouTube3({
                                                      fill: branding.sideIconBar.sideIconColorDark,
                                                      width: iconSize,
                                                      height: iconSize
                                                  })}
                                        </a>
                                    </SocialMediaIconDesktop>
                                )}
                            </React.Fragment>
                        )
                    }

                    if (item === SocialMediaType.PINTEREST) {
                        return (
                            <React.Fragment key={index}>
                                {pinterest && pinterest.link && pinterest.link.length > 0 && (
                                    <SocialMediaIconDesktop>
                                        <a
                                            href={getProperUrl(pinterest.link)}
                                            onClick={(e) => handleLinkClick(pinterest.link, SocialMediaType.PINTEREST, e)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {branding.socialMediaIcons.useOriginalSocialMediaIcons
                                                ? IconPinterestOrginal({ width: iconSize, height: iconSize })
                                                : IconPinterest3({
                                                      fill: branding.sideIconBar.sideIconColorDark,
                                                      width: iconSize,
                                                      height: iconSize
                                                  })}
                                        </a>
                                    </SocialMediaIconDesktop>
                                )}
                            </React.Fragment>
                        )
                    }

                    if (item === SocialMediaType.TIKTOK) {
                        return (
                            <React.Fragment key={index}>
                                {tiktok && tiktok.link && tiktok.link.length > 0 && (
                                    <SocialMediaIconDesktop>
                                        <a
                                            href={getProperUrl(tiktok.link)}
                                            onClick={(e) => handleLinkClick(tiktok.link, SocialMediaType.TIKTOK, e)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {branding.socialMediaIcons.useOriginalSocialMediaIcons
                                                ? IconTikTokOriginal({ width: iconSize, height: iconSize })
                                                : IconTikTok({
                                                      fill: branding.sideIconBar.sideIconColorDark,
                                                      width: iconSize,
                                                      height: iconSize
                                                  })}
                                        </a>
                                    </SocialMediaIconDesktop>
                                )}
                            </React.Fragment>
                        )
                    }

                    return null
                })}
            </SocialMediaContainerDesktop>
        </DetailsAdditionalInfoDiv>
    )
}
const CompanySocialMediaMobile: React.FunctionComponent<CompanySocialMediaProps> = (props) => {
    const facebook: SocialMedia | undefined = props.socialMedias.find(
        (socialMedia: SocialMedia) => socialMedia.type === SocialMediaType.FACEBOOK
    )
    const twitter: SocialMedia | undefined = props.socialMedias.find(
        (socialMedia: SocialMedia) => socialMedia.type === SocialMediaType.TWITTER
    )
    const instagram: SocialMedia | undefined = props.socialMedias.find(
        (socialMedia: SocialMedia) => socialMedia.type === SocialMediaType.INSTAGRAM
    )
    const youTube: SocialMedia | undefined = props.socialMedias.find(
        (socialMedia: SocialMedia) => socialMedia.type === SocialMediaType.YOUTUBE
    )
    const linkedIn: SocialMedia | undefined = props.socialMedias.find(
        (socialMedia: SocialMedia) => socialMedia.type === SocialMediaType.LINKEDIN
    )
    const xing: SocialMedia | undefined = props.socialMedias.find(
        (socialMedia: SocialMedia) => socialMedia.type === SocialMediaType.XING
    )
    const pinterest: SocialMedia | undefined = props.socialMedias.find(
        (socialMedia: SocialMedia) => socialMedia.type === SocialMediaType.PINTEREST
    )
    const tiktok: SocialMedia | undefined = props.socialMedias.find(
        (socialMedia: SocialMedia) => socialMedia.type === SocialMediaType.TIKTOK
    )

    const checkIfValid = (socialMedia: SocialMedia | undefined) => {
        return socialMedia && socialMedia !== null && socialMedia?.link?.length! > 0
    }

    const handleLinkClick = (link: string, type: SocialMediaType, e: React.MouseEvent<HTMLAnchorElement>) => {
        if (!link) e.currentTarget.removeAttribute("href")

        trackSelectContent(link, "Details", "Link")
    }

    if (
        !checkIfValid(facebook) &&
        !checkIfValid(twitter) &&
        !checkIfValid(instagram) &&
        !checkIfValid(youTube) &&
        !checkIfValid(linkedIn) &&
        !checkIfValid(xing) &&
        !checkIfValid(pinterest)
    )
        return <></>

    return (
        <DetailsAdditionalInfoDiv style={{ marginTop: "50px" }}>
            <HeaderTitle className="mb-3 mt-0">{branding.myProfilePageContent.socialMediaSectionTitle}</HeaderTitle>
            <SocialMediaContainerMobile>
                {branding.organizationDetailPageContent.availableSocialMedias.map((item, index) => {
                    if (item === SocialMediaType.FACEBOOK) {
                        return (
                            <React.Fragment key={index}>
                                {facebook && facebook.link && facebook.link.length > 0 && (
                                    <SocialMediaIconMobile className="mr-3">
                                        <a
                                            href={getProperUrl(facebook.link)}
                                            onClick={(e) => handleLinkClick(facebook.link, SocialMediaType.FACEBOOK, e)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {branding.socialMediaIcons.useOriginalSocialMediaIcons
                                                ? IconFacebookOriginal({ width: "20", height: "20" })
                                                : IconFacebook({
                                                      fill: branding.sideIconBar.sideIconColorDark,
                                                      width: "20",
                                                      height: "20"
                                                  })}
                                        </a>
                                    </SocialMediaIconMobile>
                                )}
                            </React.Fragment>
                        )
                    }

                    if (item === SocialMediaType.TWITTER) {
                        return (
                            <React.Fragment key={index}>
                                {twitter && twitter.link && twitter.link.length > 0 && (
                                    <SocialMediaIconMobile className="mr-3">
                                        <a
                                            href={getProperUrl(twitter.link)}
                                            onClick={(e) => handleLinkClick(twitter.link, SocialMediaType.TWITTER, e)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {branding.socialMediaIcons.useOriginalSocialMediaIcons
                                                ? IconTwitter({ width: "20", height: "20" })
                                                : IconTwitter({
                                                      fill: branding.sideIconBar.sideIconColorDark,
                                                      width: "20",
                                                      height: "20"
                                                  })}
                                        </a>
                                    </SocialMediaIconMobile>
                                )}
                            </React.Fragment>
                        )
                    }

                    if (item === SocialMediaType.LINKEDIN) {
                        return (
                            <React.Fragment key={index}>
                                {linkedIn && linkedIn.link && linkedIn.link.length > 0 && (
                                    <SocialMediaIconMobile className="mr-3">
                                        <a
                                            href={getProperUrl(linkedIn.link)}
                                            onClick={(e) => handleLinkClick(linkedIn.link, SocialMediaType.LINKEDIN, e)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {branding.socialMediaIcons.useOriginalSocialMediaIcons
                                                ? IconLinkedInOriginal({ width: "20", height: "20" })
                                                : IconLinkedIn({
                                                      fill: branding.sideIconBar.sideIconColorDark,
                                                      width: "20",
                                                      height: "20"
                                                  })}
                                        </a>
                                    </SocialMediaIconMobile>
                                )}
                            </React.Fragment>
                        )
                    }

                    if (item === SocialMediaType.XING) {
                        return (
                            <React.Fragment key={index}>
                                {xing && xing.link && xing.link.length > 0 && (
                                    <SocialMediaIconMobile className="mr-3">
                                        <a
                                            href={getProperUrl(xing.link)}
                                            onClick={(e) => handleLinkClick(xing.link, SocialMediaType.XING, e)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {branding.socialMediaIcons.useOriginalSocialMediaIcons
                                                ? IconXingOriginal({ width: "20", height: "20" })
                                                : IconXing({
                                                      fill: branding.sideIconBar.sideIconColorDark,
                                                      width: "20",
                                                      height: "20"
                                                  })}
                                        </a>
                                    </SocialMediaIconMobile>
                                )}
                            </React.Fragment>
                        )
                    }

                    if (item === SocialMediaType.INSTAGRAM) {
                        return (
                            <React.Fragment key={index}>
                                {instagram && instagram.link && instagram.link.length > 0 && (
                                    <SocialMediaIconMobile className="mr-3">
                                        <a
                                            href={getProperUrl(instagram.link)}
                                            onClick={(e) => handleLinkClick(instagram.link, SocialMediaType.INSTAGRAM, e)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {branding.socialMediaIcons.useOriginalSocialMediaIcons
                                                ? IconInstagramOriginal({ width: "20", height: "20" })
                                                : IconInstagram({
                                                      fill: branding.sideIconBar.sideIconColorDark,
                                                      width: "20",
                                                      height: "20"
                                                  })}
                                        </a>
                                    </SocialMediaIconMobile>
                                )}
                            </React.Fragment>
                        )
                    }

                    if (item === SocialMediaType.YOUTUBE) {
                        return (
                            <React.Fragment key={index}>
                                {youTube && youTube.link && youTube.link.length > 0 && (
                                    <SocialMediaIconMobile className="mr-3">
                                        <a
                                            href={getProperUrl(youTube.link)}
                                            onClick={(e) => handleLinkClick(youTube.link, SocialMediaType.YOUTUBE, e)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {branding.socialMediaIcons.useOriginalSocialMediaIcons
                                                ? IconYouTubeOriginal({ width: "20", height: "20" })
                                                : IconYouTube({
                                                      fill: branding.sideIconBar.sideIconColorDark,
                                                      width: "20",
                                                      height: "20"
                                                  })}
                                        </a>
                                    </SocialMediaIconMobile>
                                )}
                            </React.Fragment>
                        )
                    }

                    if (item === SocialMediaType.PINTEREST) {
                        return (
                            <React.Fragment key={index}>
                                {pinterest && pinterest.link && pinterest.link.length > 0 && (
                                    <SocialMediaIconMobile className="mr-3">
                                        <a
                                            href={getProperUrl(pinterest.link)}
                                            onClick={(e) => handleLinkClick(pinterest.link, SocialMediaType.PINTEREST, e)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {branding.socialMediaIcons.useOriginalSocialMediaIcons
                                                ? IconPinterestOrginal({ width: "20", height: "20" })
                                                : IconPinterest({
                                                      fill: branding.sideIconBar.sideIconColorDark,
                                                      width: "20",
                                                      height: "20"
                                                  })}
                                        </a>
                                    </SocialMediaIconMobile>
                                )}
                            </React.Fragment>
                        )
                    }

                    if (item === SocialMediaType.TIKTOK) {
                        return (
                            <React.Fragment key={index}>
                                {tiktok && tiktok.link && tiktok.link.length > 0 && (
                                    <SocialMediaIconMobile className="mr-3">
                                        <a
                                            href={getProperUrl(tiktok.link)}
                                            onClick={(e) => handleLinkClick(tiktok.link, SocialMediaType.TIKTOK, e)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {branding.socialMediaIcons.useOriginalSocialMediaIcons
                                                ? IconTikTokOriginal({ width: "20", height: "20" })
                                                : IconTikTok({
                                                      fill: branding.sideIconBar.sideIconColorDark,
                                                      width: "20",
                                                      height: "20"
                                                  })}
                                        </a>
                                    </SocialMediaIconMobile>
                                )}
                            </React.Fragment>
                        )
                    }

                    return null
                })}
            </SocialMediaContainerMobile>
        </DetailsAdditionalInfoDiv>
    )
}

/* #endregion */

/* #region  Founding year, size, industry*/
const CompanyDetailsRoot = styled.div<{ showYearSizeIndustry?: boolean }>`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex: 1;
    line-height: 17px;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    font-family: ${branding.font1};
    ${(props) => (props.showYearSizeIndustry ? "" : "margin-left: 50px;")}
`
const CompanyAdditionalInfoRootMobile = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 1;
    line-height: 17px;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    font-family: ${branding.font1};
    margin-top: 10px;

    & p {
        margin-bottom: 0;
    }

    @media ${device.mobile} {
        margin-left: 25px;
    }
`

const CompanyAdditionalInfoRootDesktop = styled(CompanyAdditionalInfoRootMobile)`
    margin-top: 0 !important;
    height: 100px;
`

const DetailsAdditionalInfoDiv = styled.div`
    line-height: 17px;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    font-family: ${branding.font1};

    & p {
        margin-bottom: 0;
    }

    & a {
        font-weight: normal !important;
    }

    @media ${device.mobile} {
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 24px;
    }
`

const DetailsAdditionalInfoDivOrgaAddress = styled(DetailsAdditionalInfoDiv)<{ showYearSizeIndustry?: boolean }>`
    line-height: 22px;
    font-size: 16px;
    flex: 1;
    display: flex;
    flex-direction: row;
    ${(props) => (props.showYearSizeIndustry ? "" : "justify-content: center;")}
`

const DetailsAdditionalInfoDivOrga = styled.div`
    flex: 1;
    display: flex;
    margin-top: -6px;
    line-height: 33px;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    font-family: ${branding.font1};

    & a {
        font-weight: normal !important;
    }

    @media ${device.mobile} {
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 24px;
    }
`

interface CompanyDetailInfoProps {
    company: Company
}
const CompanyDetailInfoDesktop: React.FunctionComponent<CompanyDetailInfoProps> = (props) => {
    const company = props.company
    const showYearSizeIndustry = company.foundingYear || company.size || company.industry ? true : false
    return (
        <CompanyDetailsRoot className="mt-5" showYearSizeIndustry={showYearSizeIndustry}>
            <CompanyAdress company={company} showYearSizeIndustry={showYearSizeIndustry} />
            <CompanyPhoneEmailWebsite company={company} showYearSizeIndustry={showYearSizeIndustry} />
            {showYearSizeIndustry && <CompanyYearSizeIndustry company={company}></CompanyYearSizeIndustry>}
        </CompanyDetailsRoot>
    )
}

const CompanyDetailInfoMobile: React.FunctionComponent<CompanyDetailInfoProps> = (props) => {
    const company = props.company
    return (
        <CompanyDetailsRoot className="mt-3 mx-2">
            <CompanyAddressPhoneEmail company={company} />
            <CompanyAdditionalInfoRootMobile>
                <DetailsAdditionalInfoDiv style={{ flex: 1, display: "flex", flexDirection: "row" }}>
                    <div style={{ paddingRight: "10px" }}>
                        {(company.foundingYear || company.size || company.industry) &&
                            IconInfo({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
                    </div>
                    <div>
                        {company.foundingYear && (
                            <p>
                                {branding.organizationDetailPageContent.foundingYearTitle}: {company.foundingYear}
                            </p>
                        )}
                        {company.size && (
                            <p>
                                {branding.organizationDetailPageContent.companySizeTitle}: {company.size}
                            </p>
                        )}
                        {company.industry && (
                            <p>
                                {branding.organizationDetailPageContent.industryTitle}: {company.industry}
                            </p>
                        )}
                    </div>
                </DetailsAdditionalInfoDiv>
            </CompanyAdditionalInfoRootMobile>
        </CompanyDetailsRoot>
    )
}

/* #endregion */

/* #region  Team and session template component */
interface ScrollableDivProps {
    width: string
}
export const ScrollableDiv = styled.div<ScrollableDivProps>`
    max-height: 350px;
    width: ${(props) => props.width};
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar {
        display: none;
    }
`
const SessionsContentCol = styled(Col)<{ lastEntry?: boolean }>`
    ${(props) =>
        props.lastEntry
            ? ""
            : branding.mainBorder
            ? "border-bottom: " + branding.mainBorder + ";"
            : "border-bottom: 1px solid #d9d9d9;"}
    padding: 25px 0;
`

const ScrollerShadowTop = styled.div`
    position: absolute;
    width: 100%;
    height: 10%;
    background: transparent;
    background: ${branding.primaryScrollWhiteShadowTTB};
    left: 0;
    top: 0;
    z-index: 2;
`

const ScrollerShadowBottom = styled.div`
    position: absolute;
    width: 100%;
    height: 10%;
    background: transparent;
    background: ${branding.primaryScrollWhiteShadowTTB};
    left: 0;
    bottom: 0;
    z-index: 2;
    transform: rotate(180deg);
`

const ViewAllButtonContainer = styled.div`
    cursor: pointer;
    margin-top: 3px;
    position: relative;
    z-index: 10;
`

const ViewAllButtonText = styled.div`
    display: inline-block;
    font-family: ${branding.font1};
    font-size: 12px;
    line-height: 16px;
`

const ViewAllButtonIcon = styled.div`
    display: inline-block;
    margin-left: 5px;
`

const TeamRow = styled(Row)`
    margin-left: 10px;
    margin-right: 12px;

    @media ${device.tablet} {
        margin-left: 0px;
        margin-right: 5px;
    }
`

const CustomDesktopVersionContainer = styled.div`
    display: none;
    width: 100%;

    @media ${device.tablet} {
        display: block;
    }

    &.group {
        margin-left: -20px;
    }
`

interface ViewAllButtonProps {
    viewAll: boolean
    setViewAll: (value: boolean) => void
}

export const ViewAllButton: React.FunctionComponent<ViewAllButtonProps> = (props: ViewAllButtonProps) => {
    return (
        <MobileVersionContainer>
            <ViewAllButtonContainer onClick={() => props.setViewAll(!props.viewAll)}>
                <ViewAllButtonText>
                    {props.viewAll
                        ? branding.organizationDetailPageContent.viewLessButtonText
                        : branding.organizationDetailPageContent.viewAllButtonText}
                </ViewAllButtonText>
                <ViewAllButtonIcon>
                    {props.viewAll
                        ? IconArrowUpLangSwitcher({ fill: branding.sideIconBar.sideIconColorDark, width: "12", height: "12" })
                        : IconArrowDownLangSwitcher({ fill: branding.sideIconBar.sideIconColorDark, width: "12", height: "12" })}
                </ViewAllButtonIcon>
            </ViewAllButtonContainer>
        </MobileVersionContainer>
    )
}

interface TeamSessionsTemplateProps {
    team?: Person[]
    sessions?: EventDate[]
    coupons?: any[]
    type: "team" | "session" | "coupon"
    organizationId?: string
    organizationName?: string
    organization?: Company
    onAccessDenied?: () => void
    past?: boolean
    live?: boolean
    hideShadows?: boolean
    containerOffsetLeft?: number
    src?: UserOrganizationVisitSource
    newDesign?: boolean
    group?: boolean
}

export const TeamSessionsTemplate: React.FunctionComponent<TeamSessionsTemplateProps> = (props) => {
    const scrollRef = useRef<HTMLDivElement>(null)
    const [position, setPosition] = useState(0)
    const [value, setValue] = useState(0)
    const maxScrollRange = scrollRef.current?.scrollHeight! - scrollRef.current?.clientHeight!
    const width = maxScrollRange !== 0 ? "95%" : "100%"
    const [staff, setStaff] = useState<Staff[] | undefined>()
    const userState = useLoggedInState()
    const profileId = userState.user()?.profileId
    const { showPrivacyPolicyModal, PrivacyModal } = usePrivacyPolicyModal()
    // Are shadows active
    const [isShadowTop, setIsShadowTop] = useState<boolean>(false)
    const [isShadowBottom, setIsShadowBottom] = useState<boolean>(false)

    const [viewAll, setViewAll] = useState<boolean>((props.team && props.team?.length! <= 3) ?? false)

    let sessions = props.sessions
    const location = useLocation()
    let currentLocation = location.pathname
    let coupons = props.coupons

    // Show-More-Pop-Up Dependencies
    const { DetailSectionModal, showDetailSectionModal, hideDetailSectionModal } = useDetailSectionModal()
    const { RecommendModal, showRecommendModal } = useRecommendModal(
        () => showDetailSectionModal(modalContentStaff, headerTitle),
        hideDetailSectionModal
    )
    const { CalendarModal, showCalendarEntryModal } = useCalendarEntryModal2(
        () => showDetailSectionModal(modalContentStaff, headerTitle),
        hideDetailSectionModal
    )

    useEffect(() => {
        setViewAll((props.team && props.team?.length! <= 3) ?? false)
    }, [props.team])

    useEffect(() => {
        scrollRef.current?.scrollTo(0, position)
    }, [position])

    // DETERMING IF THE SHADOWS SHOULD BE VISIBLE
    useEffect(() => {
        if (scrollRef.current?.scrollWidth === scrollRef.current?.clientHeight || props.hideShadows) return
        value === 0 ? setIsShadowTop(false) : setIsShadowTop(true)
        maxScrollRange - value < 40 ? setIsShadowBottom(false) : setIsShadowBottom(true)
        // eslint-disable-next-line
    }, [value, maxScrollRange])

    useEffect(() => {
        if (!props.organizationId) {
            setStaff(
                props.team?.map((person) => {
                    return {
                        firstName: person.firstName,
                        lastName: person.lastName,
                        id: person.id,
                        type: StaffType.PERSON,
                        logoUrl: person.logoUrl || person.userLogoUrl,
                        position: person.position,
                        company: person.organization,
                        sotUserId: person.userId,
                        visible: person.visible,
                        showroomStandby: person.showroomStandby,
                        title: person.title,
                        categories: person.categories
                    }
                })
            )
        } else {
            setStaff(
                props.team
                    ?.filter((person) => person.visible)
                    .map((person) => {
                        return {
                            firstName: person.firstName,
                            lastName: person.lastName,
                            id: person.id,
                            type: StaffType.PERSON,
                            logoUrl: person.logoUrl || person.userLogoUrl,
                            position: person.position,
                            company: person.organization,
                            sotUserId: person.userId,
                            visible: person.visible,
                            showroomStandby: person.showroomStandby,
                            title: person.title,
                            categories: person.categories
                        }
                    })
            )
        }
    }, [props.organizationId]) //eslint-disable-line

    if (
        (props.type === "team" && (!props.team || props.team.length === 0)) ||
        (props.type === "session" && (!props.sessions || props.sessions.length === 0))
    )
        return null

    const staffLength = staff !== undefined ? staff.length : 0
    let headerTitle =
        props.type === "team"
            ? (branding.organizationDetailPageContent.teamSectionTitleVisible
                  ? branding.organizationDetailPageContent.teamSectionTitle
                  : props.organizationName) +
              " (" +
              staffLength +
              ")"
            : props.type === "session"
            ? branding.organizationDetailPageContent.upcomingSessionsTitle + " (" + props.sessions!.length + ")"
            : branding.sideIconBar.goodiebagMenuText + " (" + props.coupons!.length + ")"

    if (props.live) headerTitle = branding.organizationDetailPageContent.liveSessionsTitle + " (" + props.sessions!.length + ")"

    if (props.past) {
        headerTitle =
            props.type === "team"
                ? props.organizationName + " (" + staffLength + ")"
                : branding.organizationDetailPageContent.pastSessionsTitle + " (" + props.sessions!.length + ")"
    }
    const maxNumEntriesStaff = props.newDesign && staff && staff.length >= 3 ? 3 : staff?.length ?? 0
    const maxNumEntriesCoupons = props.newDesign && coupons && coupons.length >= 3 ? 3 : coupons?.length ?? 0

    const teamGroupsFull: Dictionary<Person[]> = getEventPersonsFunctionGroups(props.team || [])

    const teamGroupsReduced: Dictionary<Person[]> = getEventPersonsFunctionGroups(
        props.team ? props.team?.slice(0, maxNumEntriesStaff) : []
    )

    const groupsContentSection: JSX.Element = (
        <>
            {Object.getOwnPropertyNames(teamGroupsReduced).map(function (key) {
                const currentGroupLength: number = teamGroupsFull[key].length //we want to show an actual number of persons in this group, instead of their number in the reduced list
                const currentGroup: Person[] = teamGroupsReduced[key]
                const currentGroupAsStaff: Staff[] = currentGroup.map((person) => {
                    return {
                        firstName: person.firstName,
                        lastName: person.lastName,
                        id: person.id,
                        type: StaffType.PERSON,
                        logoUrl: person.logoUrl || person.userLogoUrl,
                        position: person.position,
                        company: person.organization,
                        sotUserId: person.userId,
                        visible: person.visible,
                        showroomStandby: person.showroomStandby,
                        title: person.title,
                        categories: person.categories
                    }
                })

                return (
                    <>
                        <DetailSectionTitle marginTop="0">{`${key} (${currentGroupLength})`}</DetailSectionTitle>
                        {currentGroupAsStaff &&
                            currentGroupAsStaff.map((person, index) => {
                                return (
                                    <StaffEntry
                                        onAccessDenied={() => {
                                            if (props.onAccessDenied) {
                                                props.onAccessDenied()
                                            }
                                        }}
                                        key={person.id ?? index}
                                        staff={person}
                                        organizationId={props.organizationId!}
                                        backOffice={false}
                                        lastEntry={index === currentGroupAsStaff.length - 1}
                                        openRecommendModalInParent={showRecommendModal}
                                        openCalendarModalInParent={showCalendarEntryModal}
                                        closeDetailSectionModalInParent={hideDetailSectionModal}
                                    />
                                )
                            })}
                    </>
                )
            })}
        </>
    )

    const groupsContentModal: JSX.Element = (
        <>
            {Object.getOwnPropertyNames(teamGroupsFull).map(function (key) {
                const currentGroup: Person[] = teamGroupsFull[key]
                const currentGroupAsStaff: Staff[] = currentGroup.map((person) => {
                    return {
                        firstName: person.firstName,
                        lastName: person.lastName,
                        id: person.id,
                        type: StaffType.PERSON,
                        logoUrl: person.logoUrl || person.userLogoUrl,
                        position: person.position,
                        company: person.organization,
                        sotUserId: person.userId,
                        visible: person.visible,
                        showroomStandby: person.showroomStandby,
                        title: person.title,
                        categories: person.categories
                    }
                })
                return (
                    <>
                        <DetailSectionTitle marginTop="0">{`${key} (${currentGroupAsStaff.length})`}</DetailSectionTitle>
                        {currentGroupAsStaff &&
                            currentGroupAsStaff.map((person, index) => {
                                return (
                                    <StaffEntry
                                        onAccessDenied={() => {
                                            if (props.onAccessDenied) {
                                                props.onAccessDenied()
                                            }
                                        }}
                                        key={person.id ?? index}
                                        staff={person}
                                        organizationId={props.organizationId!}
                                        backOffice={false}
                                        lastEntry={index === currentGroupAsStaff.length - 1}
                                        openRecommendModalInParent={showRecommendModal}
                                        openCalendarModalInParent={showCalendarEntryModal}
                                        closeDetailSectionModalInParent={hideDetailSectionModal}
                                    />
                                )
                            })}
                    </>
                )
            })}
        </>
    )

    const desktopContent = (
        <>
            {props.group ? (
                <>{groupsContentSection}</>
            ) : (
                <>
                    {staff &&
                        staff.slice(0, maxNumEntriesStaff).map((person, index) => {
                            return (
                                <StaffEntry
                                    onAccessDenied={() => {
                                        if (props.onAccessDenied) {
                                            props.onAccessDenied()
                                        }
                                    }}
                                    key={person.id ?? index}
                                    staff={person}
                                    organizationId={props.organizationId!}
                                    backOffice={false}
                                    lastEntry={index === maxNumEntriesStaff - 1}
                                />
                            )
                        })}
                </>
            )}
            {sessions &&
                sessions.map((session) => {
                    return (
                        <SessionsContentCol xs={12} key={session.id}>
                            <SessionData session={session} src={props.src || "UNKNOWN"} />
                        </SessionsContentCol>
                    )
                })}
            {coupons &&
                coupons.slice(0, maxNumEntriesCoupons).map((coupon, index) => {
                    return (
                        <CouponData
                            key={coupon.id}
                            companyId={props.organizationId}
                            {...coupon}
                            onAction={() => {
                                if (props.organization && props.organizationId) {
                                    showPrivacyPolicyModal(props.organization, () => {
                                        if (
                                            coupon.url !== null &&
                                            coupon.url?.length! > 0 &&
                                            !coupon.url?.includes(currentLocation)
                                        ) {
                                            window.open(coupon.url, "_blank")
                                            trackVisit(profileId!, props.organizationId!, "COUPON", coupon.title)
                                        }
                                    })
                                }
                            }}
                            lastEntry={index === maxNumEntriesCoupons - 1}
                        />
                    )
                })}
            {!props.newDesign && isShadowTop && !props.hideShadows && <ScrollerShadowTop />}
            {!props.newDesign && isShadowBottom && !props.hideShadows && <ScrollerShadowBottom />}
        </>
    )

    const modalContentStaff: JSX.Element = (
        <>
            {props.group ? (
                <div style={{ marginTop: "15px" }}>{groupsContentModal}</div>
            ) : (
                <>
                    {staff &&
                        staff.map((person, index) => {
                            return (
                                <StaffEntry
                                    onAccessDenied={() => {
                                        if (props.onAccessDenied) {
                                            props.onAccessDenied()
                                        }
                                    }}
                                    key={person.id ?? index}
                                    staff={person}
                                    organizationId={props.organizationId!}
                                    backOffice={false}
                                    lastEntry={index === staff.length - 1}
                                    openRecommendModalInParent={showRecommendModal}
                                    openCalendarModalInParent={showCalendarEntryModal}
                                    closeDetailSectionModalInParent={hideDetailSectionModal}
                                />
                            )
                        })}
                </>
            )}
        </>
    )
    const modalContentCoupon: JSX.Element = (
        <>
            {coupons &&
                coupons.map((coupon, index) => {
                    return (
                        <CouponData
                            key={coupon.id}
                            companyId={props.organizationId}
                            {...coupon}
                            onAction={() => {
                                if (props.organization && props.organizationId)
                                    showPrivacyPolicyModal(props.organization, () => {
                                        if (
                                            coupon.url !== null &&
                                            coupon.url?.length! > 0 &&
                                            !coupon.url?.includes(currentLocation)
                                        ) {
                                            window.open(coupon.url, "_blank")
                                            trackVisit(profileId!, props.organizationId!, "COUPON", coupon.title)
                                        }
                                    })
                            }}
                            lastEntry={index === coupons!.length - 1}
                        />
                    )
                })}
        </>
    )

    return (
        <Col xs={12}>
            <TeamRow>
                <Col
                    xs={12}
                    className={props.newDesign ? "p-0" : "p-0 mb-4"}
                    style={{ display: "flex", justifyContent: "space-between" }}
                >
                    {props.newDesign ? (
                        <>{!props.group && <DetailSectionTitle marginTop="0">{headerTitle}</DetailSectionTitle>}</>
                    ) : (
                        <HeaderTitle>{headerTitle}</HeaderTitle>
                    )}
                    {!props.newDesign && props.team && props.team?.length! > 3 && (
                        <ViewAllButton viewAll={viewAll} setViewAll={setViewAll} />
                    )}
                </Col>
                <CustomDesktopVersionContainer className={props.group ? "group" : ""}>
                    <div style={{ width: "100%", position: "relative" }}>
                        {props.newDesign ? (
                            <div>
                                {desktopContent}
                                <DetailSectionModal />
                                <CalendarModal />
                                <RecommendModal />
                            </div>
                        ) : (
                            <ScrollableDiv ref={scrollRef} onScroll={() => setValue(scrollRef.current?.scrollTop!)} width={width}>
                                {desktopContent}
                            </ScrollableDiv>
                        )}
                    </div>
                    {!props.newDesign && (
                        <ScrollBarComponent
                            value={value}
                            maxScrollRange={maxScrollRange ? maxScrollRange : 0}
                            onScroll={(val: number) => {
                                setPosition(val)
                                setValue(val)
                            }}
                            isVertical={true}
                        ></ScrollBarComponent>
                    )}
                </CustomDesktopVersionContainer>
                {props.newDesign && staff && !coupons && (
                    <ViewMoreButton
                        showDetailSectionModal={() => {
                            showDetailSectionModal(modalContentStaff, headerTitle)
                        }}
                        entries={staff}
                        maxNumEntries={maxNumEntriesStaff}
                    />
                )}
                {props.newDesign && !staff && coupons && (
                    <ViewMoreButton
                        showDetailSectionModal={() => {
                            showDetailSectionModal(modalContentCoupon, headerTitle)
                        }}
                        entries={coupons}
                        maxNumEntries={maxNumEntriesCoupons}
                    />
                )}
                <MobileVersionContainer style={{ width: "100%" }}>
                    {viewAll ? (
                        <Row style={{ marginRight: 0 }}>
                            {staff && (
                                <>
                                    {staff.map((person, index) => {
                                        return (
                                            <Col xs={4} key={index}>
                                                <div
                                                    style={{
                                                        width: 95,
                                                        height: 95,
                                                        marginBottom: 10,
                                                        overflow: "hidden",
                                                        borderRadius: "5px"
                                                    }}
                                                >
                                                    <PersonComponent
                                                        {...person}
                                                        userId={person.id}
                                                        type={"person"}
                                                    ></PersonComponent>
                                                </div>
                                            </Col>
                                        )
                                    })}
                                </>
                            )}
                        </Row>
                    ) : (
                        <TileRow
                            key="staff"
                            iconVisible={true}
                            title={headerTitle}
                            titleVisible={false}
                            navLinkTextVisible={true}
                            hideShadowsAndScrollButtons={true}
                            offsetLeft={props.containerOffsetLeft}
                            navLinkText={branding.receptionPage.participantsNavLinkText}
                            scrollComponent={staff && staff?.length > 0}
                            childWidth={95 + 9}
                            childCount={staff?.length ?? 0}
                            customMargin="-45px 0px 0 0px"
                        >
                            {staff && (
                                <MobileTileWrapperSpeakers $count={staff?.length!}>
                                    {staff.map((person, index) => (
                                        <div style={{ width: 95, height: 95 }} key={index}>
                                            <PersonComponent {...person} type={"person"} userId={person.id}></PersonComponent>
                                        </div>
                                    ))}
                                </MobileTileWrapperSpeakers>
                            )}
                        </TileRow>
                    )}
                </MobileVersionContainer>
            </TeamRow>
            <PrivacyModal />
        </Col>
    )
}

const SessionRow = styled(Row)`
    line-height: 30px;
`
const ShareBookmarkDiv = styled.div`
    display: flex;
    justify-content: flex-end;
    font-size: 20px;
`
export const ShareIcon = styled.div`
    cursor: pointer;
    margin-right: 20px;
    color: ${branding.primaryColor};
`

interface SessionDataProps {
    session: EventDate
    src: UserOrganizationVisitSource
}

export const SessionData: React.FunctionComponent<SessionDataProps> = (props) => {
    const languageState = useLanguageState()
    const lang = languageState.getLanguage()
    const displayTicketIcon = props.session?.accessProvider === AccessProvider.TICKET

    const { showRecommendModal: showRecommendOrganizationModal, RecommendModal: OrganizationModal } = useRecommendModal()

    function getFormattedDate(date: string, start: string) {
        return (
            format(moment(date).toDate(), branding.eventTiming.eventDaysFormatPatternEvtDetailPage, {
                locale: lang === "de" ? de : en
            }) +
            (lang === "de" ? " um " : " at ") +
            start +
            (lang === "de" ? " Uhr" : "")
        ) //have to find a better way to do this but this works for now
    }
    const isLive = isEventDateLive(props.session)

    const linkId = isLive && props.session.channel ? props.session.channel.id : props.session.id

    const categoriesState = useCategoriesState()
    const [pointsBadgeData, setPointsBadgeData] = useState<Category>()

    useEffect(() => {
        if (categoriesState.getCategories().length)
            setPointsBadgeData(categoriesState.getCategoryByAlias(branding.programSchedule.pointsBadgeCategoryAlias))
        // eslint-disable-next-line
    }, [])

    return (
        <DetailNavLink
            name={props.session.name}
            id={linkId}
            type={props.session.channel ? "channel" : "eventdate"}
            source={props.src}
            onClick={() => {
                trackSelectContent(props.session.name, "Details", props.session.channel ? "Channel" : "Eventdate")
            }}
        >
            <SessionRow>
                <Col style={{ paddingRight: "0", flex: "0 0 20px", marginRight: "20px" }} xs={1}>
                    {IconVideo({ fill: branding.sideIconBar.sideIconColorDark })}
                </Col>
                <Col xs={9} style={{ paddingLeft: "0" }}>
                    <FormattedDatePar>{props.session.name}</FormattedDatePar>
                    <FormattedDateExtensionDiv>
                        {getFormattedDate(props.session.date, props.session.start)}
                    </FormattedDateExtensionDiv>
                    {props.session.location && <FormattedDateExtensionDiv>{props.session.location}</FormattedDateExtensionDiv>}
                    {pointsBadgeData &&
                        props.session.categories
                            ?.map((x) => x.alias)
                            ?.includes(branding.programSchedule.pointsBadgeCategoryAlias) && (
                            <PointsBadge
                                className={"ml-2"}
                                textColor={pointsBadgeData.colorText}
                                fillColor={pointsBadgeData.colorFill}
                                borderColor={pointsBadgeData.colorBorder}
                            >
                                {branding.programSchedule.pointsBadgeText}
                            </PointsBadge>
                        )}
                    {props.session.accessProvider !== AccessProvider.PUBLIC && (
                        <FormattedDateExtensionDiv>
                            {displayTicketIcon
                                ? IconTicket({ fill: branding.sideIconBar.sideIconColorDark, width: "18", height: "18" })
                                : IconLock({ fill: branding.sideIconBar.sideIconColorDark, width: "18", height: "18" })}
                        </FormattedDateExtensionDiv>
                    )}
                </Col>
                <Col xs={2} style={{ marginLeft: "calc(8.333333% - 55px)" }}>
                    <ShareBookmarkDiv>
                        <ShareIcon
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                showRecommendOrganizationModal(
                                    ShareTargetType.EVENTDATE,
                                    props.session.id,
                                    props.session.name,
                                    buildDetailLink(props.session.id, props.session.name, "eventdate")
                                )
                            }}
                        >
                            {IconShare({ fill: branding.sideIconBar.sideIconColorDark })}
                        </ShareIcon>
                        <BookmarkWithToggle
                            newBookmarkItem={true}
                            fontSize={"25px"}
                            color={branding.sideIconBar.sideIconColorDark}
                            stroke={branding.sideIconBar.sideIconColorDark}
                            favIconBasic={true}
                            type="eventdate"
                            id={props.session.id}
                            name={props.session.name}
                        />
                    </ShareBookmarkDiv>
                </Col>
            </SessionRow>
            <OrganizationModal />
        </DetailNavLink>
    )
}

/* #region Coupon Data */
const CouponDetails = styled.div`
    cursor: pointer;
    padding-left: 10px;
`

const CouponNameLabel = styled.div`
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 1rem;
    margin-top: 2px;
    color: ${branding.coupons.couponsListLayoutTitleColor};
`

const CouponDescriptionLabel = styled.div`
    font-size: 14px;
    line-height: 0.8rem;
`

interface CouponDataProps {
    id: string
    companyId: string
    name: string
    teaser: string
    description: string
    url: string
    onAction: () => void
    lastEntry?: boolean
}

const CouponData: React.FunctionComponent<CouponDataProps> = (props) => {
    const location = useLocation()
    let currentLocation = location.pathname
    const goToCoupon = () => {
        props.onAction()
    }

    return (
        <SessionsContentCol lastEntry={props.lastEntry}>
            <SessionRow
                style={{
                    pointerEvents:
                        props.url === null || props.url?.length! === 0 || props.url?.includes(currentLocation) ? "none" : "auto"
                }}
                onClick={() => goToCoupon()}
            >
                <Col style={{ paddingRight: "0", flex: "0 0 20px" }} xs={1}>
                    {IconCoupon({ fill: branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor })}
                </Col>
                <Col xs={11}>
                    <CouponDetails>
                        <CouponNameLabel>
                            {props.name?.split("\\n").map((item, i) => (
                                <span key={i}>
                                    {item}
                                    <br />
                                </span>
                            ))}
                        </CouponNameLabel>
                        <CouponDescriptionLabel>
                            {(props.teaser || props.description || "").split("\\n").map((item, i) => (
                                <span key={i}>
                                    {item}
                                    <br />
                                </span>
                            ))}
                        </CouponDescriptionLabel>
                    </CouponDetails>
                </Col>
            </SessionRow>
        </SessionsContentCol>
    )
}
/* #endregion */

/* #region  Links */
const LinkNameUrl = styled.p`
    font-size: 16px;
    display: inline-block;
    cursor: pointer;
    white-space: normal;
    word-break: break-word;
    margin-bottom: 0px;

    @media (max-width: 1600px) {
        font-size: 16px;
    }

    @media ${device.mobile} {
        font-size: 14px;
        font-weight: bold;
    }

    &:hover {
        opacity: 0.3;
    }
`
const LinkRow = styled(Row)<{ lastEntry?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    ${(props) => (props.lastEntry ? "" : "border-bottom: 1px solid " + branding.listDividerColor + ";")}
    padding: 25px 0;

    @media ${device.mobile} {
        margin-left: 5px !important;
        margin-right: 15px !important;
    }
`
const CopyToClipboardDiv = styled.div`
    cursor: pointer;
`

interface LinksProps {
    organizationId?: string
    organization?: Company
    links: Link[]
    tooltipMessage: string
    colXs?: number
    colPadding?: string
    hideHeaderTitle?: boolean
    newDesign?: boolean
}

export const Links: React.FunctionComponent<LinksProps> = (props) => {
    const { showPrivacyPolicyModal, PrivacyModal } = usePrivacyPolicyModal()
    const loggedInUserId = useLoggedInState().user()?.profileId

    let links = props.links ?? []

    const { useMobileDesign } = useWindowDimensions()

    links = links.filter((link) => {
        return link.text && link.text?.length > 0
    })

    if (links.length === 0) return null

    return (
        <Col
            xs={props.colXs ?? 12}
            style={{
                padding: props.colPadding ? props.colPadding : useMobileDesign ? "5px 5px 35px 5px" : "5px"
            }}
        >
            {!props.hideHeaderTitle &&
                (props.newDesign ? (
                    <DetailSectionTitle>
                        {links.length > 1
                            ? branding.organizationDetailPageContent.linksTitle + " (" + props.links.length + ")"
                            : "Link (1)"}
                    </DetailSectionTitle>
                ) : (
                    <Row className={useMobileDesign ? "" : "mb-4"} style={{ marginLeft: "5px", marginRight: "5px" }}>
                        <HeaderTitle>
                            {links.length > 1
                                ? branding.organizationDetailPageContent.linksTitle + " (" + props.links.length + ")"
                                : "Link (1)"}
                        </HeaderTitle>
                    </Row>
                ))}
            {links.map((link, index) => (
                <LinkItem
                    link={link}
                    key={link.id ?? index}
                    onCopyLink={() => {
                        if (props.organizationId)
                            showPrivacyPolicyModal(props.organization!, () => {
                                navigator.clipboard.writeText(link.url)
                            })
                        else navigator.clipboard.writeText(link.url)
                    }}
                    onFollowLink={() => {
                        if (props.organizationId)
                            showPrivacyPolicyModal(props.organization!, () => {
                                trackVisit(loggedInUserId!, props.organizationId!, "LINK", link.url)
                                window.open(getProperUrl(link.url), "_blank")
                            })
                        else window.open(getProperUrl(link.url), "_blank")

                        trackSelectContent(link.url, "Details", "Link")
                    }}
                    tooltipMessage={props.tooltipMessage}
                    lastEntry={index === links.length - 1}
                />
            ))}
            <PrivacyModal />
        </Col>
    )
}

interface LinkProps {
    link: Link
    tooltipMessage: string
    onCopyLink: () => void
    onFollowLink: () => void
    lastEntry?: boolean
}

const LinkItem: React.FunctionComponent<LinkProps> = (props) => {
    const [text, setText] = useState(props.link.text)
    const [tooltip, setTooltip] = useState(props.tooltipMessage)
    const { useMobileDesign } = useWindowDimensions()
    const rootClassName = `link-item-root ${useMobileDesign ? "mt-1 mx-0" : "mt-3 mx-0"}`

    useEffect(() => {
        setText(props.link.text)
    }, [props.link])

    if (!props.link.text || props.link.text?.length === 0) {
        return null
    }

    return (
        <LinkRow className={rootClassName} lastEntry={props.lastEntry}>
            <Col style={{ paddingRight: "0", flex: "0 0 20px", marginRight: "25px" }} xs={1} className="p-0">
                {IconLink({ fill: branding.sideIconBar.sideIconColorDark })}
            </Col>
            <Col
                style={{ paddingLeft: "0" }}
                xs={9}
                onMouseEnter={() => setText(props.link.url)}
                onMouseLeave={() => setText(props.link.text)}
            >
                <LinkNameUrl onClick={props.onFollowLink}>{text}</LinkNameUrl>
            </Col>
            <Col xs={2} style={{ marginLeft: "calc(8.333333% - 55px)" }} className="d-flex justify-content-end p-0">
                <CustomOverlayTrigger
                    placement="left"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                            {tooltip}
                        </Tooltip>
                    }
                >
                    <CopyToClipboardDiv
                        onClick={() => {
                            props.onCopyLink()
                            setTooltip("copied")
                        }}
                        onMouseLeave={() =>
                            setTimeout(() => {
                                setTooltip(props.tooltipMessage)
                            }, 500)
                        }
                    >
                        {IconCopyLight({ fill: branding.sideIconBar.sideIconColorDark })}
                    </CopyToClipboardDiv>
                </CustomOverlayTrigger>
            </Col>
        </LinkRow>
    )
}

const IframeButtonArea = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: space-evenly;
`

const IframeButton = styled.button`
    display: flex;
    width: 150px;
    height: 50px;
    font-size: 14px;
    align-items: center;
    border: 1px solid;
    background: 0;
    justify-content: center;
    border-radius: 5px;
`
interface IframeSectionProps {
    src: string
    title: string
}

const IframeSection: React.FunctionComponent<IframeSectionProps> = (props) => {
    const iframeRef = useRef<HTMLIFrameElement>(null)

    function openFullscreen() {
        if (iframeRef?.current?.requestFullscreen) {
            iframeRef.current.requestFullscreen()
        } else {
            // this only happens in safari case
            const iframeSafari = iframeRef.current as unknown as HTMLElement & {
                webkitRequestFullscreen(): Promise<void>
            }
            if (iframeSafari?.webkitRequestFullscreen) iframeSafari.webkitRequestFullscreen()
        }
    }

    function openInNewTab() {
        window.open(props.src, "_blank")
    }

    return (
        <Row className="mt-5 pb-5 d-flex flex-column" style={{ marginLeft: "5px", marginRight: "5px" }}>
            <HeaderTitle>{branding.organizationDetailPageContent.showcase}</HeaderTitle>
            <iframe
                id="orgaIframe"
                ref={iframeRef}
                className="mt-5"
                style={{ height: "600px", borderRadius: "5px", border: branding.organizationDetailPageContent.iframeBorder }}
                title={props.title}
                src={props.src}
                allowFullScreen={true}
            ></iframe>
            <IframeButtonArea className="mx-auto w-50 mt-3">
                <IframeButton onClick={() => openFullscreen()}>
                    {branding.organizationDetailPageContent.fullscreenButton}
                </IframeButton>
                <IframeButton onClick={() => openInNewTab()}>{branding.organizationDetailPageContent.newTabButton}</IframeButton>
            </IframeButtonArea>
        </Row>
    )
}
/* #endregion */

const MobileCompanyHeaderWrapper = styled.div`
    background: #fff;
    padding-bottom: 10px;
    border-radius: 5px;

    @media ${device.tablet} {
        border-radius: 0;
    }
`
const MobileCompanyHeaderBannerContainer = styled.div`
    width: 100%;
    height: 105px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 5px 5px 0 0;

    & img {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: left top;
        border-radius: 5px 5px 0 0;
    }
`

const MobileCompanyHeaderCompanyTitle = styled.div`
    font-family: ${branding.font1};
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    margin-top: 60px;
    text-align: center;
`

const MobileCompanyHeaderMenuButton = styled.div`
    position: absolute;
    right: 3rem;
    margin-top: -100px;
    cursor: pointer;
    z-index: 1;
`

const MobileCompanyHeader: React.FunctionComponent<{ company?: Company }> = (props) => {
    const [showMenu, setShowMenu] = useState<boolean>(false)
    const userState = useLoggedInState()

    const headerImageUrl = (exhibitor: Company): string => {
        if (
            branding.organizationDetailPageContent.defaultHeaderBasisPremiumTypes.includes(exhibitor.basisPremium) ||
            !exhibitor.backgroundImageURL
        )
            return "/branding/default_brand_card.jpg"
        else return exhibitor.backgroundImageURL
    }

    return (
        <MobileSectionWrapper>
            <MobileCompanyHeaderWrapper>
                <MobileCompanyHeaderBannerContainer>
                    <img src={headerImageUrl(props.company!)} alt="" />
                    {isUserEdited(userState) && (
                        <MobileCompanyHeaderMenuButton
                            onClick={() => {
                                setShowMenu(true)
                            }}
                        >
                            {IconThreeDotsMenu({ fill: "#fff" })}
                        </MobileCompanyHeaderMenuButton>
                    )}
                </MobileCompanyHeaderBannerContainer>
                <div style={{ position: "relative", width: "100%" }}>
                    <LogoDiv
                        style={{
                            position: "absolute",
                            marginTop: "-40px",
                            left: 0,
                            right: 0,
                            marginLeft: "auto",
                            marginRight: "auto"
                        }}
                    >
                        {props.company?.pictureURL ? (
                            <CompanyLogo src={props.company?.pictureURL} alt="" />
                        ) : branding.exhibitorsPageContent.defaultLogoVisibleOnCompanyTiles ? (
                            <CompanyLogo src={"/branding/rsz_defaultgrey.png"} />
                        ) : (
                            <h2 style={{ fontFamily: branding.font1 }}>{props.company?.initials}</h2>
                        )}
                    </LogoDiv>
                </div>
                <MobileCompanyHeaderCompanyTitle>{props.company?.name}</MobileCompanyHeaderCompanyTitle>

                {showMenu && <CompanyMenuModal company={props.company!} handleClose={() => setShowMenu(false)} />}
            </MobileCompanyHeaderWrapper>
        </MobileSectionWrapper>
    )
}

export const ModalRoot = styled(Modal)<{ marginTop?: string }>`
    font-family: ${branding.font1};
    color: ${branding.mainInfoColor};

    .modal-content {
        border-radius: 5px;

        &::before {
            content: "";
            position: absolute;
            border-style: solid;
            border-width: 10px 12px;
            border-color: transparent transparent #fff transparent;
            right: 1.5rem;
            top: -20px;
        }
    }

    .modal-dialog {
        margin-top: ${(props) => props.marginTop ?? "100px"};
        margin-left: auto;
        margin-right: auto;
        width: calc(100% - 25px);
        border-radius: 5px;
    }
`

export function onBookmark(favoriteState: any, company?: Company | Exhibitor, user?: any) {
    if (company && user) {
        let date = new Date()
        date.setMinutes(date.getMinutes() - 1)

        if (!favoriteState.is("organization", company?.id)) {
            trackTakeAction(`Set Favorite Organization ${company?.id}`, "SetFavorite", "Organization")
        }

        favoriteState.toggle("organization", company?.id)
        const isFavorite = favoriteState.is("organization", company?.id)
        syncFavorites({
            profileId: user.profileId,
            body: {
                currentTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), //"2020-01-01 12:00:00",
                lastSyncTime: favoriteState.getLastSyncTime(),
                changedFavorites: [
                    {
                        id: company?.id,
                        kind: "ORGANIZATION",
                        deleted: !isFavorite,
                        lastModified: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                    }
                ]
            }
        }).then(() => {
            favoriteState.setLastSyncTime(new Date())
        })
    }
}

export function getCompanyMenuModalItems(
    mobileAppDownload: boolean,
    favoriteState: any,
    showRecommendModal: (shareType: ShareTargetType, entityId: string, entityName: string, link: string) => void,
    setShowRequestMeetingModal: (value: boolean) => void,
    company?: Company | Exhibitor,
    user?: any
) {
    const menuItems: MenuItemProps[] = []

    // Bookmark organization
    const isBookmarked = favoriteState.is("organization", company?.id || "")
    menuItems.push({
        title: branding.organizationDetailPageContent.bookmark,
        icon: isBookmarked
            ? branding.defaultToggleIcon
                ? IconBookmarkFilled({ fill: branding.sideIconBar.sideIconColorDark })
                : IconBookmarkToggle({ fill: branding.sideIconBar.sideIconColorDark })
            : IconBookmarkToggle({ fill: branding.sideIconBar.sideIconColorDark }),
        onClick: () => onBookmark(favoriteState, company, user)
    })

    // Request meeting
    menuItems.push({
        disabled: isPostEventPhase,
        title: branding.organizationDetailPageContent.requestMeeting,
        icon: IconMeetingSchedule({ fill: branding.sideIconBar.sideIconColorDark }),
        onClick: () => {
            trackVisit(user?.profileId!, company?.id!, "CALENDARENTRY#CLICK")
            setShowRequestMeetingModal(true)
        }
    })

    // Recommend organization
    menuItems.push({
        title: branding.organizationDetailPageContent.recommend,
        icon: IconShare({ fill: branding.sideIconBar.sideIconColorDark }),
        onClick: () =>
            showRecommendModal(
                ShareTargetType.ORGANIZATION,
                company?.id!,
                company?.name!,
                buildDetailLink(company?.id!, company?.name!, "organization")
            )
    })

    //Download mobile app
    if (branding.contextMenuBranding.appDownloadEnabled && mobileAppDownload) {
        menuItems.push({
            title: branding.contextMenuBranding.appDownloadButtonText,
            hint: branding.contextMenuBranding.appDownloadButtonText,
            icon: IconMobile({ fill: branding.sideIconBar.sideIconColorDark }),
            onClick: () => {
                onAppDownloadClick()
            }
        })
    }

    return menuItems
}

const CompanyMenuModal: React.FunctionComponent<{ company?: Company; handleClose: () => void }> = (props) => {
    const favoriteState = useFavoriteState()
    const userState = useLoggedInState()
    const user = userState.user()
    const { RecommendModal, showRecommendModal } = useRecommendModal()

    const [showRequestMeetingModal, setShowRequestMeetingModal] = useState(false)

    return (
        <>
            <ModalRoot show={true} animation={false} onHide={() => props.handleClose()}>
                <ContextMenu
                    collapsed={false}
                    items={() =>
                        getCompanyMenuModalItems(
                            true,
                            favoriteState,
                            showRecommendModal,
                            setShowRequestMeetingModal,
                            props.company,
                            user
                        )
                    }
                />
            </ModalRoot>
            <RecommendModal />
            {showRequestMeetingModal && user && (
                <CalendarEntryModal2
                    viewMode={CalendarEntryModalViewMode.CREATE}
                    organizationId={props.company?.id!}
                    close={() => setShowRequestMeetingModal(false)}
                />
            )}
        </>
    )
}

const ImageSliderWrapper = styled.div`
    background-color: #c9c9c9;
    height: 248px;
    border-radius: 5px;

    .slick-slider {
        height: 215px;
    }

    .slick-dots {
        bottom: -30px;
    }

    .slick-dots li {
        width: 5px;
    }

    .slick-dots li.slick-active button:before {
        //color: #fff;
    }

    & .slick-prev {
        left: 20px;
        margin-top: 20px;
        z-index: 1;
    }

    & .slick-next {
        right: 20px;
        margin-top: 20px;
        z-index: 1;
    }
`

const ProductTitleMobile = styled.div`
    font-weight: 900;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
    font-family: ${branding.font1};
`

const ProductBasicInfoMobile = styled.div`
    padding: 10px 20px 10px 10px;
    min-height: 115px;
`

const ProductAttributesMobile = styled.div`
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: justify;
    font-family: ${branding.font1};
`

const ProductTeaserMobile = styled.div`
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: justify;
    font-family: ${branding.font1};
    margin-bottom: 10px;
`

const ImageContainer = styled.div<{ clickable: boolean }>`
    height: 248px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    cursor: ${(props) => (props.clickable ? "pointer" : "default")};

    & img {
        position: relative;
        z-index: -1;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        border-radius: 5px 5px 0 0;
    }
`
const VideoContainer = styled.div`
    height: 248px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;

    & video {
        position: relative;
        z-index: -1;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        border-radius: 5px 5px 0 0;
    }
`
const IconWrapper = styled.div`
    &::before {
        content: none;
    }
`
const TrademarkLogoDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    width: 95px;
    height: 95px;
    padding: 20px;
    background: #fff;
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
`

export const MobileProductHeader: React.FunctionComponent<{
    entity?: Product | Trademark | JobOffer
    type: "product" | "trademark" | "joboffer"
}> = (props) => {
    let attachments = props.type === "product" ? filterMedia(props.entity?.attachments!, true) : []
    let entityLogo = props.type === "product" ? props.entity?.pictureURL : null
    let images: Attachment[] = []
    const organization =
        props.type === "joboffer"
            ? (props.entity as JobOffer).organization
            : (props.entity as Product | Trademark)?.organizations.length
            ? (props.entity as Product | Trademark)?.organizations[0]
            : undefined
    const organizationId = organization?.id

    const user = useLoggedInState().user()
    const { PrivacyModal, showPrivacyPolicyModal } = usePrivacyPolicyModal()
    const { RecommendModal, showRecommendModal } = useRecommendModal()

    const [showMore, setShowMore] = useState(false)
    const [showMoreBtnClicked, setShowMoreBtnClicked] = useState(false)
    const history = useHistory()

    const [selectedAtt, setSelectedAtt] = useState<Attachment>()
    const [showMediaSlider, setShowMediaSlider] = useState(false)
    const os = detect()?.os

    useEffect(() => {
        if ((props.entity as Product) && (props.entity as Product)?.stands!)
            (props.entity as Product)?.stands?.length! > 3 ? setShowMore(true) : setShowMore(false)
        else setShowMore(false)
        // eslint-disable-next-line
    }, [props.entity])

    const renderImages = () => {
        // if (props.type === "product" && props.entity?.pictureURL) {
        //     images.push({
        //         id: props.entity?.id ?? "",
        //         title: props.entity?.name ?? "",
        //         description: "",
        //         url: props.entity?.pictureURL ?? "",
        //         largeThumbnailUrl: props.entity?.pictureURL ?? "",
        //         smallThumbnailUrl: props.entity?.pictureURL ?? "",
        //         contentType: "image/jpeg"
        //     })
        // }

        attachments.forEach((att) => {
            images.push(att)
        })

        return attachments.length > 0
            ? images.map((image: Attachment, index: number) =>
                  image.contentType.includes("video") ? (
                      <VideoContainer key={index} onClick={() => onFocusImageClicked(image)}>
                          {/* <PlayIcon>{IconPlayFilled({ width: "35", height: "35", stroke: "white" })}</PlayIcon> */}
                          <video src={os === "iOS" ? image.url + "#t=0.001" : image.url} controls></video>
                      </VideoContainer>
                  ) : (
                      <ImageContainer key={index} onClick={() => onFocusImageClicked(image)} clickable={true}>
                          <img src={image.url} alt="" />
                      </ImageContainer>
                  )
              )
            : attachments.length === 0 && entityLogo && (
                  <ImageContainer clickable={false}>
                      <img src={entityLogo} alt="" />
                  </ImageContainer>
              )
    }

    const onFocusImageClicked = (attachment: Attachment) => {
        if (attachments.length === 0) return
        setSelectedAtt(attachment)

        if (organizationId && organization) {
            showPrivacyPolicyModal(organization, () => {
                trackVisit(user!.profileId, organizationId!, "MEDIA#PREVIEW", selectedAtt?.id)
                setShowMediaSlider(true)
            })
        } else {
            setShowMediaSlider(true)
        }
    }

    return (
        <MobileSectionWrapper>
            {attachments.length === 0 && !entityLogo ? null : (
                <ImageSliderWrapper>
                    <Slider
                        autoplay={true}
                        autoplaySpeed={3000}
                        dots={true}
                        nextArrow={
                            <IconWrapper>
                                {IconArrowRightCarousel({
                                    width: "19px",
                                    height: "30px",
                                    fill: branding.sideIconBar.sideIconColorDark
                                })}
                            </IconWrapper>
                        }
                        prevArrow={
                            <IconWrapper>
                                {IconArrowLeftCarousel({
                                    width: "19px",
                                    height: "30px",
                                    fill: branding.sideIconBar.sideIconColorDark
                                })}
                            </IconWrapper>
                        }
                    >
                        {renderImages()}
                    </Slider>
                </ImageSliderWrapper>
            )}

            <ProductBasicInfoMobile>
                {(props.type === "trademark" || props.type === "joboffer") && (
                    <TrademarkLogoDiv>
                        {props.entity?.pictureURL ? (
                            <CompanyLogo src={props.entity?.pictureURL} alt="" />
                        ) : branding.exhibitorsPageContent.defaultLogoVisibleOnCompanyTiles ? (
                            <CompanyLogo src={"/branding/rsz_defaultgrey.png"} />
                        ) : (
                            <h2 style={{ fontFamily: branding.font1 }}>{props.entity?.initials}</h2>
                        )}
                    </TrademarkLogoDiv>
                )}
                {props.entity?.name && (
                    <Row>
                        <Col xs={10}>
                            <ProductTitleMobile>{props.entity?.name}</ProductTitleMobile>
                        </Col>
                        <Col xs={2} className="d-flex justify-content-end p-0 text-right">
                            {getShareTargetType(props.type) && showRecommendModal && (
                                <ShareIcon
                                    onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        if (showRecommendModal)
                                            showRecommendModal(
                                                getShareTargetType(props.type) || ShareTargetType.PRODUCT,
                                                props.entity?.id!,
                                                props.entity?.name!,
                                                buildDetailLink(props.entity?.id!, props.entity?.name!, props.type)
                                            )
                                    }}
                                >
                                    {IconShare({ fill: branding.sideIconBar.sideIconColorDark })}
                                </ShareIcon>
                            )}
                            <BookmarkWithToggle
                                newBookmarkItem={true}
                                fontSize={"25px"}
                                color={branding.sideIconBar.sideIconColorDark}
                                favIconBasic={true}
                                type={props.type}
                                id={props.entity?.id}
                                name={props.entity?.name}
                            />
                        </Col>
                    </Row>
                )}
                {props.type === "joboffer" && <JobOfferDetails joboffer={props.entity as JobOffer} />}
                {props.type !== "joboffer" &&
                    props.type !== "product" &&
                    props.entity?.description &&
                    props.entity?.description.text && (
                        <ProductTeaserMobile>
                            {props.entity?.description.text?.split("\\n")?.map((item, i) => (
                                <span key={i}>
                                    {item}
                                    <br />
                                </span>
                            ))}
                        </ProductTeaserMobile>
                    )}
                {props.type === "product" && (
                    <>
                        {branding.productDetailPageContent.infoContentOrder.map((item, index) => {
                            switch (item) {
                                case InfoContentOrderType.ATTRIBUTES: {
                                    return (
                                        <ProductAttributesMobile>
                                            {(props.entity as Product)?.productProperties?.map((item, i) => (
                                                <div key={i}>
                                                    {item.name && item.value && (
                                                        <div style={{ display: "flex", flexDirection: "row", height: "30px" }}>
                                                            <div style={{ fontWeight: "bold", marginRight: "5px" }}>
                                                                {item.name}:
                                                            </div>{" "}
                                                            <div>
                                                                {item.value} {item.unit || ""}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </ProductAttributesMobile>
                                    )
                                }
                                case InfoContentOrderType.DESCRIPTION: {
                                    return (
                                        <ProductTeaserMobile>
                                            {props.entity?.description &&
                                                props.entity?.description.text &&
                                                props.entity?.description?.text?.split("\\n")?.map((item, i) => (
                                                    <span key={i}>
                                                        {item}
                                                        <br />
                                                    </span>
                                                ))}
                                        </ProductTeaserMobile>
                                    )
                                }
                                case InfoContentOrderType.BADGES: {
                                    return (
                                        <BadgeArea
                                            categories={flattenCategories(
                                                (props.entity as Product)?.categories || [],
                                                branding.productDetailPageContent.showCategoryHierarchy
                                            )}
                                            marginTop="15px"
                                        />
                                    )
                                }
                                default:
                                    return null
                            }
                        })}
                    </>
                )}
                {props.type === "product" &&
                    (props.entity as Product)?.stands &&
                    (props.entity as Product)?.stands?.length! > 0 && (
                        <DetailsAdditionalInfoDiv style={{ flex: 1, display: "flex", flexDirection: "row" }}>
                            <HalleStandSectionMobileView className="product">
                                <p>{branding.productDetailPageContent.halleStandSectionTitle}</p>
                                {(props.entity as Product)?.stands
                                    .slice(0, showMoreBtnClicked ? (props.entity as Product)?.stands.length : 3)
                                    .map((stand, idx) => {
                                        return (
                                            <HalleStandSingleMobileView
                                                onClick={() => {
                                                    if (!branding.organizationDetailPageContent.halleStandLinkEnabled) return

                                                    history.push(
                                                        hallplanOverviewRoute + "/" + stand.id.split("__")[0] + "--" + stand.id
                                                    )

                                                    trackSelectContent(stand.standName || stand.displayName, "Details", "Stand")
                                                }}
                                                key={idx}
                                            >
                                                {stand.standName || stand.displayName}
                                            </HalleStandSingleMobileView>
                                        )
                                    })}
                                {(props.entity as Product)?.stands.length > 3 && showMore && (
                                    <ShowMoreDiv>
                                        <ShowMoreBtn onClick={() => setShowMoreBtnClicked(!showMoreBtnClicked)}>
                                            {showMoreBtnClicked
                                                ? branding.productDetailPageContent.showLessText
                                                : branding.productDetailPageContent.showMoreText}
                                        </ShowMoreBtn>
                                        <div style={{ marginLeft: "7px", marginBottom: "2px" }}>
                                            {showMoreBtnClicked
                                                ? IconShowLess({
                                                      fill: branding.sideIconBar.sideIconColorDark,
                                                      width: "12",
                                                      height: "12"
                                                  })
                                                : IconShowMore({
                                                      fill: branding.sideIconBar.sideIconColorDark,
                                                      width: "12",
                                                      height: "12"
                                                  })}
                                        </div>
                                    </ShowMoreDiv>
                                )}
                            </HalleStandSectionMobileView>
                        </DetailsAdditionalInfoDiv>
                    )}
            </ProductBasicInfoMobile>

            <PrivacyModal />
            <RecommendModal />
            {attachments &&
                attachments.length > 0 &&
                ReactDOM.createPortal(
                    showMediaSlider && (
                        <div>
                            <DesktopVersionContainer>
                                <MediaSlider
                                    media={images}
                                    selectedAtt={selectedAtt}
                                    setShowMediaSlider={setShowMediaSlider}
                                    isOpenCurrently={showMediaSlider}
                                    entityType={props.type}
                                />
                            </DesktopVersionContainer>
                            <MobileVersionContainer>
                                <MediaSlider
                                    media={images}
                                    selectedAtt={selectedAtt}
                                    setShowMediaSlider={setShowMediaSlider}
                                    isOpenCurrently={showMediaSlider}
                                    entityType={props.type}
                                />
                            </MobileVersionContainer>
                        </div>
                    ),
                    document.body
                )}
        </MobileSectionWrapper>
    )
}

interface EventActionsProps {
    event: Event
    showRecommendModal: (shareType: ShareTargetType, entityId: string, entityName: string, link: string) => void
}

const EventActions: React.FunctionComponent<EventActionsProps> = (props: EventActionsProps) => {
    const favoriteState = useFavoriteState()
    const isFavorite = favoriteState.is("event", props.event.id)

    return (
        <Row>
            <Col className="p-0" xs={10} />
            <Col className="d-flex justify-content-end p-0 text-right" xs={2}>
                {props.event && (
                    <CustomOverlayTrigger
                        trigger={["focus", "click", "hover"]}
                        placement="bottom"
                        delay={{ show: 250, hide: 250 }}
                        overlay={
                            <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                                {branding.backofficeReporting.download}
                            </Tooltip>
                        }
                    >
                        <div>
                            <ShareIcon
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()

                                    saveEvents(props.event?.eventdates!)

                                    trackSelectContent("Organization Eventdates", "Download", "Eventdate")
                                }}
                            >
                                {IconDownload({ fill: branding.sideIconBar.sideIconColorDark })}
                            </ShareIcon>
                        </div>
                    </CustomOverlayTrigger>
                )}
                <CustomOverlayTrigger
                    trigger={["focus", "click", "hover"]}
                    placement="bottom"
                    delay={{ show: 250, hide: 250 }}
                    overlay={
                        <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                            {branding.communicationArea.chatToolRecommend}
                        </Tooltip>
                    }
                >
                    <div>
                        <ShareIcon
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                props.showRecommendModal!(
                                    ShareTargetType.EVENT,
                                    props.event.id,
                                    props.event.name,
                                    buildDetailLink(props.event.id, props.event.id, "event")
                                )
                            }}
                        >
                            {IconShare({ fill: branding.sideIconBar.sideIconColorDark })}
                        </ShareIcon>
                    </div>
                </CustomOverlayTrigger>
                <CustomOverlayTrigger
                    placement="bottom"
                    overlay={
                        <Tooltip id="button-tooltip-share" style={{ fontFamily: branding.font1 }}>
                            {isFavorite
                                ? branding.organizationDetailPageContent.unBookmarkPerson
                                : branding.organizationDetailPageContent.bookmarkPerson}
                        </Tooltip>
                    }
                >
                    <div>
                        <BookmarkWithToggle
                            newBookmarkItem={true}
                            fontSize={"25px"}
                            color={branding.sideIconBar.sideIconColorDark}
                            favIconBasic={true}
                            type="event"
                            id={props.event?.id!}
                            name={props.event?.id!}
                        />
                    </div>
                </CustomOverlayTrigger>
            </Col>
        </Row>
    )
}
