import * as React from "react"
import { Range, getTrackBackground } from "react-range"
import styled from "styled-components"
import branding from "../../../branding/branding"
import { IconMinus, IconMinusAlt, IconPlus, IconPlusAlt } from "../../../ui/Icons"
import useWindowDimensions from "../../../ui/WindowDimensionsHook"
import { device } from "../../../utils/Device"
import { trackSelectContent } from "../../../utils/GTMTracking"

const ReactRangeRoot = styled.div`
    display: flex;
    width: 30%;
    max-width: 400px;
    justify-content: space-between;
    align-items: center;
    margin-left: 4rem;
    margin-bottom: 12px;

    &.hallplan {
        width: 100%;
        margin-left: 0px;
        @media ${device.mobile} {
            display: none;
        }
    }
`
const ReactRangeActionCircle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${branding.networkingArea.networkingRangeActionButtonBgColor};
    color: ${branding.networkingArea.networkingRangeActionButtonColor};
    width: 35px;
    height: 35px;
    border-radius: 50%;
    cursor: pointer;

    @media ${device.mobile} {
        width: 18px;
        height: 18px;
    }

    &.hallplan {
        width: 50px;
        height: 50px;
    }
`

const ZoomButtonsRoot = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: -85px;
    margin-left: 10px;
    position: relative;
    z-index: 1000;
`

const ZoomButton = styled.div`
    width: 37.5px;
    height: 37.5px;
    border-radius: 5px;
    background: #fff;
    display: flex;
    justify-content: center;
    cursor: pointer;
    padding: 5px;
    border: 1px solid #c9c9c9;

    &.zoomIn {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border-bottom: none;
    }

    &.zoomOut {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }
`

export const ReactRangeTrack = styled.div`
    height: 36px;
    display: flex;
    width: 70%;
    & div.range-track-inner {
        height: 4px;
        width: 100%;
        border-radius: 4px;
        align-self: center;
    }

    @media ${device.mobile} {
        width: 65%;
    }
`

export const ReactRangeThumb = styled.div`
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: ${branding.networkingArea.networkingRangeButtonColor};
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;

    @media ${device.mobile} {
        height: 12px;
        width: 12px;
    }
`

export interface ReactRangeProps {
    values: number[]
    onChange: Function
    min?: number
    max?: number
    step?: number
}

enum ZoomActionType {
    ZOOM_IN,
    ZOOM_OUT
}

const RangeSlider = (rootProps: ReactRangeProps) => {
    return (
        <Range
            values={rootProps.values}
            step={rootProps.step}
            min={rootProps.min}
            max={rootProps.max}
            onChange={(values) => rootProps.onChange(values)}
            renderTrack={({ props, children }) => (
                <ReactRangeTrack onMouseDown={props.onMouseDown} onTouchStart={props.onTouchStart}>
                    <div
                        ref={props.ref}
                        className={"range-track-inner"}
                        style={{
                            background: getTrackBackground({
                                values: rootProps.values,
                                colors: [branding.networkingArea.networkingRangeButtonColor, "#D9D9D9"],
                                min: rootProps.min!,
                                max: rootProps.max!
                            })
                        }}
                    >
                        {children}
                    </div>
                </ReactRangeTrack>
            )}
            renderThumb={({ props, isDragged, index }) => <ReactRangeThumb {...props} key={index}></ReactRangeThumb>}
        />
    )
}

interface ZoomRangeSliderProps {
    zoomStrengthValue: number[]
    setZoomStrengthValue: (value: React.SetStateAction<number[]>) => void
    min?: number
    max?: number
    step?: number
    hallplan?: boolean
    hallplanCompontent?: boolean
    customFunction?: () => void
}

const ZoomRangeSlider = (props: ZoomRangeSliderProps) => {
    const { useMobileDesign } = useWindowDimensions()
    const changeZoomValue = (actionType: ZoomActionType) => {
        if (actionType === ZoomActionType.ZOOM_IN && props.zoomStrengthValue[0] === props.max) return
        else if (actionType === ZoomActionType.ZOOM_OUT && props.zoomStrengthValue[0] === props.min) return

        actionType === ZoomActionType.ZOOM_IN
            ? props.setZoomStrengthValue([parseFloat((props.zoomStrengthValue[0] + props.step!).toFixed(1))])
            : props.setZoomStrengthValue([parseFloat((props.zoomStrengthValue[0] - props.step!).toFixed(1))])
    }

    if (useMobileDesign && props.hallplanCompontent) {
        return (
            <ZoomButtonsRoot>
                <ZoomButton
                    className="zoomIn"
                    onClick={(e: any) => {
                        changeZoomValue(ZoomActionType.ZOOM_IN)
                        trackSelectContent("Hallplan-ZoomIn", "ZoomIn", "Hall")
                        e.preventDefault()
                        e.stopPropagation()
                    }}
                    onTouchStartCapture={(e: any) => {
                        changeZoomValue(ZoomActionType.ZOOM_IN)
                        e.preventDefault()
                        e.stopPropagation()
                    }}
                >
                    <div>
                        {IconPlusAlt({
                            fill: branding.sideIconBar.sideIconColorDark,
                            width: "12",
                            height: "12"
                        })}
                    </div>
                </ZoomButton>
                <ZoomButton
                    className="zoomOut"
                    onClick={() => {
                        trackSelectContent("Hallplan-ZoomOut", "ZoomOut", "Hall")
                        changeZoomValue(ZoomActionType.ZOOM_OUT)
                    }}
                    onTouchStartCapture={() => changeZoomValue(ZoomActionType.ZOOM_OUT)}
                >
                    <div>
                        {IconMinusAlt({
                            fill: branding.sideIconBar.sideIconColorDark,
                            width: "12",
                            height: "12"
                        })}
                    </div>
                </ZoomButton>
            </ZoomButtonsRoot>
        )
    }

    return (
        <ReactRangeRoot className={props.hallplan ? "hallplan" : ""}>
            <ReactRangeActionCircle onClick={() => changeZoomValue(ZoomActionType.ZOOM_OUT)}>
                <div>
                    {IconMinus({
                        fill: "#fff",
                        width: props.hallplan ? "22" : "20",
                        height: props.hallplan ? "22" : "20"
                    })}
                </div>
            </ReactRangeActionCircle>
            <RangeSlider {...props} values={props.zoomStrengthValue} onChange={props.setZoomStrengthValue} />
            <ReactRangeActionCircle onClick={() => changeZoomValue(ZoomActionType.ZOOM_IN)}>
                <div>
                    {IconPlus({
                        fill: "#fff",
                        width: props.hallplan ? "22" : "20",
                        height: props.hallplan ? "22" : "20"
                    })}
                </div>
            </ReactRangeActionCircle>
        </ReactRangeRoot>
    )
}

export const ZOOM_STEP_VALUE = 0.1
export const ZOOM_MIN_VALUE = 0.5
export const ZOOM_MAX_VALUE = 4

ZoomRangeSlider.defaultProps = {
    min: ZOOM_MIN_VALUE,
    max: ZOOM_MAX_VALUE,
    step: ZOOM_STEP_VALUE
}

export default ZoomRangeSlider
