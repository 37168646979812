export const replaceKickOrBanReasonPlaceholderText = (template: string, reason: string, defaultText: string) => {
    //var template = branding.globalTexts.guestRestrictionMessage
    let contentString

    if (reason !== "") {
        contentString = template.split("{$reason}").join(reason)
    } else {
        contentString = defaultText
    }

    return contentString
}

export function compareAlphabeticallyIgnoreCase(a?: string, b?: string) {
    if (!a || !b) return 0
    // ignore upper and lowercase
    const wordA = a.toUpperCase()
    const wordB = b.toUpperCase()
    if (wordA < wordB) {
        return -1
    }
    if (wordA > wordB) {
        return 1
    }
    // names must be equal
    return 0
}

// example input: "  1 0 00 00 0 " -> output: "1 000 000"
export function formatNumberStringGroupsOfThree(numberString: string): string {
    const stringWithoutSpaces = numberString.replace(/\s/g, "")
    const stringWithGaps = stringWithoutSpaces.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    return stringWithGaps
}

export function capitalizeFirstLetter(str: string) {
    if (typeof str !== "string" || str.length === 0) {
        return str
    }

    return str.charAt(0).toUpperCase() + str.slice(1)
}
