import { Modal } from "react-bootstrap"
import styled from "styled-components"
import branding from "../../../branding/branding"
import { device } from "../../../utils/Device"
import SearchPersonInput from "../../../communicationArea/SearchPersonInput"
import ReactDatePicker from "react-datepicker"

interface OpenAvailabilityCalendarProps {
    openLayout?: boolean
}

/* #region ScheduleMeetingComponent */
export const Layout = styled(Modal)<OpenAvailabilityCalendarProps>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    margin: 0 auto;
    overflow: scroll;
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-content {
        position: relative;
        background-color: white;
        width: ${(props) => (props.openLayout ? "150%" : "630px")};
        padding: 30px;
        display: flex;
        flex-direction: row;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        max-width: 1200px;
    }

    .modal-dialog {
        position: relative;
        top: 5%;
        max-width: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 10%;
    }
`

export const Content = styled.div<OpenAvailabilityCalendarProps>`
    position: relative;
    top: 5%;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 1;
    padding-right: ${(props) => (props.openLayout ? "0" : "30px")};
    border-right: ${(props) => (props.openLayout ? "none" : "1px solid #c4c4c4")};
`

export const ContentHeader = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 0 10px;
`

export const ContentTitle = styled.div`
    font-size: 15px;
    line-height: 20px;
    font-family: ${branding.font1};
    font-weight: bold;
    width: fit-content;
    display: flex;
    gap: 0.5rem;

    @media ${device.mobileXL} {
        font-size: 18px;
    }
`

interface IconEditBackgroundProps {
    canEditMeeting?: boolean
}

export const IconEditBackground = styled.div<IconEditBackgroundProps>`
    cursor: pointer;
    display: ${(props) => (props.canEditMeeting ? "none" : "block")};
`

export const CloseButton = styled.div<OpenAvailabilityCalendarProps>`
    position: absolute;
    cursor: pointer;
    right: 0;
    display: ${(props) => (props.openLayout ? "none" : "block")};

    svg {
        color: ${branding.mainInfoColor};
    }
`

export const MainContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
`

export const OpenAvailabilityCalendarWrapper = styled.div`
    display: flex;
    width: auto;
`

export const AvailabilityCheckText = styled.p`
    display: none;

    @media ${device.tablet} {
        display: inline-block;
        color: ${branding.calendarEntryModalPageContent.calendarTheme};
        cursor: pointer;
        width: fit-content;
        margin: 0;
        font-family: ${branding.font1};
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
    }
`
/* #endregion */

/* #region CalendarEntryForm */
interface BackgroundProps {
    width: string
}
export const Background = styled.div<BackgroundProps>`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: ${(props) => props.width ?? "100%"};
    margin-bottom: 10px;
`
interface InputTitleWrapperProps {
    justifyContent?: string
}

export const InputTitleWrapper = styled.div<InputTitleWrapperProps>`
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.justifyContent};
`

export const InputTitle = styled.div`
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    font-family: ${branding.font1};
`

export const MandatoryEntry = styled.span`
    color: ${branding.dangerButtonColor};
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
`

export const Form = styled.form`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`

interface InputIconsProps {
    className?: string
}

export const InputIcons = styled.div<InputIconsProps>`
    position: relative;

    & span {
        position: absolute;
        left: 8px;
        top: 2px;
        color: #727272;
        width: 12px;
        height: 14px;
        z-index: 1;
    }

    .react-datepicker-popper {
        z-index: 2;
        width: ${(props) => (props.className === "select-time" ? "100%" : "65%")};
        overflow: scroll;

        & div:first-child {
            & .react-datepicker {
                background-color: #fff;
                color: #000;
                border: 1px solid #aeaeae;
                border-radius: 0.3rem;
                display: inline-block;
                position: relative;

                & .react-datepicker__header {
                    border: none;
                    width: 100%;
                }

                & .react-datepicker__current-month {
                    border: none;
                    width: 100%;
                }

                & .react-datepicker__day-name {
                    border: none;
                }

                & .react-datepicker__week {
                    border: none;
                }

                & .react-datepicker__day {
                    border: none;
                }
            }
        }
    }

    .react-datepicker__time-container
        .react-datepicker__time
        .react-datepicker__time-box
        ul.react-datepicker__time-list
        li.react-datepicker__time-list-item--selected {
        background-color: #6dd400;
        color: white;
        font-weight: bold;
    }

    .react-datepicker__day--keyboard-selected {
        background-color: white;
        color: black;
    }

    .react-datepicker--time-only {
        width: 100%;
        display: flex;
    }

    .react-datepicker__time-box {
        width: 100% !important;
    }

    .react-datepicker__time-list-item {
        text-align: left;
    }

    .react-datepicker__time-container {
        width: 100%;
        text-align: left;
    }

    .react-datepicker__header {
        background-color: white;
        border-radius: 5px 5px 0 0;
        border-bottom: none;
    }

    .react-datepicker__navigation-icon::before {
        border-color: ${branding.calendarEntryModalPageContent.calendarTheme};
        top: 10px;
        left: 0;
    }

    .react-datepicker__current-month {
        font-family: ${branding.font1};
        font-weight: 700;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.12px;
        color: #727272;
    }

    .react-datepicker__triangle {
        display: none;
    }

    .react-datepicker__day-name {
        font-family: ${branding.font1};
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
        text-transform: capitalize;
        color: #727272;
    }

    .react-datepicker__day--outside-month {
        font-family: ${branding.font1};
        font-weight: 400;
        font-size: 0.8rem;
        line-height: 22px;
        letter-spacing: 0.22px;
        color: #c9c9c9;
    }

    .react-datepicker__day--selected {
        background: ${branding.calendarEntryModalPageContent.calendarTheme};
        color: white;
    }

    .react-datepicker__header--time {
        display: none;
        width: 100%;
    }

    input:disabled {
        background-color: white;
    }

    .react-datepicker-popper div:first-child {
        width: none;
    }
`

interface ShowIconProps {
    showIconInInput?: boolean
    className?: string
    showErrorBorder?: boolean
}

export const Input = styled.input<ShowIconProps>`
    border: 1px solid #c9c9c9;
    border-radius: 5px;
    color: rgb(0, 0, 0);
    font-size: 12px;
    font-family: ${branding.font1};
    font-weight: 400;
    line-height: 17px;
    cursor: text;
    height: 35px;
    outline: none;
    width: 100%;
    padding: ${(props) => (props.className === "text-input" ? "1rem 3.3rem 1rem 1rem" : "1rem 2rem 1rem 1rem")};
    padding-left: ${(props) => props.showIconInInput && "40px"};
    z-index: 0;
`

export const RadioWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 1.5rem;
    gap: 0.5rem;

    .radio-input {
        appearance: none;
        border-radius: 50%;
        width: 0.85em;
        height: 0.85em;
        border: 1px solid ${branding.calendarEntryModalPageContent.locationPickerRadioButtonColor};
        transform: translateY(-0.075em);
        display: grid;
        place-content: center;
    }

    .radio-input::before {
        content: "";
        width: 0.45em;
        height: 0.45em;
        border-radius: 50%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em ${branding.calendarEntryModalPageContent.locationPickerRadioButtonColor};
    }

    .radio-input:checked::before {
        transform: scale(1);
    }
`

export const RadioLabel = styled.label`
    margin: 0;
    font-size: 14px;
    font-family: ${branding.font1};
    line-height: 17px;
    font-weight: 400;
    color: #727272;
`

export const RadioInput = styled.input``

export const TextareaWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 75px;
`

export const TextareaInput = styled.textarea`
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border: 1px solid #c9c9c9;
    border-radius: 5px;
    font-size: 12px;
    padding: 5px 15px;
    outline: none;
    font-family: ${branding.font1};
    font-weight: 400;
    line-height: 17px;
    resize: none;
`

export const TimezoneWrapper = styled.div`
    position: relative;
`

export const TimezoneInput = styled.div`
    position: relative;

    & span {
        position: absolute;
        top: 1px;
        right: 8px;
        cursor: pointer;
    }
`

export const TimezoneDropdownList = styled.div`
    position: absolute;
    top: 40px;
    left: 0;
    border: 1px solid #727272;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    height: 200px;
    width: 100%;
    background-color: white;
    z-index: 2;
    overflow: scroll;
    max-height: 190px;
    height: fit-content;
    overflow-x: hidden;
`

export const TimezoneName = styled.div`
    width: 100%;
    font-family: ${branding.font1};
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    padding: 5px;
    cursor: pointer;
    white-space: nowrap;
    overflow-x: scroll;

    :hover {
        background-color: #eaeaea;
    }

    ::-webkit-scrollbar {
        height: 2px;
    }
`

export const CalendarWrapper = styled(ReactDatePicker)<ShowIconProps>`
    width: 100%;
    border: 1px solid
        ${(props) =>
            props.showErrorBorder
                ? branding.calendarEntryModalPageContent.errorInputBorderColor
                : branding.calendarEntryModalPageContent.inputBorderColor};
    border-radius: 5px;
    color: rgb(0, 0, 0);
    font-size: 12px;
    font-family: ${branding.font1};
    font-weight: 400;
    line-height: 17px;
    cursor: text;
    height: 35px;
    outline: none;
    width: 100%;
    padding: 1rem;
    padding-left: ${(props) => props.showIconInInput && "40px"};
    z-index: 0;

    &.readOnly {
        cursor: not-allowed;
    }
`

export const StyledSearchPersonInput = styled(SearchPersonInput)`
    .searchPersonResultItem > div:nth-child(1) {
        padding-right: 25px !important;
    }
`

/* #endregion */

/* #region AvailabilityCalendarComponent */
export const AvailabilityLayout = styled.div`
    position: relative;
    height: 100%;
    width: 50%;
    background-color: white;
    padding: 0 0 0 30px;
    border-radius: 0 5px 5px 0;
    top: 5%;
`

export const IconsHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`

export const CloseArrowIcon = styled.div`
    display: flex;
    flex-flow: row;
    cursor: pointer;
`

export const AvailabilityMainContent = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
`

export const MainContentTitle = styled.p`
    font-family: ${branding.font1};
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #202428;
`

export const ShowTimezoneDate = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export const ShowTimezone = styled.p`
    font-family: ${branding.font1};
    font-size: 15px;
    line-height: 18px;
    color: #727272;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @media ${device.laptop} {
        max-width: 500px;
    }
`

export const ShowDate = styled.p`
    font-family: ${branding.font1};
    font-size: 14px;
    line-height: 17px;
    color: #727272;
    white-space: nowrap;
`

export const UsersAvailablilityWrapper = styled.div`
    position: relative;
    height: 100%;
    max-width: 550px;
    overflow: scroll;
    overflow-y: hidden;

    // Removing scrollbar on mozilla
    ::-webkit-scrollbar {
        display: none;
    }

    ::-moz-scrollbar {
        display: none;
    }

    -ms-overflow-style: none;
    scrollbar-color: transparent transparent;
`

export const Agenda = styled.div`
    display: flex;
    margin-top: 2rem;
    gap: 1rem;
`

interface BoxProps {
    border?: string
    background?: string
    checkBackgroundColor?: boolean
}

export const Box = styled.div<BoxProps>`
    width: 35px;
    height: 15px;
    ${(props) => (props.checkBackgroundColor ? "border:1px solid #e30613;" : "border: 1px solid #5db130;")}
    ${(props) => (props.checkBackgroundColor ? "background:rgba(227, 6, 19, 0.05);" : "background:rgba(109, 212, 0, 0.05);")}
    border-radius: 3px;
`

export const BusyBox = styled.div`
    width: 35px;
    height: 15px;
    border-radius: 3px;
    background: #e4e4e4;
`

export const Status = styled.span`
    font-family: ${branding.font1};
    font-size: 12px;
    line-height: 14px;
    color: #202428;
`

export const AgendaItem = styled.div`
    display: flex;
    justify-content: center;
    gap: 0.3rem;
`

interface IconsProps {
    right?: string
    top?: string
    width?: string
}
export const IconRightLeftWrapper = styled.div<IconsProps>`
    position: absolute;
    top: 80px;
    cursor: pointer;
    right: ${(props) => props.right ?? "unset"};
    z-index: 2;
    ${(props) => (props.width ? "width: 40px" : "width: fit-content")};
    background-color: white;
`

export const HoursSlider = styled.div`
    display: flex;
    margin-left: 1rem;

    ::-webkit-scrollbar {
        height: 2px;
    }
`

export const TimeSlot = styled.p`
    margin-bottom: 0;
    min-width: 90px;
    text-align: center;
    font-family: ${branding.font1};
    font-size: 15px;
    line-height: 17px;
    color: #c9c9c9;
`

export const AvailabilityWrapper = styled.div`
    width: 4364px;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    position: relative;
`

interface MeetingSelectorProps {
    width: string
    left: string
    checkBackgroundColor?: boolean
}

export const MeetingSelector = styled.div<MeetingSelectorProps>`
    position: absolute;
    height: 100%;
    background: ${(props) => (props.checkBackgroundColor ? "rgba(227, 6, 19, 0.05)" : "rgba(109, 212, 0, 0.05)")};
    border: ${(props) => (props.width === "0px" ? "0px" : "1px")} solid
        ${(props) => (props.checkBackgroundColor ? "#e30613" : "#6dd400")};
    border-radius: 5px;
    width: ${(props) => props.width ?? "0px"};
    margin-left: 43px;
    left: ${(props) => props.left ?? "0"};
    z-index: 1;
`

interface ResizeButtonProps {
    width?: boolean
    borderColor?: boolean
    right?: boolean
}

export const ResizeButton = styled.div<ResizeButtonProps>`
    position: absolute;
    top: 50%;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    display: ${(props) => (props.width ? "none" : "block")};
    border: 1px solid ${(props) => (props.borderColor ? "#e30613" : "#6dd400")};
    ${(props) => (props.right ? "right: -10px;" : undefined)}
    z-index: 2;
    background: whitesmoke;
`

export const InvitedPersonBackground = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 4px;
    width: 100%;
`

export const HideSelectorBehindIcons = styled.div`
    position: absolute;
    background-color: #fff;
    height: 50px;
    width: 40px;
    top: 0;
    left: 0;
    z-index: -1;
`

export const AvatarBackground = styled.div`
    position: sticky;
    height: 35px;
    width: 40px;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: white;
    user-select: none;
`

interface ScheduledMeetingTimeProps {
    width?: string
    left?: string
}

export const ScheduledMeetingTime = styled.div<ScheduledMeetingTimeProps>`
    position: absolute;
    height: 35px;
    width: ${(props) => props.width ?? "0px"};
    background: rgba(217, 217, 217, 0.65);
    border-left: 2.5px solid #727272;
    border-radius: 5px;
    transform: matrix(1, 0, 0, -1, 0, 0);
    left: ${(props) => props.left ?? "0px"};
    margin-left: 43px;
`

export const GreyBackground = styled.div`
    width: 100%;
    background: rgba(239, 239, 239, 0.5);
    gap: 4px;
    border-radius: 5px;
    height: 35px;
`

/* #endregion */

export const ToggleSwitcherWrapper = styled.div`
    position: absolute;
    display: flex;
    right: 0;
    top: 40px;
    align-items: center;
    height: 20px;
`

interface ToggleNameProps {
    color?: string
    paddingRight?: string
}

export const ToggleName = styled.span<ToggleNameProps>`
    font-size: 12px;
    line-height: 17px;
    font-family: ${branding.font1};
    color: ${(props) => props.color ?? "#000000"};
    padding-right: ${(props) => props.paddingRight ?? "0"};
`

export const ToggleSwitchBackground = styled.div`
    margin-top: 5px;
`

export const ParticipantsListWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    max-height: 172px;
    overflow-y: scroll;
`

export const ButtonBackground = styled.div`
    width: 33%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
`

export const ParticipantWrapper = styled.div`
    min-width: 120px;
    width: 95%;
    height: 35px;
    display: flex;
    background: #e6e6e6;
    border-radius: 20px;
    align-items: center;
    padding: 0px 10px 0px 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis !important;

    svg {
        cursor: pointer;
    }
`

export const AvatarWithDefaultBackground = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const IconCloseBackground = styled.div``

export const ParticipantStatusName = styled.div`
    position: relative;
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 40px;
    padding-left: 7px;
    font-family: ${branding.font1};
    font-weight: 400;
`

interface ParticipantStatusProps {
    color?: string
    statusColor?: string
}

export const ParticipantStatus = styled.div<ParticipantStatusProps>`
    color: ${(props) => props.color};
    font-size: 10px;
    text-transform: lowercase;
    position: absolute;
    top: 20px;

    &::first-letter {
        text-transform: uppercase;
    }
`

export const ParticipantName = styled.div`
    font-size: 12px;
    color: #202428;
    flex-grow: 1;
    align-self: flex-start;
    display: inline;
    position: absolute;
    display: contents;
`

interface SubmitButtonContainerLinksProps {
    isFlexEnd?: boolean
}

export const SubmitButtonContainerLinks = styled.div<SubmitButtonContainerLinksProps>`
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: ${(props) => (props.isFlexEnd ? "flex-end" : "space-between")};
    align-items: center;
`

export const Buttons = styled.div`
    display: flex;
    gap: 10px;
    align-self: flex-end;
`

interface ExportMeetingProps {
    color?: string
}

export const ExportMeeting = styled.span<ExportMeetingProps>`
    font-family: ${branding.font1};
    font-size: 12px;
    line-height: 17px;
    color: ${(props) => props.color ?? "#727272"};
    cursor: pointer;
`

interface ButtonLinkProps {
    backgroundColor?: string
    color?: string
    borderColor?: string
}

export const ButtonLink = styled.button<ButtonLinkProps>`
    background-color: ${(props) => props.backgroundColor ?? "#fff"};
    color: ${(props) => props.color ?? "#fff"};
    border: 1px solid ${(props) => props.borderColor ?? "#fff"};
    width: 120px;
    height: 35px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: ${branding.font1};
    font-size: 12px;
    line-height: 17px;

    &:hover {
        text-decoration: none;
        background-color: ${(props) => props.backgroundColor ?? "#fff"};
        color: ${(props) => props.color ?? "#fff"};
    }
`

export const LocationWrapper = styled.div`
    position: relative;
`

export const LocationCardBackground = styled.div`
    position: relative;
    cursor: pointer;
`

export const LocationIconWrapper = styled.div`
    position: absolute;
    left: 8px;
    top: 0;
`

export const LocationHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5px 0;
`

export const LocationName = styled.p`
    color: #202428;
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    margin-bottom: 0;
    padding-left: 2rem;
`

export const HallLocationName = styled.div`
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #202428;
    padding-left: 2rem;

    :hover {
        background-color: #eaeaea;
    }
`

export const ToggleSwitchCalendarEntryRoot = styled.div`
    display: flex;
    justify-content: flex-end;
    font-family: ${branding.font1};
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    position: relative;
`

export const ToggleSwitchText = styled.p`
    margin-bottom: 0;
`

export const StyledCrossIcon = styled.div`
    position: absolute;
    top: 0;
    right: 40px;
    cursor: pointer;
`

interface ValidationDivProps {
    isVisibleOnViewMeetingModal?: boolean
}

export const ValidationDiv = styled.div<ValidationDivProps>`
    position: absolute;
    right: 45px;
    top: 0;
    display: ${(props) => (props.isVisibleOnViewMeetingModal ? "none" : "block")};
`

export const SearchBarPickerWrapper = styled.div``

export const ChatWithParticipant = styled.div`
    font-family: ${branding.font1};
    font-size: 12px;
    line-height: 17px;
    color: #727272;
    display: flex;
    gap: 0.4rem;
    cursor: pointer;
`

export const ParticipantText = styled.span``
